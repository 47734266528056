import { FunctionComponent, memo } from 'react';

interface Props {
  className?: string;
}

const CrossIconComponent: FunctionComponent<Props> = ({
  className = 'text-catalina-blue',
}) => {
  return (
    <svg
      className={className}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1.30929L11.6907 0L6.5 5.19071L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CrossIcon = memo(CrossIconComponent);
