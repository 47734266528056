import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';

export const stripUrlQueryParams = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.origin}${parsedUrl.pathname}`;
  } catch (error) {
    reportErrorToSentry(error);
    return '';
  }
};
