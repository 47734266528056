import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Routes } from 'router/routes';

export enum AuthRole {
  MarketplaceAdmin = 'marketplaceAdmin',
  User = 'user',
}

interface useIsAuthorizedOrRedirectToHomePageProps {
  userRoles?: AuthRole[];
  authorizedRoles?: AuthRole[];
  redirectUrl?: string;
}

export const useIsAuthorizedOrRedirectToHomePage = ({
  userRoles,
  authorizedRoles,
  redirectUrl,
}: useIsAuthorizedOrRedirectToHomePageProps): { isAuthorized: boolean } => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (!userRoles?.length) {
      return;
    }

    const redirectToUserTypeHomePage = (url?: string) => {
      const validUrl = url ?? Routes.HOME;

      if (router.pathname !== validUrl) {
        // https://nextjs.org/docs/pages/api-reference/functions/use-router#potential-eslint-errors
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        router.push(validUrl);
      }
    };

    // TODO: In the very near future, when the RBAC access topic will be tackled
    // the default value before RBAC processing should be set as false
    let isUserAuthorized = true;

    if (authorizedRoles && authorizedRoles.length) {
      isUserAuthorized = authorizedRoles.some((role) => {
        return userRoles.includes(role);
      });
    }

    if (!isUserAuthorized) {
      redirectToUserTypeHomePage(redirectUrl);
    } else {
      setIsAuthorized(isUserAuthorized);
    }
  }, [userRoles, authorizedRoles, redirectUrl, router]);

  return { isAuthorized };
};
