import { pickBy } from 'lodash';
import {
  AnalyticsEvent,
  AnalyticsService,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';

export class HubspotAnalyticService implements AnalyticsService {
  private hubspotClient?: Window['_hsq'];
  constructor() {}

  init = () => {
    // We can't move this in constructor because window is not defined at that time
    // See Hubspot tracking documentation: https://developers.hubspot.com/docs/api/events/tracking-code#set-page-path
    this.hubspotClient = window._hsq = window._hsq || [];
  };

  // TODO: implement the trackGqlOperation logic if useful
  trackGqlOperation = (_operationName: string, _payload?: object) => {};

  // TODO: implement the track event logic if useful
  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    _eventName: TAnalyticsEventName,
    _payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {};

  trackPageView = (page: string) => {
    this.hubspotClient?.push?.(['setPath', page]);
    this.hubspotClient?.push?.(['trackPageView']);
  };

  /**
   * This method follows the tracking code API documentation: https://developers.hubspot.com/docs/api/events/tracking-code
   */
  identifyUser = (user: AnalyticsUser) => {
    if (!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) return;

    try {
      const _hsq = (window._hsq = window._hsq || []);

      const hubspotContact = {
        email: user.email,
        main_business_type: user.mainBusinessType,
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phoneNumber,
        internal_id: user.internalId,
      };

      // We remove undefined values so that Hubspot doesn't override contact properties with 'undefined' string
      const cleanedHubspotContact = pickBy(
        hubspotContact,
        (v) => v !== undefined
      );

      _hsq.push(['identify', cleanedHubspotContact]);
      /**
       * We need the following line to send the tracking event to Hubspot
       * see https://community.hubspot.com/t5/APIs-Integrations/Using-hsq-push-quot-identify-quot-email-quot-xxx-quot-does-not/m-p/528462
       */
      _hsq.push(['trackPageView']);
    } catch (error) {
      reportErrorToSentry(error);
    }
  };

  forgetUser = () => {
    // We de-identify Hubspot user by removing the hubspot cookie
    // see https://stackoverflow.com/questions/68738453/how-to-deidentify-a-user-in-an-spa-which-uses-hubspot-tracking-code
    if (!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) return;
    try {
      const _hsp = (window._hsp = window._hsp || []);

      // see https://developers.hubspot.com/docs/api/events/cookie-banner
      _hsp.push(['revokeCookieConsent']);
    } catch (error) {
      reportErrorToSentry(error);
    }
  };
}
