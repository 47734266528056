import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { ChatContextProvider } from 'modules/Chat/context/ChatContext';
import { useUnreadChatMessagesCount } from 'modules/Chat/hooks/useUnreadChatMessagesCount.hook';

export const ChatProvider: FunctionComponentWithChildren = ({ children }) => {
  const unreadChatMessagesCount = useUnreadChatMessagesCount();

  return (
    <ChatContextProvider value={{ unreadChatMessagesCount }}>
      {children}
    </ChatContextProvider>
  );
};
