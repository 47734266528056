import { debounce, DebouncedFunc } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

// largely inspired from https://www.developerway.com/posts/debouncing-in-react

export const useDebounce = <TCallback extends (...callbackParams: any) => any>(
  callback: TCallback,
  debounceTime: number,
  debounceParams?: { leading?: boolean; trailing?: boolean; maxWait?: number } // https://ellenaua.medium.com/throttle-debounce-behavior-lodash-6bcae1494e03
): DebouncedFunc<TCallback> => {
  // create a ref because it's a MUTABLE object and yet PERSISTENT BETWEEN RE-RENDERS!
  const ref = useRef(callback);

  useEffect(() => {
    // updating ref when callback changes
    // ref is mutable! ref.current is a reference to the latest callback
    ref.current = callback;
  }, [callback]);

  // creating debounced callback only once - on mount
  const debouncedCallback = useMemo(() => {
    // func will be created only once - on mount
    const func = (...callbackParams: Parameters<TCallback>[]) => {
      // func is also mutable and runs the latest callback via ref.current
      return ref.current?.(...callbackParams);
    };

    // debounce the func that was created once, but has access to the latest callback
    return debounce(func, debounceTime, debounceParams);
    // no dependencies! never gets updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // don't add any dependencies here so that we are certain that debouncedCallback is defined only once! (no reaseon for debounce time and params to change anyway)

  return debouncedCallback;
  // Since we use the debounce method from lodash, debouncedCallback can return eihter the same thing as callback or undefined
  // You have to manage this case while using it, no choice...
};
