import { makeContext } from '@core/context/makeContext';
import { AuthService } from 'modules/Authentication/authentication.interface';

interface AuthenticationServiceContextValue {
  authenticationService: AuthService;
}

export const {
  Provider: AuthenticationServiceContextProvider,
  useContext: useAuthenticationServiceContext,
} = makeContext<AuthenticationServiceContextValue>(
  'AuthenticationServiceContext'
);
