import { AuthRole } from 'modules/Authentication/hooks/useIsAuthorizedOrRedirectToHomePage.hook';

interface User {
  email?: string;
}

export const checkIsAdmin = (user: User): boolean =>
  user.email?.endsWith(`@${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}`) ?? false;

export const checkIsAdminAndReturnRole = (
  user: User
): AuthRole.MarketplaceAdmin | null =>
  checkIsAdmin(user) ? AuthRole.MarketplaceAdmin : null;

export const rolesProcessor = (
  user: User,
  roleCheckers: Array<(user: User) => AuthRole | null>
): AuthRole[] => {
  const roles: AuthRole[] = [];

  for (const roleCheck of roleCheckers) {
    const role = roleCheck(user);

    if (role) {
      roles.push(role);
    }
  }

  return roles?.length ? roles : [AuthRole.User];
};
