import {
  FieldMergeFunction,
  FieldReadFunction,
  Reference,
} from '@apollo/client';
import {
  ExistingAnonymizedProfileCache,
  IncomingAnonymizedProfileCache,
  PartialAnonymizedProfileOffsetBasedConnection,
} from '@core/clients/apollo/cache/mergeAndReadMethods/paginatedAnonymizedProfiles.cache.types';
import { UndefinedGraphQLFieldError } from '@core/errors';
import { QueryPaginatedAnonymizedProfilesArgs } from 'graphql/generated/graphql';
import { uniqBy } from 'lodash';

export const mergeIncomingPaginatedAnonymizedProfiles: FieldMergeFunction<
  ExistingAnonymizedProfileCache,
  IncomingAnonymizedProfileCache
> = (
  existing,
  incoming,
  options
): PartialAnonymizedProfileOffsetBasedConnection => {
  const args = options.args as QueryPaginatedAnonymizedProfilesArgs | null;
  const { offsetBasedPaginationParams } = args ?? {};
  const { offset } = offsetBasedPaginationParams ?? {};

  if (offset === undefined) {
    throw new UndefinedGraphQLFieldError({
      fieldName: 'offset',
      operationName: 'paginatedAnonymizedProfiles',
    });
  }

  const existingNodes = existing?.nodes ?? [];
  const incomingNodes = incoming.nodes;

  let mergedNodes: Reference[] = [];
  if (offset === 0) {
    mergedNodes = incomingNodes;
  } else {
    mergedNodes = uniqBy(
      [...existingNodes, ...incomingNodes],
      (anonymizedProfileRef) => anonymizedProfileRef.__ref
    );
  }

  return {
    ...(existing ?? {}),
    __typename: incoming.__typename,
    hasNextPage: incoming.hasNextPage,
    totalCount: incoming.totalCount,
    nodes: mergedNodes,
  };
};

export const readPaginatedAnonymizedProfilesFromCache: FieldReadFunction<
  ExistingAnonymizedProfileCache,
  PartialAnonymizedProfileOffsetBasedConnection
> = (existing, { args }) => {
  // A read function should always return undefined if existing is
  // undefined. Returning undefined signals that the field is
  // missing from the cache, which instructs Apollo Client to
  // fetch its value from your GraphQL server.
  if (!existing) {
    return undefined;
  }

  const offsetBasedPaginationParams = args?.offsetBasedPaginationParams as
    | QueryPaginatedAnonymizedProfilesArgs['offsetBasedPaginationParams']
    | null;

  if (!offsetBasedPaginationParams) {
    throw new UndefinedGraphQLFieldError({
      fieldName: 'offsetBasedPaginationParams',
      operationName: 'paginatedAnonymizedProfiles',
    });
  }

  return existing;
};
