import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { AnalyticsService } from 'modules/Analytics/analytics.interface';
import { AnalyticsServiceContextProvider } from 'modules/Analytics/AnalyticsServiceContext';
import { HubspotAnalytics } from 'modules/Analytics/components/HubspotAnalytics.component';
import { useIdentifyMyUserInHubspotChat } from 'modules/Analytics/hooks/useIdentifyMyUserInHubspotChat.hook';
import { logMessage } from 'modules/Analytics/utils/reportError.utils';
import { AuthenticatedUser } from 'modules/Authentication/authentication.interface';
import { useCheckIfUserIsAuthenticated } from 'modules/Authentication/hooks/useCheckIfUserIsAuthenticated.hook';
import { useMyUserContext } from 'modules/MyUser/context/MyUserContext';
import { isMarketplaceUser } from 'modules/MyUser/context/MyUserProvider';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const AnalyticsServiceProvider: FunctionComponentWithChildren<{
  analyticService: AnalyticsService;
}> = ({ children, analyticService }) => {
  const router = useRouter();
  const { myUser } = useMyUserContext();
  const { checkIfUserIsAuthenticated } = useCheckIfUserIsAuthenticated();
  const { identifyMyUserInHubspotChat } = useIdentifyMyUserInHubspotChat();

  // Note: when using app router (the new Next.JS architecture), this won't work anymore
  // See: https://posthog.com/docs/libraries/next-js#app-router
  useEffect(() => {
    router.events.on('routeChangeComplete', analyticService.trackPageView);

    return () => {
      router.events.off('routeChangeComplete', analyticService.trackPageView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No dependencies! We want to run this only once

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfUserIsAuthenticated({
      onUserAuthenticated: async (authenticatedUser: AuthenticatedUser) => {
        if (!authenticatedUser.email) {
          logMessage('Cannot identify user, no user email found');
          return Promise.resolve();
        }

        analyticService.identifyUser({
          email: authenticatedUser.email,
          authUserId: authenticatedUser.authUserId,
          ...(myUser && { mainBusinessType: myUser.mainBusinessType }),
          ...(isMarketplaceUser(myUser) && {
            firstName: myUser.firstName,
            lastName: myUser.lastName,
            phoneNumber: myUser.phoneNumber,
            internalId: myUser.id,
          }),
        });
        if (myUser) {
          await identifyMyUserInHubspotChat({ myUser });
        }
        return Promise.resolve();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]); // We want this to run each time myUser changes

  return (
    <AnalyticsServiceContextProvider value={analyticService}>
      <HubspotAnalytics />
      {children}
    </AnalyticsServiceContextProvider>
  );
};
