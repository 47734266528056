/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query useAcademicBackgroundOptions_academicBackgrounds {\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n':
    types.UseAcademicBackgroundOptions_AcademicBackgroundsDocument,
  '\n  fragment JobOfferLinksPopover_JobOffer on JobOffer {\n    id\n    ...JobOfferCurrentStaffingDrawer_JobOffer\n  }\n':
    types.JobOfferLinksPopover_JobOfferFragmentDoc,
  '\n  fragment JobOfferCurrentStaffingDrawer_JobOffer on JobOffer {\n    id\n    title\n    employer {\n      id\n      name\n    }\n    ...JobOfferRecruitmentTasksList_JobOffer\n    ...JobOfferRecruitmentTaskRecommendationsList_JobOffer\n  }\n':
    types.JobOfferCurrentStaffingDrawer_JobOfferFragmentDoc,
  '\n    fragment JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      status\n      ...JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n    }\n  ':
    types.JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendationFragmentDoc,
  '\n  query jobOfferRecruitmentTaskRecommendations(\n    $jobOfferId: String!\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    jobOfferRecruitmentTaskRecommendations(\n      jobOfferId: $jobOfferId\n      filters: $filters\n    ) {\n      ...JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation\n    }\n  }\n':
    types.JobOfferRecruitmentTaskRecommendationsDocument,
  '\n  fragment JobOfferRecruitmentTaskRecommendationsList_JobOffer on JobOffer {\n    id\n    status\n  }\n':
    types.JobOfferRecruitmentTaskRecommendationsList_JobOfferFragmentDoc,
  '\n    fragment JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      recruiter {\n        id\n        email\n        phoneNumber\n        roles {\n          name\n        }\n        ...ChatContact_User\n      }\n      status\n    }\n  ':
    types.JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragmentDoc,
  '\n  fragment JobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n':
    types.JobOfferRecruitmentTasksList_JobOfferFragmentDoc,
  '\n  fragment JobOfferRecruitmentTasksList_RecruitmentTask on RecruitmentTask {\n    status\n    ...JobOfferRecruitmentTasksTable_RecruitmentTask\n  }\n':
    types.JobOfferRecruitmentTasksList_RecruitmentTaskFragmentDoc,
  '\n  query jobOfferRecruitmentTasks($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...JobOfferRecruitmentTasksList_RecruitmentTask\n    }\n  }\n':
    types.JobOfferRecruitmentTasksDocument,
  '\n  fragment JobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiter {\n      id\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n    jobApplications {\n      id\n    }\n    status\n  }\n':
    types.JobOfferRecruitmentTasksTable_RecruitmentTaskFragmentDoc,
  '\n  query JobOfferSelectionTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n':
    types.JobOfferSelectionTable_PaginatedJobOffersQueryDocument,
  '\n  query RecruitersCopySelectionFromJobOfferTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n':
    types.RecruitersCopySelectionFromJobOfferTable_PaginatedJobOffersQueryDocument,
  '\n  mutation staffJobOffer($input: StaffJobOfferInput!) {\n    staffJobOffer(input: $input) {\n      recruiterId\n      recruiterEmail\n      error\n    }\n  }\n':
    types.StaffJobOfferDocument,
  '\n  fragment useJobOfferAutoStaffing_User on User {\n    id\n    email\n    firstName\n    lastName\n    roles {\n      name\n    }\n    jobOfferStaffingRecruiterTier\n  }\n':
    types.UseJobOfferAutoStaffing_UserFragmentDoc,
  '\n  query useJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInput(\n    $input: JobOfferStaffingStandardStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingStandardStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n':
    types.UseJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInputDocument,
  '\n  query useJobOfferAutoStaffingExploratory_jobOfferStaffingExploratoryStrategySelectedRecruiters(\n    $input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingExploratoryStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n':
    types.UseJobOfferAutoStaffingExploratory_JobOfferStaffingExploratoryStrategySelectedRecruitersDocument,
  '\n  query useJobOfferAutoStaffingCopy_jobOfferStaffingCopyStrategySelectedRecruiters(\n    $input: JobOfferStaffingCopyStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingCopyStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n':
    types.UseJobOfferAutoStaffingCopy_JobOfferStaffingCopyStrategySelectedRecruitersDocument,
  '\n  fragment UseJobOfferSelectionTableColumns_JobOffer on JobOffer {\n    id\n    title\n    jobCategory {\n      name\n    }\n    employer {\n      name\n    }\n    ...JobOfferStatusTag_JobOffer\n    ...JobOfferLinksPopover_JobOffer\n  }\n':
    types.UseJobOfferSelectionTableColumns_JobOfferFragmentDoc,
  '\n  mutation staffRecruiter($input: StaffRecruiterInput!) {\n    staffRecruiter(input: $input) {\n      jobOfferId\n      jobOfferTitle\n      error\n    }\n  }\n':
    types.StaffRecruiterDocument,
  '\n  query StaffingFromRecruiterJobOffersTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n    $recruiterId: String!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseStaffingFromRecruiterJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n':
    types.StaffingFromRecruiterJobOffersTable_PaginatedJobOffersQueryDocument,
  '\n    fragment UseStaffingFromRecruiterJobOffersTableColumns_JobOffer on JobOffer {\n      id\n      title\n      createdAt\n      status\n      employer {\n        id\n        name\n      }\n      recruiterLatestRecruitmentTaskRecommendation(recruiterId: $recruiterId) {\n        id\n        createdAt\n        status\n      }\n      recruiterRecruitmentTask(recruiterId: $recruiterId) {\n        id\n        status\n      }\n      ...JobOfferStatusTag_JobOffer\n      ...JobOfferLinksPopover_JobOffer\n    }\n  ':
    types.UseStaffingFromRecruiterJobOffersTableColumns_JobOfferFragmentDoc,
  '\n  query employerJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      nodes {\n        id\n        status\n        creator {\n          id\n        }\n      }\n    }\n  }\n':
    types.EmployerJobOffersDocument,
  '\n  query myRecruiterActivityInformation {\n    myUser {\n      __typename\n      ... on User {\n        recruiterActivitySectors {\n          id\n        }\n        recruiterDeprecatedJobCategories {\n          id\n        }\n        recruiterNewJobCategories {\n          id\n        }\n      }\n    }\n  }\n':
    types.MyRecruiterActivityInformationDocument,
  '\n  query apolloQueryMockFactory_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      id\n    }\n  }\n':
    types.ApolloQueryMockFactory_JobOfferQueryDocument,
  '\n  query companyLogoUploadUrlAndFileName(\n    $input: CompanyLogoUploadUrlAndFileNameInput!\n  ) {\n    companyLogoUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n':
    types.CompanyLogoUploadUrlAndFileNameDocument,
  '\n  mutation updateMyEmployerLogo($input: UpdateMyEmployerLogoInput!) {\n    updateMyEmployerLogo(input: $input) {\n      id\n      logoUrl\n    }\n  }\n':
    types.UpdateMyEmployerLogoDocument,
  '\n  fragment HiringManagerLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    employer {\n      id\n    }\n  }\n':
    types.HiringManagerLayout_UserFragmentDoc,
  '\n  fragment JobApplicationWithStatuses on JobApplication {\n    id\n    statuses {\n      ...JobApplicationStatus\n    }\n  }\n':
    types.JobApplicationWithStatusesFragmentDoc,
  '\n  fragment JobApplicationLastUpdateAlert_JobApplication on JobApplication {\n    statuses {\n      id\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n':
    types.JobApplicationLastUpdateAlert_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationProfileDrawer_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileDrawerHeader_JobApplication\n    ...JobApplicationProfileDrawerHistorySection_JobApplication\n    ...JobApplicationProfileDrawerFooter_JobApplication\n    profile {\n      id\n      ...ProfileDrawerCreatorSection_Profile\n      ...ProfileDrawerInfoSection_Profile\n      ...ProfileDrawerSkillsSection_Profile\n      ...ProfileDrawerEducationsSection_Profile\n      ...ProfileDrawerExperiencesSection_Profile\n    }\n    recruitmentTask {\n      id\n      ...ProfileDrawerCreatorSection_RecruitmentTask\n    }\n  }\n':
    types.JobApplicationProfileDrawer_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationProfileDrawerFooter_JobApplication on JobApplication {\n    id\n    ...HiringConfirmationModal_JobApplication\n    ...JobApplicationRejectionModal_JobApplication\n    ...StartApplicantRecruitmentProcessModal_JobApplication\n    statuses {\n      id\n      name\n    }\n  }\n':
    types.JobApplicationProfileDrawerFooter_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationProfileDrawerHeader_JobApplication on JobApplication {\n    id\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      curriculumVitaeUrl\n      linkedInProfileUrl\n      phoneNumber\n      email\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    statuses {\n      id\n      name\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n':
    types.JobApplicationProfileDrawerHeader_JobApplicationFragmentDoc,
  '\n    fragment JobApplicationProfileDrawerHistorySection_JobApplication on JobApplication {\n      statuses {\n        id\n        name\n        createdAt\n        message {\n          body\n          creator {\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  ':
    types.JobApplicationProfileDrawerHistorySection_JobApplicationFragmentDoc,
  '\n    query getJobApplicationProfileDrawerData($id: String!) {\n      jobApplication(id: $id) {\n        ...JobApplicationProfileDrawer_JobApplication\n      }\n    }\n  ':
    types.GetJobApplicationProfileDrawerDataDocument,
  '\n  fragment UseJobApplicationSubmissionForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n':
    types.UseJobApplicationSubmissionForm_ProfileFragmentDoc,
  '\n  mutation useJobApplicationSubmissionForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseJobApplicationSubmissionForm_Profile\n    }\n  }\n':
    types.UseJobApplicationSubmissionForm_UpdateProfileMutationDocument,
  '\n  fragment GetJobApplicationLastUpdateAlert_JobApplicationStatus on JobApplicationStatus {\n    id\n    name\n    createdAt\n  }\n':
    types.GetJobApplicationLastUpdateAlert_JobApplicationStatusFragmentDoc,
  '\n  fragment SortByStatus_JobApplication on JobApplication {\n    statuses {\n      name\n      createdAt\n    }\n  }\n':
    types.SortByStatus_JobApplicationFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n    createdAt\n  }\n':
    types.UseJobApplicationRecommendation_ProfileFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_Employer on Employer {\n    id\n    name\n    logoUrl\n    description\n    nonPoachingAgreementsDescription\n  }\n':
    types.UseJobApplicationRecommendation_EmployerFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_User on User {\n    id\n    firstName\n    lastName\n    phoneNumber\n    email\n    myOneToOneChatChannelId\n    employer {\n      id\n      name\n    }\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n':
    types.UseJobApplicationRecommendation_UserFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    myOneToOneChatChannelId\n  }\n':
    types.UseJobApplicationRecommendation_InternalManagerFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_SuccessFee on SuccessFee {\n    id\n    basisPoints\n  }\n':
    types.UseJobApplicationRecommendation_SuccessFeeFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_JobOffer on JobOffer {\n    id\n    title\n    isTerminated\n    description\n    recruitmentProcess\n    careerOutlook\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    perksDescription\n    briefingVideoUrl\n    videoAskUrl\n    location\n    contractType\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    mandatoryProfileAttributes\n    profileDescription\n    technicalSkillsDescription\n    typicalProfileUrl\n    recruitmentAdvice\n    remoteWorkPolicy\n    latestSuccessFee {\n      ...UseJobApplicationRecommendation_SuccessFee\n    }\n    creator {\n      ...UseJobApplicationRecommendation_User\n    }\n    # TODO: replace InternalManager by CustomerSuccessManager\n    internalManager {\n      ...UseJobApplicationRecommendation_InternalManager\n    }\n    employer {\n      ...UseJobApplicationRecommendation_Employer\n    }\n  }\n':
    types.UseJobApplicationRecommendation_JobOfferFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n':
    types.UseJobApplicationRecommendation_RecruitmentTaskFragmentDoc,
  '\n  fragment UseJobApplicationRecommendation_JobApplicationRecommendation on JobApplicationRecommendation {\n    id\n    createdAt\n    hasApplied\n    score\n    status\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOfferMatchingCriteria {\n      name\n      isMatching\n    }\n    jobOffer {\n      ...UseJobApplicationRecommendation_JobOffer\n      ...JobOfferHeader_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n    profile {\n      ...UseJobApplicationRecommendation_Profile\n    }\n    recruitmentTask {\n      ...UseJobApplicationRecommendation_RecruitmentTask\n    }\n  }\n':
    types.UseJobApplicationRecommendation_JobApplicationRecommendationFragmentDoc,
  '\n  query useJobApplicationRecommendation_jobApplicationRecommendationQuery(\n    $input: GetJobApplicationRecommendationInput!\n  ) {\n    jobApplicationRecommendation(input: $input) {\n      ...UseJobApplicationRecommendation_JobApplicationRecommendation\n    }\n  }\n':
    types.UseJobApplicationRecommendation_JobApplicationRecommendationQueryDocument,
  '\n    mutation useJobApplicationRecommendationAcceptationModal_takeRecruitmentTaskViaJobApplicationRecommendationMutation(\n      $input: TakeRecruitmentTaskViaJobApplicationRecommendationInput!\n    ) {\n      takeRecruitmentTaskViaJobApplicationRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  ':
    types.UseJobApplicationRecommendationAcceptationModal_TakeRecruitmentTaskViaJobApplicationRecommendationMutationDocument,
  '\n    fragment EmployerJobOfferJobApplicationEditableStatusTag_JobApplication on JobApplication {\n      id\n      statuses {\n        id\n        name\n        createdAt\n      }\n      ...StartApplicantRecruitmentProcessModal_JobApplication\n      ...JobApplicationRejectionModal_JobApplication\n      ...HiringConfirmationModal_JobApplication\n    }\n  ':
    types.EmployerJobOfferJobApplicationEditableStatusTag_JobApplicationFragmentDoc,
  '\n    fragment AfterHiringJobOfferTerminationModal_JobApplication on JobApplication {\n      jobOffer {\n        id\n        ...UseAfterHiringJobOfferTerminationModal_JobOffer\n      }\n    }\n  ':
    types.AfterHiringJobOfferTerminationModal_JobApplicationFragmentDoc,
  '\n  fragment UseAfterHiringJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n  }\n':
    types.UseAfterHiringJobOfferTerminationModal_JobOfferFragmentDoc,
  '\n    mutation terminateJobOffer($input: TerminateJobOfferInput!) {\n      terminateJobOffer(input: $input) {\n        id\n        isTerminated\n      }\n    }\n  ':
    types.TerminateJobOfferDocument,
  '\n    fragment AskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...UseAskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  ':
    types.AskForFirstInterviewWithTemplateModal_JobApplicationFragmentDoc,
  '\n    fragment UseAskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        id\n        firstName\n        lastName\n        creator {\n          id\n          firstName\n          lastName\n        }\n      }\n      jobOffer {\n        id\n        title\n        employer {\n          id\n          name\n        }\n      }\n    }\n  ':
    types.UseAskForFirstInterviewWithTemplateModal_JobApplicationFragmentDoc,
  '\n    fragment AskForFirstInterviewWithoutTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        firstName\n        lastName\n      }\n    }\n  ':
    types.AskForFirstInterviewWithoutTemplateModal_JobApplicationFragmentDoc,
  '\n  fragment HiringConfirmationModal_JobApplication on JobApplication {\n    id\n    profile {\n      id\n      firstName\n      lastName\n      creator {\n        id\n        firstName\n        lastName\n      }\n    }\n    jobOffer {\n      id\n      title\n    }\n    ...AfterHiringJobOfferTerminationModal_JobApplication\n  }\n':
    types.HiringConfirmationModal_JobApplicationFragmentDoc,
  '\n    mutation declareApplicantHiredByMyCompany(\n      $input: DeclareApplicantHiredByMyCompanyInput!\n    ) {\n      declareApplicantHiredByMyCompany(input: $input) {\n        # This mutation updates the jobApplication statuses\n        # We want it to return the jobApplication with its statuses to update the cache\n        ...JobApplicationWithStatuses\n      }\n    }\n  ':
    types.DeclareApplicantHiredByMyCompanyDocument,
  '\n  fragment JobApplicationRejectionModal_JobApplication on JobApplication {\n    id\n  }\n':
    types.JobApplicationRejectionModal_JobApplicationFragmentDoc,
  '\n    fragment StartApplicantRecruitmentProcessModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...AskForFirstInterviewWithoutTemplateModal_JobApplication\n      ...AskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  ':
    types.StartApplicantRecruitmentProcessModal_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationStatus on JobApplicationStatus {\n    id\n    createdAt\n    name\n    message {\n      ...JobApplicationStatusMessage\n    }\n  }\n':
    types.JobApplicationStatusFragmentDoc,
  '\n  fragment JobApplicationStatusMessage on Message {\n    id\n    body\n    createdAt\n    creator {\n      id\n      firstName\n      lastName\n    }\n  }\n':
    types.JobApplicationStatusMessageFragmentDoc,
  '\n  query jobCategories_useAutoCompleteJobCategoryOptions(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n':
    types.JobCategories_UseAutoCompleteJobCategoryOptionsDocument,
  '\n  query jobCategories_useJobCategoryOptions($filters: JobCategoriesFilters) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n':
    types.JobCategories_UseJobCategoryOptionsDocument,
  '\n  fragment JobOfferBody_JobOffer on JobOffer {\n    id\n    description\n    profileDescription\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    typicalProfileUrl\n    careerOutlook\n    recruitmentProcess\n    perksDescription\n    recruitmentAdvice\n    employer {\n      id\n      description\n      nonPoachingAgreementsDescription\n    }\n    ...JobOfferBodyMotivationalSection_JobOffer\n    ...JobOfferBodySummarySection_JobOffer\n    ...JobOfferBodyVideoBriefSection_JobOffer\n    ...JobOfferBodyPerksSection_JobOffer\n  }\n':
    types.JobOfferBody_JobOfferFragmentDoc,
  '\n  fragment JobOfferBodyMotivationalSection_JobOffer on JobOffer {\n    creator {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    motivationalMessageToRecruiters\n  }\n':
    types.JobOfferBodyMotivationalSection_JobOfferFragmentDoc,
  '\n  fragment JobOfferBodyPerksSection_JobOffer on JobOffer {\n    id\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n  }\n':
    types.JobOfferBodyPerksSection_JobOfferFragmentDoc,
  '\n  fragment JobOfferBodySummarySection_JobOffer on JobOffer {\n    id\n    location\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n':
    types.JobOfferBodySummarySection_JobOfferFragmentDoc,
  '\n  fragment JobOfferBodyVideoBriefSection_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n':
    types.JobOfferBodyVideoBriefSection_JobOfferFragmentDoc,
  '\n  fragment UseJobOfferClassificationFromTextForm_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n':
    types.UseJobOfferClassificationFromTextForm_JobOfferLeadFragmentDoc,
  '\n    query UseJobOfferClassificationFromTextForm_employerJobOfferLead(\n      $jobOfferLeadId: String!\n    ) {\n      employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n        ...UseJobOfferClassificationFromTextForm_JobOfferLead\n      }\n    }\n  ':
    types.UseJobOfferClassificationFromTextForm_EmployerJobOfferLeadDocument,
  '\n    mutation UseJobOfferClassificationFromTextForm_classifyAndCreateJobOffer(\n      $input: ClassifyAndCreateJobOfferInput!\n    ) {\n      classifyAndCreateJobOffer(input: $input) {\n        jobId\n      }\n    }\n  ':
    types.UseJobOfferClassificationFromTextForm_ClassifyAndCreateJobOfferDocument,
  '\n  fragment JobOfferContact_User on User {\n    id\n    phoneNumber\n    email\n    ...ChatContact_User\n  }\n':
    types.JobOfferContact_UserFragmentDoc,
  '\n  fragment JobOfferContact_InternalManager on InternalManager {\n    id\n    phoneNumber\n    email\n    ...ChatContact_InternalManager\n  }\n':
    types.JobOfferContact_InternalManagerFragmentDoc,
  '\n  fragment JobOfferContacts_JobOffer on JobOffer {\n    creator {\n      employer {\n        id\n        name\n      }\n      ...JobOfferContact_User\n      ...ActivityMetric_User\n    }\n    internalManager {\n      ...JobOfferContact_InternalManager\n    }\n  }\n':
    types.JobOfferContacts_JobOfferFragmentDoc,
  '\n  fragment JobOfferHeader_JobOffer on JobOffer {\n    title\n    contractType\n    employer {\n      id\n      name\n      logoUrl\n    }\n    isTerminated\n    ...JobOfferStatusTag_JobOffer\n  }\n':
    types.JobOfferHeader_JobOfferFragmentDoc,
  '\n    fragment JobOfferMinimalFormAcademicBackgroundStep_JobOffer on JobOffer {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  ':
    types.JobOfferMinimalFormAcademicBackgroundStep_JobOfferFragmentDoc,
  '\n  fragment JobOfferMinimalFormLocationStep_JobOffer on JobOffer {\n    id\n    location\n    remoteWorkPolicy\n  }\n':
    types.JobOfferMinimalFormLocationStep_JobOfferFragmentDoc,
  '\n  fragment JobOfferMinimalFormProfileStep_JobOffer on JobOffer {\n    id\n    jobCategory {\n      id\n      name\n    }\n    minNumberOfYearsOfExperience\n  }\n':
    types.JobOfferMinimalFormProfileStep_JobOfferFragmentDoc,
  '\n  fragment JobOfferMinimalFormSalaryStep_JobOffer on JobOffer {\n    id\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n  }\n':
    types.JobOfferMinimalFormSalaryStep_JobOfferFragmentDoc,
  '\n  fragment JobOfferMinimalFormSuccessFeeStep_JobOffer on JobOffer {\n    id\n    latestSuccessFee {\n      basisPoints\n    }\n  }\n':
    types.JobOfferMinimalFormSuccessFeeStep_JobOfferFragmentDoc,
  '\n  fragment VideoBriefExpertRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n':
    types.VideoBriefExpertRecordingClickableCard_JobOfferFragmentDoc,
  '\n  fragment VideoBriefSoloRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n':
    types.VideoBriefSoloRecordingClickableCard_JobOfferFragmentDoc,
  '\n  query UseJobOfferVideoBriefSoloRecordingDrawer_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer\n    }\n  }\n  fragment UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer on JobOffer {\n    id\n    videoAskUrl\n  }\n':
    types.UseJobOfferVideoBriefSoloRecordingDrawer_JobOfferDocument,
  '\n  fragment JobOfferMinimalFormVideoBriefStep_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n      startDate\n    }\n    videoAskUrl\n    ...VideoBriefSoloRecordingClickableCard_JobOffer\n    ...VideoBriefExpertRecordingClickableCard_JobOffer\n  }\n':
    types.JobOfferMinimalFormVideoBriefStep_JobOfferFragmentDoc,
  '\n  query useJobOfferMinimalForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n  fragment UseJobOfferMinimalForm_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n    }\n    videoAskUrl\n    ...JobOfferMinimalFormProfileStep_JobOffer\n    ...JobOfferMinimalFormAcademicBackgroundStep_JobOffer\n    ...JobOfferMinimalFormLocationStep_JobOffer\n    ...JobOfferMinimalFormSalaryStep_JobOffer\n    ...JobOfferMinimalFormSuccessFeeStep_JobOffer\n    ...JobOfferMinimalFormVideoBriefStep_JobOffer\n  }\n':
    types.UseJobOfferMinimalForm_JobOfferDocument,
  '\n  mutation jobOfferMinimalForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      id\n      # We need to use the same fragment as the one used in the hook so that the apollo cache updates automatically\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n':
    types.JobOfferMinimalForm_UpdateJobOfferDocument,
  '\n    mutation useJobOfferMinimalFormHandleStepSubmit_completeJobOfferCreationForm(\n      $input: CompleteJobOfferCreationFormInput!\n    ) {\n      completeJobOfferCreationForm(input: $input) {\n        id\n      }\n    }\n  ':
    types.UseJobOfferMinimalFormHandleStepSubmit_CompleteJobOfferCreationFormDocument,
  '\n    mutation useJobOfferMinimalFormHandleStepSubmit_expressInterestInProfiles(\n      $input: ExpressInterestInProfilesInput!\n    ) {\n      expressInterestInProfiles(input: $input) {\n        id\n      }\n    }\n  ':
    types.UseJobOfferMinimalFormHandleStepSubmit_ExpressInterestInProfilesDocument,
  '\n  fragment JobOfferPackageTableCell_JobOffer on JobOffer {\n    minimumRemuneration\n    maximumRemuneration\n    currencyCode\n    maximumVariableRemuneration\n  }\n':
    types.JobOfferPackageTableCell_JobOfferFragmentDoc,
  '\n  fragment JobOfferStatusTag_JobOffer on JobOffer {\n    status\n    isTerminated\n  }\n':
    types.JobOfferStatusTag_JobOfferFragmentDoc,
  '\n  query useJobOfferUpdateForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n':
    types.UseJobOfferUpdateForm_JobOfferDocument,
  '\n  mutation useJobOfferUpdateForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n':
    types.UseJobOfferUpdateForm_UpdateJobOfferDocument,
  '\n  fragment UseJobOfferUpdateForm_JobOffer on JobOffer {\n    title\n    description\n    profileDescription\n    recruitmentProcess\n    numberOfPositionsToFill\n    location\n    contractType\n    currencyCode\n    minimumRemuneration\n    maximumRemuneration\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    maximumVariableRemuneration\n    careerOutlook\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    perksDescription\n    typicalProfileUrl\n    newJobCategory {\n      id\n      name\n    }\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    motivationalMessageToRecruiters\n    ...JobOfferWizardForm_JobOffer\n  }\n':
    types.UseJobOfferUpdateForm_JobOfferFragmentDoc,
  '\n  fragment JobOfferWizardForm_JobOffer on JobOffer {\n    ...JobOfferWizardFormSalaryStep_JobOffer\n  }\n':
    types.JobOfferWizardForm_JobOfferFragmentDoc,
  '\n  fragment JobOfferWizardFormSalaryStep_JobOffer on JobOffer {\n    status\n  }\n':
    types.JobOfferWizardFormSalaryStep_JobOfferFragmentDoc,
  '\n  mutation useJobOfferUploadVideoBrief_updateJobOffer(\n    $input: UpdateJobOfferInput!\n  ) {\n    updateJobOffer(input: $input) {\n      ...EmployerJobOfferPreview_JobOffer\n    }\n  }\n':
    types.UseJobOfferUploadVideoBrief_UpdateJobOfferDocument,
  '\n  fragment CheckIfJobOfferBriefIsRecorded_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n':
    types.CheckIfJobOfferBriefIsRecorded_JobOfferFragmentDoc,
  '\n  query myUser {\n    myUser {\n      __typename\n      ... on User {\n        ...RecruiterLayout_User\n        ...HiringManagerLayout_User\n        firstName\n        lastName\n        email\n        phoneNumber\n        profilePictureUrl\n        mainBusinessType\n        termsOfServiceVersion\n        permissions {\n          name\n        }\n      }\n      ... on UserLead {\n        ...MyUserProvider_UserLead\n      }\n    }\n  }\n  fragment MyUserProvider_UserLead on UserLead {\n    id\n    email\n    mainBusinessType\n  }\n':
    types.MyUserDocument,
  '\n  fragment ProfileDrawerCreatorSection_Profile on Profile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n':
    types.ProfileDrawerCreatorSection_ProfileFragmentDoc,
  '\n  fragment ProfileDrawerCreatorSection_AnonymizedProfile on AnonymizedProfile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n':
    types.ProfileDrawerCreatorSection_AnonymizedProfileFragmentDoc,
  '\n  fragment ProfileDrawerCreatorSection_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n':
    types.ProfileDrawerCreatorSection_RecruitmentTaskFragmentDoc,
  '\n  fragment ProfileDrawerEducationsSection_Profile on Profile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n':
    types.ProfileDrawerEducationsSection_ProfileFragmentDoc,
  '\n  fragment ProfileDrawerEducationsSection_AnonymizedProfile on AnonymizedProfile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n':
    types.ProfileDrawerEducationsSection_AnonymizedProfileFragmentDoc,
  '\n  fragment ProfileDrawerExperiencesSection_Profile on Profile {\n    experiences {\n      ...ProfileExperiences_Experience\n    }\n  }\n':
    types.ProfileDrawerExperiencesSection_ProfileFragmentDoc,
  '\n  fragment ProfileDrawerExperiencesSection_AnonymizedProfile on AnonymizedProfile {\n    experiences {\n      ...ProfileExperiences_AnonymizedExperience\n    }\n  }\n':
    types.ProfileDrawerExperiencesSection_AnonymizedProfileFragmentDoc,
  '\n  fragment ProfileDrawerInfoSection_Profile on Profile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n':
    types.ProfileDrawerInfoSection_ProfileFragmentDoc,
  '\n  fragment ProfileDrawerInfoSection_AnonymizedProfile on AnonymizedProfile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n':
    types.ProfileDrawerInfoSection_AnonymizedProfileFragmentDoc,
  '\n  fragment ProfileDrawerSkillsSection_Profile on Profile {\n    validSkills {\n      id\n      name\n    }\n  }\n':
    types.ProfileDrawerSkillsSection_ProfileFragmentDoc,
  '\n  fragment ProfileDrawerSkillsSection_AnonymizedProfile on AnonymizedProfile {\n    validSkills {\n      id\n      name\n    }\n  }\n':
    types.ProfileDrawerSkillsSection_AnonymizedProfileFragmentDoc,
  '\n  fragment ProfileHeader_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n  }\n':
    types.ProfileHeader_ProfileFragmentDoc,
  '\n  fragment ProfileEducation_Education on Education {\n    id\n    degreeTitle\n    school {\n      id\n      name\n    }\n    startedAt\n    endedAt\n  }\n':
    types.ProfileEducation_EducationFragmentDoc,
  '\n  fragment ProfileEducationsForm_Profile on Profile {\n    id\n    educations {\n      ...ProfileEducation_Education\n    }\n  }\n':
    types.ProfileEducationsForm_ProfileFragmentDoc,
  '\n  fragment ProfileExperience_Experience on Experience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n':
    types.ProfileExperience_ExperienceFragmentDoc,
  '\n  fragment ProfileExperience_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n':
    types.ProfileExperience_AnonymizedExperienceFragmentDoc,
  '\n  fragment ProfileExperiences_Experience on Experience {\n    ...ProfileExperience_Experience\n  }\n':
    types.ProfileExperiences_ExperienceFragmentDoc,
  '\n  fragment ProfileExperiences_AnonymizedExperience on AnonymizedExperience {\n    ...ProfileExperience_AnonymizedExperience\n  }\n':
    types.ProfileExperiences_AnonymizedExperienceFragmentDoc,
  '\n  fragment ProfileExperiencesForm_Experience on Experience {\n    id\n    jobTitle\n    employer {\n      name\n    }\n    startedAt\n    endedAt\n  }\n':
    types.ProfileExperiencesForm_ExperienceFragmentDoc,
  '\n  fragment ProfileExperiencesForm_Profile on Profile {\n    id\n    experiences {\n      ...ProfileExperiencesForm_Experience\n    }\n    linkedInProfileUrl\n  }\n':
    types.ProfileExperiencesForm_ProfileFragmentDoc,
  '\n  fragment ProfileInfo_Profile on Profile {\n    ...ProfileEducationsForm_Profile\n    ...ProfileExperiencesForm_Profile\n    ...ProfileHeader_Profile\n    ...ProfileRequirementsChecklist_Profile\n    ...UpdateProfileCareerAutoSubmitForm_Profile\n    ...UseProfileAcademicBackgroundsAutoSubmitForm_Profile\n    ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    ...UseProfileSkillsAutoSubmitForm_Profile\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    myNote {\n      id\n      createdAt\n      updatedAt\n      body\n    }\n  }\n':
    types.ProfileInfo_ProfileFragmentDoc,
  '\n  fragment UpdateProfileCareerAutoSubmitForm_Profile on Profile {\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    # Below are the fields that are used in this component\n    lastName\n    firstName\n    curriculumVitaeUrl\n    openToWorkUntil\n  }\n':
    types.UpdateProfileCareerAutoSubmitForm_ProfileFragmentDoc,
  '\n    fragment UseProfileAcademicBackgroundsAutoSubmitForm_Profile on Profile {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  ':
    types.UseProfileAcademicBackgroundsAutoSubmitForm_ProfileFragmentDoc,
  '\n  fragment UseProfileIsOpenToWorkAutoSubmitForm_Profile on Profile {\n    id\n    openToWorkUntil\n  }\n':
    types.UseProfileIsOpenToWorkAutoSubmitForm_ProfileFragmentDoc,
  '\n  mutation markProfileAsOpenToWork($input: MarkProfileAsOpenToWorkInput!) {\n    markProfileAsOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n':
    types.MarkProfileAsOpenToWorkDocument,
  '\n  mutation markProfileAsNotOpenToWork(\n    $input: MarkProfileAsNotOpenToWorkInput!\n  ) {\n    markProfileAsNotOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n':
    types.MarkProfileAsNotOpenToWorkDocument,
  '\n  fragment UseProfileJobCategoriesAutoSubmitForm_Profile on Profile {\n    id\n    newJobCategories {\n      id\n      name\n    }\n  }\n':
    types.UseProfileJobCategoriesAutoSubmitForm_ProfileFragmentDoc,
  '\n  mutation updateProfileJobCategories(\n    $input: UpdateProfileJobCategoriesInput!\n  ) {\n    updateProfileJobCategories(input: $input) {\n      ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    }\n  }\n':
    types.UpdateProfileJobCategoriesDocument,
  '\n  fragment UseProfileSkillsAutoSubmitForm_Profile on Profile {\n    id\n    skills {\n      id\n      name\n      status\n    }\n  }\n':
    types.UseProfileSkillsAutoSubmitForm_ProfileFragmentDoc,
  '\n  mutation useProfileSkillsAutoSubmitForm_addProfilePotentialSkillMutation(\n    $input: AddProfilePotentialSkillInput!\n  ) {\n    addProfilePotentialSkill(input: $input) {\n      ...UseProfileSkillsAutoSubmitForm_Profile\n    }\n  }\n':
    types.UseProfileSkillsAutoSubmitForm_AddProfilePotentialSkillMutationDocument,
  '\n  fragment UseUpdateProfileAutoSubmitForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n':
    types.UseUpdateProfileAutoSubmitForm_ProfileFragmentDoc,
  '\n  mutation useUpdateProfileAutoSubmitForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseUpdateProfileAutoSubmitForm_Profile\n    }\n  }\n':
    types.UseUpdateProfileAutoSubmitForm_UpdateProfileMutationDocument,
  '\n  fragment ProfileRequirementsChecklist_Profile on Profile {\n    id\n    email\n    approximateFirstJobStartDate\n    newJobCategories {\n      id\n    }\n    academicBackgrounds {\n      id\n    }\n    skills {\n      id\n    }\n    urbanArea {\n      id\n    }\n    experiences {\n      id\n    }\n  }\n':
    types.ProfileRequirementsChecklist_ProfileFragmentDoc,
  '\n  query useProfile_profileQuery($input: GetProfileInput!) {\n    profile(input: $input) {\n      ...ProfileInfo_Profile\n    }\n  }\n':
    types.UseProfile_ProfileQueryDocument,
  '\n    query useRecruiterProfilesSearch_recruiterJobApplicationsQuery(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: JobApplicationsFilters\n    ) {\n      recruiterJobApplications(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        nodes {\n          profile {\n            id\n          }\n        }\n      }\n    }\n  ':
    types.UseRecruiterProfilesSearch_RecruiterJobApplicationsQueryDocument,
  '\n  query recruiterProfiles($filters: RecruiterProfilesFilters) {\n    recruiterProfiles(filters: $filters) {\n      id\n      firstName\n      lastName\n      email\n      phoneNumber\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n      createdAt\n    }\n  }\n':
    types.RecruiterProfilesDocument,
  '\n    query recruiterLayout_recruiterRecruitmentTaskRecommendationsCount(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n    }\n  ':
    types.RecruiterLayout_RecruiterRecruitmentTaskRecommendationsCountDocument,
  '\n  fragment RecruiterLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    roles {\n      name\n    }\n    searchFirm {\n      id\n      contracts {\n        id\n        statuses {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.RecruiterLayout_UserFragmentDoc,
  '\n  fragment RecruiterSettingsGeneralTab_User on User {\n    id\n    searchFirm {\n      id\n      name\n      description\n    }\n    firstName\n    lastName\n    phoneNumber\n    email\n    isAuthorizedToUseMarketplace\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n':
    types.RecruiterSettingsGeneralTab_UserFragmentDoc,
  '\n  fragment RecruiterSettingsSpecializationsTab_User on User {\n    recruiterNewJobCategories {\n      id\n      name\n    }\n    recruiterActivitySectors {\n      id\n      name\n    }\n  }\n':
    types.RecruiterSettingsSpecializationsTab_UserFragmentDoc,
  '\n  query jobCategories_useRecruiterSettingsSpecializationsTab(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n':
    types.JobCategories_UseRecruiterSettingsSpecializationsTabDocument,
  '\n  mutation updateRecruiterNewJobCategoryAssociations(\n    $recruiterJobCategoryAssociationInputs: [RecruiterJobCategoryAssociationInput!]!\n  ) {\n    updateRecruiterNewJobCategoryAssociations(\n      recruiterJobCategoryAssociationInputs: $recruiterJobCategoryAssociationInputs\n    )\n  }\n':
    types.UpdateRecruiterNewJobCategoryAssociationsDocument,
  '\n  fragment RecruiterSuperRecruiter_RecruiterActivityMetrics on RecruiterActivityMetrics {\n    jobApplicationMetrics(input: $jobApplicationMetricsInput) {\n      numberOfJobApplications\n      interviewConversionRateInBasisPoints\n      hireConversionRateInBasisPoints\n    }\n  }\n':
    types.RecruiterSuperRecruiter_RecruiterActivityMetricsFragmentDoc,
  '\n  fragment RecruiterSuperRecruiter_UserActivityMetric on UserActivityMetric {\n    recruiterActivityMetrics {\n      ...RecruiterSuperRecruiter_RecruiterActivityMetrics\n    }\n  }\n':
    types.RecruiterSuperRecruiter_UserActivityMetricFragmentDoc,
  '\n  fragment RecruiterSuperRecruiter_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n      ...RecruiterSuperRecruiter_UserActivityMetric\n    }\n  }\n':
    types.RecruiterSuperRecruiter_UserFragmentDoc,
  '\n  query recruiterSuperRecruiter_myUserQuery(\n    $jobApplicationMetricsInput: RecruiterJobApplicationMetricsInput!\n  ) {\n    myUser {\n      __typename\n      ...RecruiterSuperRecruiter_User\n    }\n  }\n':
    types.RecruiterSuperRecruiter_MyUserQueryDocument,
  '\n  fragment RecruitmentTaskWithApplicationsCard_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask\n    jobApplications {\n      ...JobApplicationProfileDrawer_JobApplication\n      ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n      ...JobApplicationLastUpdateAlert_JobApplication\n      profile {\n        firstName\n        lastName\n      }\n    }\n  }\n':
    types.RecruitmentTaskWithApplicationsCard_RecruitmentTaskFragmentDoc,
  '\n    fragment RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      jobOffer {\n        id\n        title\n      }\n    }\n  ':
    types.RecruitmentTaskWithApplicationsCardHeader_RecruitmentTaskFragmentDoc,
  '\n    fragment RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskWithApplicationsCard_RecruitmentTask\n    }\n  ':
    types.RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTaskFragmentDoc,
  '\n  query recruitmentTasksOfRecruiterForMyEmployer(\n    $input: RecruitmentTasksOfRecruiterForMyEmployerInput!\n  ) {\n    recruitmentTasksOfRecruiterForMyEmployer(input: $input) {\n      ...RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask\n    }\n  }\n':
    types.RecruitmentTasksOfRecruiterForMyEmployerDocument,
  '\n  query pendingRecruiterRecruitmentTaskRecommendationsCount(\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n  }\n':
    types.PendingRecruiterRecruitmentTaskRecommendationsCountDocument,
  '\n    mutation rejectRecruitmentTaskRecommendation(\n      $input: RejectRecruitementTaskRecommendationInput!\n    ) {\n      rejectRecruitmentTaskRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  ':
    types.RejectRecruitmentTaskRecommendationDocument,
  '\n  fragment UseSchoolsOptions_School on School {\n    id\n    name\n  }\n':
    types.UseSchoolsOptions_SchoolFragmentDoc,
  '\n  query useSchoolsOptions_schools($input: GetSchoolsInput!) {\n    schools(input: $input) {\n      ...UseSchoolsOptions_School\n    }\n  }\n':
    types.UseSchoolsOptions_SchoolsDocument,
  '\n  mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n    agreeToTermsOfService(input: $input) {\n      id\n      termsOfServiceVersion\n    }\n  }\n':
    types.AgreeToTermsOfServiceDocument,
  '\n  fragment ActivityMetric_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n':
    types.ActivityMetric_UserFragmentDoc,
  '\n  query profilePictureUploadUrlAndFileName(\n    $input: ProfilePictureUploadUrlAndFileNameInput!\n  ) {\n    profilePictureUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n':
    types.ProfilePictureUploadUrlAndFileNameDocument,
  '\n  mutation updateMyProfilePicture($input: UpdateMyProfilePictureInput!) {\n    updateMyProfilePicture(input: $input) {\n      id\n      profilePictureUrl\n    }\n  }\n':
    types.UpdateMyProfilePictureDocument,
  '\n  fragment ChatContact_User on User {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n':
    types.ChatContact_UserFragmentDoc,
  '\n  fragment ChatContact_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n':
    types.ChatContact_InternalManagerFragmentDoc,
  '\n  mutation createMyEmployer($input: CreateMyEmployerInput!) {\n    createMyEmployer(input: $input) {\n      id\n      employer {\n        id\n        name\n      }\n    }\n  }\n':
    types.CreateMyEmployerDocument,
  '\n  fragment UseRecruiterProfilesTableColumns_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    curriculumVitaeUrl\n    linkedInProfileUrl\n    profilePictureUrl\n    createdAt\n    openToWorkUntil\n    lastJobApplicationRecommendationsComputation {\n      id\n      startedAt\n      status\n    }\n  }\n':
    types.UseRecruiterProfilesTableColumns_ProfileFragmentDoc,
  '\n  query useRecruiterProfilesPage_paginatedRecruiterProfilesQuery(\n    $filters: RecruiterProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedRecruiterProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseRecruiterProfilesTableColumns_Profile\n      }\n    }\n  }\n':
    types.UseRecruiterProfilesPage_PaginatedRecruiterProfilesQueryDocument,
  '\n    query recruitmentTaskRecommendation($id: String!) {\n      recruitmentTaskRecommendation(id: $id) {\n        ...RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation\n      }\n    }\n  ':
    types.RecruitmentTaskRecommendationDocument,
  '\n    fragment RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      recruiterFlatFeeInBasisPoints\n      recruiterSuccessFeeInBasisPoints\n      jobOffer {\n        id\n        ...JobOfferHeader_JobOffer\n        ...JobOfferBody_JobOffer\n        ...JobOfferContacts_JobOffer\n        latestSuccessFee {\n          id\n          basisPoints\n        }\n        isTerminated\n      }\n      ...RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation\n    }\n  ':
    types.RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendationFragmentDoc,
  '\n    fragment RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      expirationDate\n      jobOffer {\n        isTerminated\n      }\n    }\n  ':
    types.RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendationFragmentDoc,
  '\n    fragment RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      expirationDate\n      recruiterSuccessFeeInBasisPoints\n      recruiterFlatFeeInBasisPoints\n      jobOffer {\n        title\n        priorityIndicator\n        employer {\n          name\n        }\n        location\n        latestSuccessFee {\n          basisPoints\n        }\n        ...JobOfferPackageTableCell_JobOffer\n      }\n    }\n  ':
    types.RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendationFragmentDoc,
  '\n    query RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendations(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendations(filters: $filters) {\n        ...RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n      }\n    }\n  ':
    types.RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendationsDocument,
  '\n  query TermsOfServiceAgreementBox_termsOfService(\n    $input: TermsOfServiceInput!\n  ) {\n    termsOfService(input: $input) {\n      id\n      content\n    }\n  }\n':
    types.TermsOfServiceAgreementBox_TermsOfServiceDocument,
  '\n    mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n      agreeToTermsOfService(input: $input) {\n        id\n        termsOfServiceVersion\n      }\n    }\n  ':
    types.AgreeToTermsOfServiceDocument,
  '\n                query deprecatedJobCategories_RecruiterRecruitmentTasksFilterModal(\n                  $filters: DeprecatedJobCategoriesFilters\n                ) {\n                  deprecatedJobCategories(filters: $filters) {\n                    id\n                    name\n                  }\n                }\n              ':
    types.DeprecatedJobCategories_RecruiterRecruitmentTasksFilterModalDocument,
  '\n  query recruiterRecruitmentTasksTable_recruiterRecruitmentTasks(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: RecruiterRecruitmentTasksFilters\n  ) {\n    recruiterRecruitmentTasks(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...RecruiterRecruitmentTasksTable_RecruitmentTask\n      }\n    }\n  }\n':
    types.RecruiterRecruitmentTasksTable_RecruiterRecruitmentTasksDocument,
  '\n  fragment RecruiterRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOffer {\n      id\n      title\n      location\n      priorityIndicator\n      isTerminated\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      creator {\n        ...ChatContact_User\n      }\n      employer {\n        name\n      }\n      ...JobOfferPackageTableCell_JobOffer\n    }\n  }\n':
    types.RecruiterRecruitmentTasksTable_RecruitmentTaskFragmentDoc,
  '\n  query ActivateMyJobOfferPage_employerJobOfferLead($jobOfferLeadId: String!) {\n    employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n      ...ActivateMyJobOfferPage_JobOfferLead\n    }\n  }\n  fragment ActivateMyJobOfferPage_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n':
    types.ActivateMyJobOfferPage_EmployerJobOfferLeadDocument,
  '\n  mutation createCollectPaymentDetailsCheckoutSession {\n    createCollectPaymentDetailsCheckoutSession {\n      url\n    }\n  }\n':
    types.CreateCollectPaymentDetailsCheckoutSessionDocument,
  '\n  fragment SelectedProfileDrawer_AnonymizedProfile on AnonymizedProfile {\n    ...SelectedProfileDrawerHeader_AnonymizedProfile\n    ...ProfileDrawerCreatorSection_AnonymizedProfile\n    ...ProfileDrawerInfoSection_AnonymizedProfile\n    ...ProfileDrawerSkillsSection_AnonymizedProfile\n    ...ProfileDrawerExperiencesSection_AnonymizedProfile\n    ...ProfileDrawerEducationsSection_AnonymizedProfile\n  }\n':
    types.SelectedProfileDrawer_AnonymizedProfileFragmentDoc,
  '\n  fragment SelectedProfileDrawerHeader_AnonymizedProfile on AnonymizedProfile {\n    id\n    profilePictureUrl\n  }\n':
    types.SelectedProfileDrawerHeader_AnonymizedProfileFragmentDoc,
  '\n  fragment EmployerJobOfferDashboard_JobOffer on JobOffer {\n    ...EmployerJobOfferHeader_JobOffer\n    ...EmployerJobOfferTimeline_JobOffer\n    ...EmployerJobOfferTabs_JobOffer\n    ...EmployerJobOfferPageProvider_JobOffer\n    status\n    internalManager {\n      ...JobOfferContact_InternalManager\n      phoneNumber\n      email\n    }\n  }\n':
    types.EmployerJobOfferDashboard_JobOfferFragmentDoc,
  '\n  query employerJobOfferDashboard_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      ...EmployerJobOfferDashboard_JobOffer\n    }\n  }\n':
    types.EmployerJobOfferDashboard_JobOfferQueryDocument,
  '\n  fragment EmployerJobOfferHeader_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfOngoingRecruitmentTasks\n    briefingVideoUrl\n    flatFee\n    currencyCode\n    employer {\n      id\n      name\n      logoUrl\n    }\n    creator {\n      id\n      firstName\n      lastName\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    ...EmployerJobOfferManagementPopover_JobOffer\n    ...JobOfferStatusTag_JobOffer\n  }\n':
    types.EmployerJobOfferHeader_JobOfferFragmentDoc,
  '\n  fragment UseBookVideoBriefMeeting_VideoBriefMeeting on VideoBriefMeeting {\n    id\n    startDate\n    endDate\n    updatedAt\n    jobOffer {\n      id\n      videoBriefMeeting {\n        id\n        startDate\n      }\n    }\n  }\n':
    types.UseBookVideoBriefMeeting_VideoBriefMeetingFragmentDoc,
  '\n  mutation useBookVideoBriefMeeting_bookVideoBriefMeetingMutation(\n    $input: BookVideoBriefMeetingInput!\n  ) {\n    bookVideoBriefMeeting(input: $input) {\n      ...UseBookVideoBriefMeeting_VideoBriefMeeting\n    }\n  }\n':
    types.UseBookVideoBriefMeeting_BookVideoBriefMeetingMutationDocument,
  '\n  fragment EmployerJobOfferManagementPopover_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    briefingVideoUrl\n    ...EmployerJobOfferTerminationModal_JobOffer\n  }\n':
    types.EmployerJobOfferManagementPopover_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n    numberOfOngoingRecruitmentTasks\n  }\n':
    types.EmployerJobOfferTerminationModal_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferTabs_JobOffer on JobOffer {\n    status\n    ...EmployerJobOfferJobApplicationsTabContent_JobOffer\n    ...EmployerJobOfferRecruitersTabContent_JobOffer\n    ...EmployerJobOfferPreviewTabContent_JobOffer\n    ...EmployerJobOfferSelectedProfilesTabContent_JobOffer\n  }\n':
    types.EmployerJobOfferTabs_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferJobApplicationsCards_JobApplication on JobApplication {\n    id\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      firstName\n      lastName\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n    }\n  }\n':
    types.EmployerJobOfferJobApplicationsCards_JobApplicationFragmentDoc,
  '\n  fragment EmployerJobOfferJobApplicationsList_JobApplication on JobApplication {\n    ...EmployerJobOfferJobApplicationsTable_JobApplication\n    ...EmployerJobOfferJobApplicationsCards_JobApplication\n  }\n':
    types.EmployerJobOfferJobApplicationsList_JobApplicationFragmentDoc,
  '\n  fragment EmployerJobOfferJobApplicationsTabContent_JobOffer on JobOffer {\n    id\n    status\n    numberOfJobApplications\n    isTerminated\n    ...CheckIfJobOfferBriefIsRecorded_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n':
    types.EmployerJobOfferJobApplicationsTabContent_JobOfferFragmentDoc,
  '\n  query employerJobOfferJobApplications(\n    $jobOfferId: String!\n    $jobApplicationsFilters: JobApplicationsFilters\n  ) {\n    jobOfferApplications(\n      jobOfferId: $jobOfferId\n      filters: $jobApplicationsFilters\n    ) {\n      ...EmployerJobOfferJobApplicationsList_JobApplication\n    }\n  }\n':
    types.EmployerJobOfferJobApplicationsDocument,
  '\n  fragment JobApplicationRecruiterDetailsModal_User on User {\n    id\n    createdAt\n    firstName\n    lastName\n    profilePictureUrl\n    searchFirm {\n      id\n      name\n      description\n    }\n    roles {\n      name\n    }\n  }\n':
    types.JobApplicationRecruiterDetailsModal_UserFragmentDoc,
  '\n  fragment EmployerJobOfferJobApplicationsTable_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileNameCell_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    ...JobApplicationProfileCareerCell_JobApplication\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    profile {\n      id\n      creator {\n        id\n        ...JobApplicationRecruiterCell_User\n        ...JobApplicationRecruiterDetailsModal_User\n      }\n    }\n    statuses {\n      id\n    }\n    recruitmentTask {\n      id\n      ...JobApplicationRecruiterCell_RecruitmentTask\n    }\n  }\n':
    types.EmployerJobOfferJobApplicationsTable_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationProfileCareerCell_JobApplication on JobApplication {\n    profile {\n      curriculumVitaeUrl\n      linkedInProfileUrl\n    }\n  }\n':
    types.JobApplicationProfileCareerCell_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationProfileNameCell_JobApplication on JobApplication {\n    id\n    profile {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n  }\n':
    types.JobApplicationProfileNameCell_JobApplicationFragmentDoc,
  '\n  fragment JobApplicationRecruiterCell_User on User {\n    id\n    firstName\n    lastName\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n':
    types.JobApplicationRecruiterCell_UserFragmentDoc,
  '\n  fragment JobApplicationRecruiterCell_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n':
    types.JobApplicationRecruiterCell_RecruitmentTaskFragmentDoc,
  '\n  fragment EmployerJobOfferPreview_JobOffer on JobOffer {\n    ...JobOfferBody_JobOffer\n    ...JobOfferContacts_JobOffer\n  }\n':
    types.EmployerJobOfferPreview_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferPreviewTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferPreview_JobOffer\n  }\n':
    types.EmployerJobOfferPreviewTabContent_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferRecruitersTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferRecruitmentTasksList_JobOffer\n  }\n':
    types.EmployerJobOfferRecruitersTabContent_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n':
    types.EmployerJobOfferRecruitmentTasksList_JobOfferFragmentDoc,
  '\n  fragment UseEmployerJobOfferRecruitmentTasksTable_JobApplication on JobApplication {\n    id\n  }\n':
    types.UseEmployerJobOfferRecruitmentTasksTable_JobApplicationFragmentDoc,
  '\n  fragment UseEmployerJobOfferRecruitmentTasksTable_User on User {\n    id\n    createdAt\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n':
    types.UseEmployerJobOfferRecruitmentTasksTable_UserFragmentDoc,
  '\n    fragment UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      recruiter {\n        ...UseEmployerJobOfferRecruitmentTasksTable_User\n      }\n      jobApplications {\n        ...UseEmployerJobOfferRecruitmentTasksTable_JobApplication\n      }\n    }\n  ':
    types.UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTaskFragmentDoc,
  '\n  query useEmployerJobOfferRecruitmentTasksList($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask\n    }\n  }\n':
    types.UseEmployerJobOfferRecruitmentTasksListDocument,
  '\n  fragment EmployerJobOfferSelectedProfilesTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n':
    types.EmployerJobOfferSelectedProfilesTabContent_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferGoToProfileSearchButton_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n  }\n':
    types.EmployerJobOfferGoToProfileSearchButton_JobOfferFragmentDoc,
  '\n    fragment EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      ...EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile\n      ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment\n      ...EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile\n    }\n  ':
    types.EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfileFragmentDoc,
  '\n  fragment EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile\n    }\n  }\n':
    types.EmployerJobOfferLikedAnonymizedProfilesTable_JobOfferFragmentDoc,
  '\n    query employerJobOfferLikedAnonymizedProfilesTable_anonymizedProfile(\n      $input: GetProfileInput!\n    ) {\n      anonymizedProfile(input: $input) {\n        ...SelectedProfileDrawer_AnonymizedProfile\n      }\n    }\n  ':
    types.EmployerJobOfferLikedAnonymizedProfilesTable_AnonymizedProfileDocument,
  '\n    fragment EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      createdAt\n    }\n  ':
    types.EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfileFragmentDoc,
  '\n  fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile on AnonymizedProfile {\n    id\n    firstName\n    profilePictureUrl\n    experiences {\n      id\n      jobTitle\n      employer {\n        name\n      }\n    }\n  }\n':
    types.EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfileFragmentDoc,
  '\n    fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        ...EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile\n      }\n    }\n  ':
    types.EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfileFragmentDoc,
  '\n  fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User on User {\n    id\n    email\n    firstName\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n':
    types.EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_UserFragmentDoc,
  '\n    fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        creator {\n          id\n          ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User\n        }\n      }\n    }\n  ':
    types.EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragmentFragmentDoc,
  '\n  fragment EmployerJobOfferTimeline_JobOffer on JobOffer {\n    status\n    numberOfOngoingRecruitmentTasks\n    numberOfJobApplications\n    totalNumberOfAcceptedForFirstInterviewJobApplications\n    numberOfHiredJobApplications\n    numberOfNewJobApplications\n  }\n':
    types.EmployerJobOfferTimeline_JobOfferFragmentDoc,
  '\n  fragment EmployerJobOfferPageProvider_JobOffer on JobOffer {\n    id\n    numberOfJobApplications\n  }\n':
    types.EmployerJobOfferPageProvider_JobOfferFragmentDoc,
  '\n  query employerJobOffersPage {\n    myUser {\n      __typename\n      ... on User {\n        ...ActivityMetric_User\n      }\n    }\n  }\n':
    types.EmployerJobOffersPageDocument,
  '\n    query employerJobOfferLeads_employerPaginatedJobOfferLeads(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: EmployerJobOfferLeadsFilters\n    ) {\n      employerPaginatedJobOfferLeads(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        totalCount\n        hasNextPage\n        nodes {\n          ...EmployerJobOfferLeads_JobOfferLead\n        }\n      }\n    }\n    fragment EmployerJobOfferLeads_JobOfferLead on JobOfferLead {\n      id\n      title\n      location\n      description\n      jobBoard\n      jobBoardUrl\n    }\n  ':
    types.EmployerJobOfferLeads_EmployerPaginatedJobOfferLeadsDocument,
  '\n  query employerJobOffersTable_employerPaginatedJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n':
    types.EmployerJobOffersTable_EmployerPaginatedJobOffersDocument,
  '\n  fragment EmployerJobOffersTableColumns_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfNewJobApplications\n    status\n    ...JobOfferStatusTag_JobOffer\n  }\n':
    types.EmployerJobOffersTableColumns_JobOfferFragmentDoc,
  '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Skill on Skill {\n    id\n    name\n  }\n':
    types.EmployerProfileSearchPageAnonymizedProfileCard_SkillFragmentDoc,
  '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...EmployerProfileSearchPageAnonymizedProfileCard_Skill\n    }\n  }\n':
    types.EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragmentDoc,
  '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n':
    types.EmployerProfileSearchPageAnonymizedProfileCard_EducationFragmentDoc,
  '\n    fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  ':
    types.EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragmentDoc,
  '\n  fragment EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n':
    types.EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfileFragmentDoc,
  '\n  query EmployerProfileSearchPageUseAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n':
    types.EmployerProfileSearchPageUseAnonymizedProfileList_PaginatedAnonymizedProfilesDocument,
  '\n  fragment EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n':
    types.EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfileFragmentDoc,
  '\n  fragment UseJobOfferSelectionForm_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n':
    types.UseJobOfferSelectionForm_AnonymizedProfileFragmentDoc,
  '\n  fragment UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      ...UseJobOfferSelectionForm_AnonymizedProfile\n    }\n  }\n':
    types.UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfileFragmentDoc,
  '\n  fragment UseJobOfferSelectionForm_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    status\n    isTerminated\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n    likedAnonymizedProfiles {\n      ...UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile\n    }\n  }\n':
    types.UseJobOfferSelectionForm_JobOfferFragmentDoc,
  '\n  query EmployerProfileSearchPage_employerJobOffers {\n    employerJobOffers {\n      ...UseJobOfferSelectionForm_JobOffer\n    }\n  }\n':
    types.EmployerProfileSearchPage_EmployerJobOffersDocument,
  '\n  fragment useProfileSearchSelectionSubmit_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n':
    types.UseProfileSearchSelectionSubmit_AnonymizedProfileFragmentDoc,
  '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n  }\n':
    types.OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperienceFragmentDoc,
  '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n':
    types.OnboardingEmployerProfileSearchPageAnonymizedProfileCard_EducationFragmentDoc,
  '\n    fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  ':
    types.OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfileFragmentDoc,
  '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n':
    types.OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfileFragmentDoc,
  '\n  query EmployerProfileSearchPageAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n':
    types.EmployerProfileSearchPageAnonymizedProfileList_PaginatedAnonymizedProfilesDocument,
  '\n  mutation createMySearchFirm($input: CreateSearchFirmInput!) {\n    createSearchFirm(input: $input) {\n      id\n      name\n    }\n  }\n':
    types.CreateMySearchFirmDocument,
  '\n    query recruiterRecruitmentTaskDetailsByJobOfferId($jobOfferId: String!) {\n      recruiterRecruitmentTaskByJobOfferId(jobOfferId: $jobOfferId) {\n        id\n        jobOffer {\n          ...JobOfferHeader_JobOffer\n        }\n        ...RecruiterRecruitmentTaskTabs_RecruitmentTask\n      }\n    }\n  ':
    types.RecruiterRecruitmentTaskDetailsByJobOfferIdDocument,
  '\n  fragment RecruitmentTaskDetailsTabContent_RecruitmentTask on RecruitmentTask {\n    id\n    status\n    recruiterFlatFeeInBasisPoints\n    recruiterSuccessFeeInBasisPoints\n    jobOffer {\n      id\n      isTerminated\n      contractType\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      ...JobOfferBody_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n  }\n':
    types.RecruitmentTaskDetailsTabContent_RecruitmentTaskFragmentDoc,
  '\n  fragment RecruiterRecruitmentTaskTabs_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskPageProvider_RecruitmentTask\n    ...RecruitmentTaskDetailsTabContent_RecruitmentTask\n    ...RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask\n  }\n':
    types.RecruiterRecruitmentTaskTabs_RecruitmentTaskFragmentDoc,
  '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Skill on Skill {\n    id\n    name\n  }\n':
    types.RecruiterRecruitmentTaskRelevantProfileCard_SkillFragmentDoc,
  '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...RecruiterRecruitmentTaskRelevantProfileCard_Skill\n    }\n  }\n':
    types.RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperienceFragmentDoc,
  '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n':
    types.RecruiterRecruitmentTaskRelevantProfileCard_EducationFragmentDoc,
  '\n    fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      firstName\n      profilePictureUrl\n      creator {\n        id\n        firstName\n        lastName\n        email\n      }\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_Education\n      }\n    }\n  ':
    types.RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfileFragmentDoc,
  '\n  fragment RecruitmentTaskRelevantProfileCardList_Employer on Employer {\n    id\n    name\n    ...RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer\n  }\n':
    types.RecruitmentTaskRelevantProfileCardList_EmployerFragmentDoc,
  '\n  fragment RecruitmentTaskRelevantProfileCardList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile\n  }\n':
    types.RecruitmentTaskRelevantProfileCardList_AnonymizedProfileFragmentDoc,
  '\n  fragment RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_AnonymizedProfile\n    }\n  }\n':
    types.RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfileFragmentDoc,
  '\n  fragment RecruitmentTaskRelevantProfileCardList_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile\n    }\n    employer {\n      ...RecruitmentTaskRelevantProfileCardList_Employer\n    }\n  }\n':
    types.RecruitmentTaskRelevantProfileCardList_JobOfferFragmentDoc,
  '\n  fragment RecruitmentTaskRelevantProfileCardList_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruitmentTaskRelevantProfileCardList_JobOffer\n    }\n  }\n':
    types.RecruitmentTaskRelevantProfileCardList_RecruitmentTaskFragmentDoc,
  '\n    fragment RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer on Employer {\n      id\n      name\n    }\n  ':
    types.RecruitmentTaskRelevantProfilesEmptyListDisplay_EmployerFragmentDoc,
  '\n    fragment RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_RecruitmentTask\n    }\n  ':
    types.RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTaskFragmentDoc,
  '\n  fragment RecruiterRecruitmentTaskPageProvider_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      id\n    }\n  }\n':
    types.RecruiterRecruitmentTaskPageProvider_JobOfferFragmentDoc,
  '\n  fragment RecruitmentTaskPageProvider_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruiterRecruitmentTaskPageProvider_JobOffer\n    }\n  }\n':
    types.RecruitmentTaskPageProvider_RecruitmentTaskFragmentDoc,
  '\n  query recruiterSettingsUser {\n    myUser {\n      __typename\n      ...RecruiterSettingsGeneralTab_User\n      ...RecruiterSettingsSpecializationsTab_User\n    }\n  }\n':
    types.RecruiterSettingsUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useAcademicBackgroundOptions_academicBackgrounds {\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query useAcademicBackgroundOptions_academicBackgrounds {\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferLinksPopover_JobOffer on JobOffer {\n    id\n    ...JobOfferCurrentStaffingDrawer_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferLinksPopover_JobOffer on JobOffer {\n    id\n    ...JobOfferCurrentStaffingDrawer_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferCurrentStaffingDrawer_JobOffer on JobOffer {\n    id\n    title\n    employer {\n      id\n      name\n    }\n    ...JobOfferRecruitmentTasksList_JobOffer\n    ...JobOfferRecruitmentTaskRecommendationsList_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferCurrentStaffingDrawer_JobOffer on JobOffer {\n    id\n    title\n    employer {\n      id\n      name\n    }\n    ...JobOfferRecruitmentTasksList_JobOffer\n    ...JobOfferRecruitmentTaskRecommendationsList_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      status\n      ...JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n    }\n  '
): (typeof documents)['\n    fragment JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      status\n      ...JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query jobOfferRecruitmentTaskRecommendations(\n    $jobOfferId: String!\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    jobOfferRecruitmentTaskRecommendations(\n      jobOfferId: $jobOfferId\n      filters: $filters\n    ) {\n      ...JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation\n    }\n  }\n'
): (typeof documents)['\n  query jobOfferRecruitmentTaskRecommendations(\n    $jobOfferId: String!\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    jobOfferRecruitmentTaskRecommendations(\n      jobOfferId: $jobOfferId\n      filters: $filters\n    ) {\n      ...JobOfferRecruitmentTaskRecommendationsList_RecruitmentTaskRecommendation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferRecruitmentTaskRecommendationsList_JobOffer on JobOffer {\n    id\n    status\n  }\n'
): (typeof documents)['\n  fragment JobOfferRecruitmentTaskRecommendationsList_JobOffer on JobOffer {\n    id\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      recruiter {\n        id\n        email\n        phoneNumber\n        roles {\n          name\n        }\n        ...ChatContact_User\n      }\n      status\n    }\n  '
): (typeof documents)['\n    fragment JobOfferRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      recruiter {\n        id\n        email\n        phoneNumber\n        roles {\n          name\n        }\n        ...ChatContact_User\n      }\n      status\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n'
): (typeof documents)['\n  fragment JobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferRecruitmentTasksList_RecruitmentTask on RecruitmentTask {\n    status\n    ...JobOfferRecruitmentTasksTable_RecruitmentTask\n  }\n'
): (typeof documents)['\n  fragment JobOfferRecruitmentTasksList_RecruitmentTask on RecruitmentTask {\n    status\n    ...JobOfferRecruitmentTasksTable_RecruitmentTask\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query jobOfferRecruitmentTasks($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...JobOfferRecruitmentTasksList_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  query jobOfferRecruitmentTasks($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...JobOfferRecruitmentTasksList_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiter {\n      id\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n    jobApplications {\n      id\n    }\n    status\n  }\n'
): (typeof documents)['\n  fragment JobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiter {\n      id\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n    jobApplications {\n      id\n    }\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query JobOfferSelectionTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query JobOfferSelectionTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RecruitersCopySelectionFromJobOfferTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query RecruitersCopySelectionFromJobOfferTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseJobOfferSelectionTableColumns_JobOffer\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation staffJobOffer($input: StaffJobOfferInput!) {\n    staffJobOffer(input: $input) {\n      recruiterId\n      recruiterEmail\n      error\n    }\n  }\n'
): (typeof documents)['\n  mutation staffJobOffer($input: StaffJobOfferInput!) {\n    staffJobOffer(input: $input) {\n      recruiterId\n      recruiterEmail\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment useJobOfferAutoStaffing_User on User {\n    id\n    email\n    firstName\n    lastName\n    roles {\n      name\n    }\n    jobOfferStaffingRecruiterTier\n  }\n'
): (typeof documents)['\n  fragment useJobOfferAutoStaffing_User on User {\n    id\n    email\n    firstName\n    lastName\n    roles {\n      name\n    }\n    jobOfferStaffingRecruiterTier\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInput(\n    $input: JobOfferStaffingStandardStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingStandardStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'
): (typeof documents)['\n  query useJobOfferAutoStaffingStandard_JobOfferStaffingStandardStrategySelectedRecruitersInput(\n    $input: JobOfferStaffingStandardStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingStandardStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobOfferAutoStaffingExploratory_jobOfferStaffingExploratoryStrategySelectedRecruiters(\n    $input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingExploratoryStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'
): (typeof documents)['\n  query useJobOfferAutoStaffingExploratory_jobOfferStaffingExploratoryStrategySelectedRecruiters(\n    $input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingExploratoryStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobOfferAutoStaffingCopy_jobOfferStaffingCopyStrategySelectedRecruiters(\n    $input: JobOfferStaffingCopyStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingCopyStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'
): (typeof documents)['\n  query useJobOfferAutoStaffingCopy_jobOfferStaffingCopyStrategySelectedRecruiters(\n    $input: JobOfferStaffingCopyStrategySelectedRecruitersInput!\n  ) {\n    jobOfferStaffingCopyStrategySelectedRecruiters(input: $input) {\n      ...useJobOfferAutoStaffing_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferSelectionTableColumns_JobOffer on JobOffer {\n    id\n    title\n    jobCategory {\n      name\n    }\n    employer {\n      name\n    }\n    ...JobOfferStatusTag_JobOffer\n    ...JobOfferLinksPopover_JobOffer\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferSelectionTableColumns_JobOffer on JobOffer {\n    id\n    title\n    jobCategory {\n      name\n    }\n    employer {\n      name\n    }\n    ...JobOfferStatusTag_JobOffer\n    ...JobOfferLinksPopover_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation staffRecruiter($input: StaffRecruiterInput!) {\n    staffRecruiter(input: $input) {\n      jobOfferId\n      jobOfferTitle\n      error\n    }\n  }\n'
): (typeof documents)['\n  mutation staffRecruiter($input: StaffRecruiterInput!) {\n    staffRecruiter(input: $input) {\n      jobOfferId\n      jobOfferTitle\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StaffingFromRecruiterJobOffersTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n    $recruiterId: String!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseStaffingFromRecruiterJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query StaffingFromRecruiterJobOffersTable_paginatedJobOffersQuery(\n    $input: PaginatedJobOffersInput!\n    $recruiterId: String!\n  ) {\n    paginatedJobOffers(input: $input) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseStaffingFromRecruiterJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment UseStaffingFromRecruiterJobOffersTableColumns_JobOffer on JobOffer {\n      id\n      title\n      createdAt\n      status\n      employer {\n        id\n        name\n      }\n      recruiterLatestRecruitmentTaskRecommendation(recruiterId: $recruiterId) {\n        id\n        createdAt\n        status\n      }\n      recruiterRecruitmentTask(recruiterId: $recruiterId) {\n        id\n        status\n      }\n      ...JobOfferStatusTag_JobOffer\n      ...JobOfferLinksPopover_JobOffer\n    }\n  '
): (typeof documents)['\n    fragment UseStaffingFromRecruiterJobOffersTableColumns_JobOffer on JobOffer {\n      id\n      title\n      createdAt\n      status\n      employer {\n        id\n        name\n      }\n      recruiterLatestRecruitmentTaskRecommendation(recruiterId: $recruiterId) {\n        id\n        createdAt\n        status\n      }\n      recruiterRecruitmentTask(recruiterId: $recruiterId) {\n        id\n        status\n      }\n      ...JobOfferStatusTag_JobOffer\n      ...JobOfferLinksPopover_JobOffer\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query employerJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      nodes {\n        id\n        status\n        creator {\n          id\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query employerJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      nodes {\n        id\n        status\n        creator {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query myRecruiterActivityInformation {\n    myUser {\n      __typename\n      ... on User {\n        recruiterActivitySectors {\n          id\n        }\n        recruiterDeprecatedJobCategories {\n          id\n        }\n        recruiterNewJobCategories {\n          id\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query myRecruiterActivityInformation {\n    myUser {\n      __typename\n      ... on User {\n        recruiterActivitySectors {\n          id\n        }\n        recruiterDeprecatedJobCategories {\n          id\n        }\n        recruiterNewJobCategories {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query apolloQueryMockFactory_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      id\n    }\n  }\n'
): (typeof documents)['\n  query apolloQueryMockFactory_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query companyLogoUploadUrlAndFileName(\n    $input: CompanyLogoUploadUrlAndFileNameInput!\n  ) {\n    companyLogoUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n'
): (typeof documents)['\n  query companyLogoUploadUrlAndFileName(\n    $input: CompanyLogoUploadUrlAndFileNameInput!\n  ) {\n    companyLogoUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateMyEmployerLogo($input: UpdateMyEmployerLogoInput!) {\n    updateMyEmployerLogo(input: $input) {\n      id\n      logoUrl\n    }\n  }\n'
): (typeof documents)['\n  mutation updateMyEmployerLogo($input: UpdateMyEmployerLogoInput!) {\n    updateMyEmployerLogo(input: $input) {\n      id\n      logoUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HiringManagerLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    employer {\n      id\n    }\n  }\n'
): (typeof documents)['\n  fragment HiringManagerLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    employer {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationWithStatuses on JobApplication {\n    id\n    statuses {\n      ...JobApplicationStatus\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationWithStatuses on JobApplication {\n    id\n    statuses {\n      ...JobApplicationStatus\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationLastUpdateAlert_JobApplication on JobApplication {\n    statuses {\n      id\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationLastUpdateAlert_JobApplication on JobApplication {\n    statuses {\n      id\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationProfileDrawer_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileDrawerHeader_JobApplication\n    ...JobApplicationProfileDrawerHistorySection_JobApplication\n    ...JobApplicationProfileDrawerFooter_JobApplication\n    profile {\n      id\n      ...ProfileDrawerCreatorSection_Profile\n      ...ProfileDrawerInfoSection_Profile\n      ...ProfileDrawerSkillsSection_Profile\n      ...ProfileDrawerEducationsSection_Profile\n      ...ProfileDrawerExperiencesSection_Profile\n    }\n    recruitmentTask {\n      id\n      ...ProfileDrawerCreatorSection_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationProfileDrawer_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileDrawerHeader_JobApplication\n    ...JobApplicationProfileDrawerHistorySection_JobApplication\n    ...JobApplicationProfileDrawerFooter_JobApplication\n    profile {\n      id\n      ...ProfileDrawerCreatorSection_Profile\n      ...ProfileDrawerInfoSection_Profile\n      ...ProfileDrawerSkillsSection_Profile\n      ...ProfileDrawerEducationsSection_Profile\n      ...ProfileDrawerExperiencesSection_Profile\n    }\n    recruitmentTask {\n      id\n      ...ProfileDrawerCreatorSection_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationProfileDrawerFooter_JobApplication on JobApplication {\n    id\n    ...HiringConfirmationModal_JobApplication\n    ...JobApplicationRejectionModal_JobApplication\n    ...StartApplicantRecruitmentProcessModal_JobApplication\n    statuses {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationProfileDrawerFooter_JobApplication on JobApplication {\n    id\n    ...HiringConfirmationModal_JobApplication\n    ...JobApplicationRejectionModal_JobApplication\n    ...StartApplicantRecruitmentProcessModal_JobApplication\n    statuses {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationProfileDrawerHeader_JobApplication on JobApplication {\n    id\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      curriculumVitaeUrl\n      linkedInProfileUrl\n      phoneNumber\n      email\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    statuses {\n      id\n      name\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationProfileDrawerHeader_JobApplication on JobApplication {\n    id\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      curriculumVitaeUrl\n      linkedInProfileUrl\n      phoneNumber\n      email\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    statuses {\n      id\n      name\n      ...GetJobApplicationLastUpdateAlert_JobApplicationStatus\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment JobApplicationProfileDrawerHistorySection_JobApplication on JobApplication {\n      statuses {\n        id\n        name\n        createdAt\n        message {\n          body\n          creator {\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  '
): (typeof documents)['\n    fragment JobApplicationProfileDrawerHistorySection_JobApplication on JobApplication {\n      statuses {\n        id\n        name\n        createdAt\n        message {\n          body\n          creator {\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query getJobApplicationProfileDrawerData($id: String!) {\n      jobApplication(id: $id) {\n        ...JobApplicationProfileDrawer_JobApplication\n      }\n    }\n  '
): (typeof documents)['\n    query getJobApplicationProfileDrawerData($id: String!) {\n      jobApplication(id: $id) {\n        ...JobApplicationProfileDrawer_JobApplication\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationSubmissionForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationSubmissionForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useJobApplicationSubmissionForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseJobApplicationSubmissionForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation useJobApplicationSubmissionForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseJobApplicationSubmissionForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment GetJobApplicationLastUpdateAlert_JobApplicationStatus on JobApplicationStatus {\n    id\n    name\n    createdAt\n  }\n'
): (typeof documents)['\n  fragment GetJobApplicationLastUpdateAlert_JobApplicationStatus on JobApplicationStatus {\n    id\n    name\n    createdAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SortByStatus_JobApplication on JobApplication {\n    statuses {\n      name\n      createdAt\n    }\n  }\n'
): (typeof documents)['\n  fragment SortByStatus_JobApplication on JobApplication {\n    statuses {\n      name\n      createdAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n    createdAt\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n    createdAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_Employer on Employer {\n    id\n    name\n    logoUrl\n    description\n    nonPoachingAgreementsDescription\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_Employer on Employer {\n    id\n    name\n    logoUrl\n    description\n    nonPoachingAgreementsDescription\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_User on User {\n    id\n    firstName\n    lastName\n    phoneNumber\n    email\n    myOneToOneChatChannelId\n    employer {\n      id\n      name\n    }\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_User on User {\n    id\n    firstName\n    lastName\n    phoneNumber\n    email\n    myOneToOneChatChannelId\n    employer {\n      id\n      name\n    }\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    myOneToOneChatChannelId\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    myOneToOneChatChannelId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_SuccessFee on SuccessFee {\n    id\n    basisPoints\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_SuccessFee on SuccessFee {\n    id\n    basisPoints\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_JobOffer on JobOffer {\n    id\n    title\n    isTerminated\n    description\n    recruitmentProcess\n    careerOutlook\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    perksDescription\n    briefingVideoUrl\n    videoAskUrl\n    location\n    contractType\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    mandatoryProfileAttributes\n    profileDescription\n    technicalSkillsDescription\n    typicalProfileUrl\n    recruitmentAdvice\n    remoteWorkPolicy\n    latestSuccessFee {\n      ...UseJobApplicationRecommendation_SuccessFee\n    }\n    creator {\n      ...UseJobApplicationRecommendation_User\n    }\n    # TODO: replace InternalManager by CustomerSuccessManager\n    internalManager {\n      ...UseJobApplicationRecommendation_InternalManager\n    }\n    employer {\n      ...UseJobApplicationRecommendation_Employer\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_JobOffer on JobOffer {\n    id\n    title\n    isTerminated\n    description\n    recruitmentProcess\n    careerOutlook\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    perksDescription\n    briefingVideoUrl\n    videoAskUrl\n    location\n    contractType\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    mandatoryProfileAttributes\n    profileDescription\n    technicalSkillsDescription\n    typicalProfileUrl\n    recruitmentAdvice\n    remoteWorkPolicy\n    latestSuccessFee {\n      ...UseJobApplicationRecommendation_SuccessFee\n    }\n    creator {\n      ...UseJobApplicationRecommendation_User\n    }\n    # TODO: replace InternalManager by CustomerSuccessManager\n    internalManager {\n      ...UseJobApplicationRecommendation_InternalManager\n    }\n    employer {\n      ...UseJobApplicationRecommendation_Employer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobApplicationRecommendation_JobApplicationRecommendation on JobApplicationRecommendation {\n    id\n    createdAt\n    hasApplied\n    score\n    status\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOfferMatchingCriteria {\n      name\n      isMatching\n    }\n    jobOffer {\n      ...UseJobApplicationRecommendation_JobOffer\n      ...JobOfferHeader_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n    profile {\n      ...UseJobApplicationRecommendation_Profile\n    }\n    recruitmentTask {\n      ...UseJobApplicationRecommendation_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobApplicationRecommendation_JobApplicationRecommendation on JobApplicationRecommendation {\n    id\n    createdAt\n    hasApplied\n    score\n    status\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOfferMatchingCriteria {\n      name\n      isMatching\n    }\n    jobOffer {\n      ...UseJobApplicationRecommendation_JobOffer\n      ...JobOfferHeader_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n    profile {\n      ...UseJobApplicationRecommendation_Profile\n    }\n    recruitmentTask {\n      ...UseJobApplicationRecommendation_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobApplicationRecommendation_jobApplicationRecommendationQuery(\n    $input: GetJobApplicationRecommendationInput!\n  ) {\n    jobApplicationRecommendation(input: $input) {\n      ...UseJobApplicationRecommendation_JobApplicationRecommendation\n    }\n  }\n'
): (typeof documents)['\n  query useJobApplicationRecommendation_jobApplicationRecommendationQuery(\n    $input: GetJobApplicationRecommendationInput!\n  ) {\n    jobApplicationRecommendation(input: $input) {\n      ...UseJobApplicationRecommendation_JobApplicationRecommendation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation useJobApplicationRecommendationAcceptationModal_takeRecruitmentTaskViaJobApplicationRecommendationMutation(\n      $input: TakeRecruitmentTaskViaJobApplicationRecommendationInput!\n    ) {\n      takeRecruitmentTaskViaJobApplicationRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  '
): (typeof documents)['\n    mutation useJobApplicationRecommendationAcceptationModal_takeRecruitmentTaskViaJobApplicationRecommendationMutation(\n      $input: TakeRecruitmentTaskViaJobApplicationRecommendationInput!\n    ) {\n      takeRecruitmentTaskViaJobApplicationRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerJobOfferJobApplicationEditableStatusTag_JobApplication on JobApplication {\n      id\n      statuses {\n        id\n        name\n        createdAt\n      }\n      ...StartApplicantRecruitmentProcessModal_JobApplication\n      ...JobApplicationRejectionModal_JobApplication\n      ...HiringConfirmationModal_JobApplication\n    }\n  '
): (typeof documents)['\n    fragment EmployerJobOfferJobApplicationEditableStatusTag_JobApplication on JobApplication {\n      id\n      statuses {\n        id\n        name\n        createdAt\n      }\n      ...StartApplicantRecruitmentProcessModal_JobApplication\n      ...JobApplicationRejectionModal_JobApplication\n      ...HiringConfirmationModal_JobApplication\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment AfterHiringJobOfferTerminationModal_JobApplication on JobApplication {\n      jobOffer {\n        id\n        ...UseAfterHiringJobOfferTerminationModal_JobOffer\n      }\n    }\n  '
): (typeof documents)['\n    fragment AfterHiringJobOfferTerminationModal_JobApplication on JobApplication {\n      jobOffer {\n        id\n        ...UseAfterHiringJobOfferTerminationModal_JobOffer\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseAfterHiringJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n  }\n'
): (typeof documents)['\n  fragment UseAfterHiringJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation terminateJobOffer($input: TerminateJobOfferInput!) {\n      terminateJobOffer(input: $input) {\n        id\n        isTerminated\n      }\n    }\n  '
): (typeof documents)['\n    mutation terminateJobOffer($input: TerminateJobOfferInput!) {\n      terminateJobOffer(input: $input) {\n        id\n        isTerminated\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment AskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...UseAskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  '
): (typeof documents)['\n    fragment AskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...UseAskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment UseAskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        id\n        firstName\n        lastName\n        creator {\n          id\n          firstName\n          lastName\n        }\n      }\n      jobOffer {\n        id\n        title\n        employer {\n          id\n          name\n        }\n      }\n    }\n  '
): (typeof documents)['\n    fragment UseAskForFirstInterviewWithTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        id\n        firstName\n        lastName\n        creator {\n          id\n          firstName\n          lastName\n        }\n      }\n      jobOffer {\n        id\n        title\n        employer {\n          id\n          name\n        }\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment AskForFirstInterviewWithoutTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        firstName\n        lastName\n      }\n    }\n  '
): (typeof documents)['\n    fragment AskForFirstInterviewWithoutTemplateModal_JobApplication on JobApplication {\n      id\n      profile {\n        firstName\n        lastName\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HiringConfirmationModal_JobApplication on JobApplication {\n    id\n    profile {\n      id\n      firstName\n      lastName\n      creator {\n        id\n        firstName\n        lastName\n      }\n    }\n    jobOffer {\n      id\n      title\n    }\n    ...AfterHiringJobOfferTerminationModal_JobApplication\n  }\n'
): (typeof documents)['\n  fragment HiringConfirmationModal_JobApplication on JobApplication {\n    id\n    profile {\n      id\n      firstName\n      lastName\n      creator {\n        id\n        firstName\n        lastName\n      }\n    }\n    jobOffer {\n      id\n      title\n    }\n    ...AfterHiringJobOfferTerminationModal_JobApplication\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation declareApplicantHiredByMyCompany(\n      $input: DeclareApplicantHiredByMyCompanyInput!\n    ) {\n      declareApplicantHiredByMyCompany(input: $input) {\n        # This mutation updates the jobApplication statuses\n        # We want it to return the jobApplication with its statuses to update the cache\n        ...JobApplicationWithStatuses\n      }\n    }\n  '
): (typeof documents)['\n    mutation declareApplicantHiredByMyCompany(\n      $input: DeclareApplicantHiredByMyCompanyInput!\n    ) {\n      declareApplicantHiredByMyCompany(input: $input) {\n        # This mutation updates the jobApplication statuses\n        # We want it to return the jobApplication with its statuses to update the cache\n        ...JobApplicationWithStatuses\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationRejectionModal_JobApplication on JobApplication {\n    id\n  }\n'
): (typeof documents)['\n  fragment JobApplicationRejectionModal_JobApplication on JobApplication {\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment StartApplicantRecruitmentProcessModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...AskForFirstInterviewWithoutTemplateModal_JobApplication\n      ...AskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  '
): (typeof documents)['\n    fragment StartApplicantRecruitmentProcessModal_JobApplication on JobApplication {\n      profile {\n        firstName\n        lastName\n      }\n      ...AskForFirstInterviewWithoutTemplateModal_JobApplication\n      ...AskForFirstInterviewWithTemplateModal_JobApplication\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationStatus on JobApplicationStatus {\n    id\n    createdAt\n    name\n    message {\n      ...JobApplicationStatusMessage\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationStatus on JobApplicationStatus {\n    id\n    createdAt\n    name\n    message {\n      ...JobApplicationStatusMessage\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationStatusMessage on Message {\n    id\n    body\n    createdAt\n    creator {\n      id\n      firstName\n      lastName\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationStatusMessage on Message {\n    id\n    body\n    createdAt\n    creator {\n      id\n      firstName\n      lastName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query jobCategories_useAutoCompleteJobCategoryOptions(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query jobCategories_useAutoCompleteJobCategoryOptions(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query jobCategories_useJobCategoryOptions($filters: JobCategoriesFilters) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query jobCategories_useJobCategoryOptions($filters: JobCategoriesFilters) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferBody_JobOffer on JobOffer {\n    id\n    description\n    profileDescription\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    typicalProfileUrl\n    careerOutlook\n    recruitmentProcess\n    perksDescription\n    recruitmentAdvice\n    employer {\n      id\n      description\n      nonPoachingAgreementsDescription\n    }\n    ...JobOfferBodyMotivationalSection_JobOffer\n    ...JobOfferBodySummarySection_JobOffer\n    ...JobOfferBodyVideoBriefSection_JobOffer\n    ...JobOfferBodyPerksSection_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferBody_JobOffer on JobOffer {\n    id\n    description\n    profileDescription\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    typicalProfileUrl\n    careerOutlook\n    recruitmentProcess\n    perksDescription\n    recruitmentAdvice\n    employer {\n      id\n      description\n      nonPoachingAgreementsDescription\n    }\n    ...JobOfferBodyMotivationalSection_JobOffer\n    ...JobOfferBodySummarySection_JobOffer\n    ...JobOfferBodyVideoBriefSection_JobOffer\n    ...JobOfferBodyPerksSection_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferBodyMotivationalSection_JobOffer on JobOffer {\n    creator {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    motivationalMessageToRecruiters\n  }\n'
): (typeof documents)['\n  fragment JobOfferBodyMotivationalSection_JobOffer on JobOffer {\n    creator {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n    motivationalMessageToRecruiters\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferBodyPerksSection_JobOffer on JobOffer {\n    id\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n  }\n'
): (typeof documents)['\n  fragment JobOfferBodyPerksSection_JobOffer on JobOffer {\n    id\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferBodySummarySection_JobOffer on JobOffer {\n    id\n    location\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment JobOfferBodySummarySection_JobOffer on JobOffer {\n    id\n    location\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n    numberOfPositionsToFill\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferBodyVideoBriefSection_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n'
): (typeof documents)['\n  fragment JobOfferBodyVideoBriefSection_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferClassificationFromTextForm_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferClassificationFromTextForm_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query UseJobOfferClassificationFromTextForm_employerJobOfferLead(\n      $jobOfferLeadId: String!\n    ) {\n      employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n        ...UseJobOfferClassificationFromTextForm_JobOfferLead\n      }\n    }\n  '
): (typeof documents)['\n    query UseJobOfferClassificationFromTextForm_employerJobOfferLead(\n      $jobOfferLeadId: String!\n    ) {\n      employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n        ...UseJobOfferClassificationFromTextForm_JobOfferLead\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation UseJobOfferClassificationFromTextForm_classifyAndCreateJobOffer(\n      $input: ClassifyAndCreateJobOfferInput!\n    ) {\n      classifyAndCreateJobOffer(input: $input) {\n        jobId\n      }\n    }\n  '
): (typeof documents)['\n    mutation UseJobOfferClassificationFromTextForm_classifyAndCreateJobOffer(\n      $input: ClassifyAndCreateJobOfferInput!\n    ) {\n      classifyAndCreateJobOffer(input: $input) {\n        jobId\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferContact_User on User {\n    id\n    phoneNumber\n    email\n    ...ChatContact_User\n  }\n'
): (typeof documents)['\n  fragment JobOfferContact_User on User {\n    id\n    phoneNumber\n    email\n    ...ChatContact_User\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferContact_InternalManager on InternalManager {\n    id\n    phoneNumber\n    email\n    ...ChatContact_InternalManager\n  }\n'
): (typeof documents)['\n  fragment JobOfferContact_InternalManager on InternalManager {\n    id\n    phoneNumber\n    email\n    ...ChatContact_InternalManager\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferContacts_JobOffer on JobOffer {\n    creator {\n      employer {\n        id\n        name\n      }\n      ...JobOfferContact_User\n      ...ActivityMetric_User\n    }\n    internalManager {\n      ...JobOfferContact_InternalManager\n    }\n  }\n'
): (typeof documents)['\n  fragment JobOfferContacts_JobOffer on JobOffer {\n    creator {\n      employer {\n        id\n        name\n      }\n      ...JobOfferContact_User\n      ...ActivityMetric_User\n    }\n    internalManager {\n      ...JobOfferContact_InternalManager\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferHeader_JobOffer on JobOffer {\n    title\n    contractType\n    employer {\n      id\n      name\n      logoUrl\n    }\n    isTerminated\n    ...JobOfferStatusTag_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferHeader_JobOffer on JobOffer {\n    title\n    contractType\n    employer {\n      id\n      name\n      logoUrl\n    }\n    isTerminated\n    ...JobOfferStatusTag_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment JobOfferMinimalFormAcademicBackgroundStep_JobOffer on JobOffer {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  '
): (typeof documents)['\n    fragment JobOfferMinimalFormAcademicBackgroundStep_JobOffer on JobOffer {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferMinimalFormLocationStep_JobOffer on JobOffer {\n    id\n    location\n    remoteWorkPolicy\n  }\n'
): (typeof documents)['\n  fragment JobOfferMinimalFormLocationStep_JobOffer on JobOffer {\n    id\n    location\n    remoteWorkPolicy\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferMinimalFormProfileStep_JobOffer on JobOffer {\n    id\n    jobCategory {\n      id\n      name\n    }\n    minNumberOfYearsOfExperience\n  }\n'
): (typeof documents)['\n  fragment JobOfferMinimalFormProfileStep_JobOffer on JobOffer {\n    id\n    jobCategory {\n      id\n      name\n    }\n    minNumberOfYearsOfExperience\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferMinimalFormSalaryStep_JobOffer on JobOffer {\n    id\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n  }\n'
): (typeof documents)['\n  fragment JobOfferMinimalFormSalaryStep_JobOffer on JobOffer {\n    id\n    minimumRemuneration\n    maximumRemuneration\n    maximumVariableRemuneration\n    currencyCode\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferMinimalFormSuccessFeeStep_JobOffer on JobOffer {\n    id\n    latestSuccessFee {\n      basisPoints\n    }\n  }\n'
): (typeof documents)['\n  fragment JobOfferMinimalFormSuccessFeeStep_JobOffer on JobOffer {\n    id\n    latestSuccessFee {\n      basisPoints\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VideoBriefExpertRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n'
): (typeof documents)['\n  fragment VideoBriefExpertRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VideoBriefSoloRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n'
): (typeof documents)['\n  fragment VideoBriefSoloRecordingClickableCard_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    videoBriefMeeting {\n      id\n      startDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UseJobOfferVideoBriefSoloRecordingDrawer_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer\n    }\n  }\n  fragment UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer on JobOffer {\n    id\n    videoAskUrl\n  }\n'
): (typeof documents)['\n  query UseJobOfferVideoBriefSoloRecordingDrawer_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer\n    }\n  }\n  fragment UseJobOfferVideoBriefSoloRecordingDrawer_JobOffer on JobOffer {\n    id\n    videoAskUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferMinimalFormVideoBriefStep_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n      startDate\n    }\n    videoAskUrl\n    ...VideoBriefSoloRecordingClickableCard_JobOffer\n    ...VideoBriefExpertRecordingClickableCard_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferMinimalFormVideoBriefStep_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n      startDate\n    }\n    videoAskUrl\n    ...VideoBriefSoloRecordingClickableCard_JobOffer\n    ...VideoBriefExpertRecordingClickableCard_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobOfferMinimalForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n  fragment UseJobOfferMinimalForm_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n    }\n    videoAskUrl\n    ...JobOfferMinimalFormProfileStep_JobOffer\n    ...JobOfferMinimalFormAcademicBackgroundStep_JobOffer\n    ...JobOfferMinimalFormLocationStep_JobOffer\n    ...JobOfferMinimalFormSalaryStep_JobOffer\n    ...JobOfferMinimalFormSuccessFeeStep_JobOffer\n    ...JobOfferMinimalFormVideoBriefStep_JobOffer\n  }\n'
): (typeof documents)['\n  query useJobOfferMinimalForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n  fragment UseJobOfferMinimalForm_JobOffer on JobOffer {\n    id\n    videoBriefMeeting {\n      id\n    }\n    videoAskUrl\n    ...JobOfferMinimalFormProfileStep_JobOffer\n    ...JobOfferMinimalFormAcademicBackgroundStep_JobOffer\n    ...JobOfferMinimalFormLocationStep_JobOffer\n    ...JobOfferMinimalFormSalaryStep_JobOffer\n    ...JobOfferMinimalFormSuccessFeeStep_JobOffer\n    ...JobOfferMinimalFormVideoBriefStep_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation jobOfferMinimalForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      id\n      # We need to use the same fragment as the one used in the hook so that the apollo cache updates automatically\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  mutation jobOfferMinimalForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      id\n      # We need to use the same fragment as the one used in the hook so that the apollo cache updates automatically\n      ...UseJobOfferMinimalForm_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation useJobOfferMinimalFormHandleStepSubmit_completeJobOfferCreationForm(\n      $input: CompleteJobOfferCreationFormInput!\n    ) {\n      completeJobOfferCreationForm(input: $input) {\n        id\n      }\n    }\n  '
): (typeof documents)['\n    mutation useJobOfferMinimalFormHandleStepSubmit_completeJobOfferCreationForm(\n      $input: CompleteJobOfferCreationFormInput!\n    ) {\n      completeJobOfferCreationForm(input: $input) {\n        id\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation useJobOfferMinimalFormHandleStepSubmit_expressInterestInProfiles(\n      $input: ExpressInterestInProfilesInput!\n    ) {\n      expressInterestInProfiles(input: $input) {\n        id\n      }\n    }\n  '
): (typeof documents)['\n    mutation useJobOfferMinimalFormHandleStepSubmit_expressInterestInProfiles(\n      $input: ExpressInterestInProfilesInput!\n    ) {\n      expressInterestInProfiles(input: $input) {\n        id\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferPackageTableCell_JobOffer on JobOffer {\n    minimumRemuneration\n    maximumRemuneration\n    currencyCode\n    maximumVariableRemuneration\n  }\n'
): (typeof documents)['\n  fragment JobOfferPackageTableCell_JobOffer on JobOffer {\n    minimumRemuneration\n    maximumRemuneration\n    currencyCode\n    maximumVariableRemuneration\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferStatusTag_JobOffer on JobOffer {\n    status\n    isTerminated\n  }\n'
): (typeof documents)['\n  fragment JobOfferStatusTag_JobOffer on JobOffer {\n    status\n    isTerminated\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useJobOfferUpdateForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  query useJobOfferUpdateForm_jobOffer($id: String!) {\n    jobOffer(id: $id) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useJobOfferUpdateForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  mutation useJobOfferUpdateForm_updateJobOffer($input: UpdateJobOfferInput!) {\n    updateJobOffer(input: $input) {\n      ...UseJobOfferUpdateForm_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferUpdateForm_JobOffer on JobOffer {\n    title\n    description\n    profileDescription\n    recruitmentProcess\n    numberOfPositionsToFill\n    location\n    contractType\n    currencyCode\n    minimumRemuneration\n    maximumRemuneration\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    maximumVariableRemuneration\n    careerOutlook\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    perksDescription\n    typicalProfileUrl\n    newJobCategory {\n      id\n      name\n    }\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    motivationalMessageToRecruiters\n    ...JobOfferWizardForm_JobOffer\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferUpdateForm_JobOffer on JobOffer {\n    title\n    description\n    profileDescription\n    recruitmentProcess\n    numberOfPositionsToFill\n    location\n    contractType\n    currencyCode\n    minimumRemuneration\n    maximumRemuneration\n    minNumberOfYearsOfExperience\n    maxNumberOfYearsOfExperience\n    maximumVariableRemuneration\n    careerOutlook\n    mandatoryProfileAttributes\n    technicalSkillsDescription\n    perksDescription\n    typicalProfileUrl\n    newJobCategory {\n      id\n      name\n    }\n    hasIncentiveBonus\n    hasLuncheonVouchers\n    hasHealthInsurancePlan\n    remoteWorkPolicy\n    academicBackgrounds {\n      id\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    motivationalMessageToRecruiters\n    ...JobOfferWizardForm_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferWizardForm_JobOffer on JobOffer {\n    ...JobOfferWizardFormSalaryStep_JobOffer\n  }\n'
): (typeof documents)['\n  fragment JobOfferWizardForm_JobOffer on JobOffer {\n    ...JobOfferWizardFormSalaryStep_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobOfferWizardFormSalaryStep_JobOffer on JobOffer {\n    status\n  }\n'
): (typeof documents)['\n  fragment JobOfferWizardFormSalaryStep_JobOffer on JobOffer {\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useJobOfferUploadVideoBrief_updateJobOffer(\n    $input: UpdateJobOfferInput!\n  ) {\n    updateJobOffer(input: $input) {\n      ...EmployerJobOfferPreview_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  mutation useJobOfferUploadVideoBrief_updateJobOffer(\n    $input: UpdateJobOfferInput!\n  ) {\n    updateJobOffer(input: $input) {\n      ...EmployerJobOfferPreview_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CheckIfJobOfferBriefIsRecorded_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n'
): (typeof documents)['\n  fragment CheckIfJobOfferBriefIsRecorded_JobOffer on JobOffer {\n    id\n    briefingVideoUrl\n    videoAskUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query myUser {\n    myUser {\n      __typename\n      ... on User {\n        ...RecruiterLayout_User\n        ...HiringManagerLayout_User\n        firstName\n        lastName\n        email\n        phoneNumber\n        profilePictureUrl\n        mainBusinessType\n        termsOfServiceVersion\n        permissions {\n          name\n        }\n      }\n      ... on UserLead {\n        ...MyUserProvider_UserLead\n      }\n    }\n  }\n  fragment MyUserProvider_UserLead on UserLead {\n    id\n    email\n    mainBusinessType\n  }\n'
): (typeof documents)['\n  query myUser {\n    myUser {\n      __typename\n      ... on User {\n        ...RecruiterLayout_User\n        ...HiringManagerLayout_User\n        firstName\n        lastName\n        email\n        phoneNumber\n        profilePictureUrl\n        mainBusinessType\n        termsOfServiceVersion\n        permissions {\n          name\n        }\n      }\n      ... on UserLead {\n        ...MyUserProvider_UserLead\n      }\n    }\n  }\n  fragment MyUserProvider_UserLead on UserLead {\n    id\n    email\n    mainBusinessType\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerCreatorSection_Profile on Profile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerCreatorSection_Profile on Profile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerCreatorSection_AnonymizedProfile on AnonymizedProfile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerCreatorSection_AnonymizedProfile on AnonymizedProfile {\n    creator {\n      id\n      createdAt\n      phoneNumber\n      email\n      roles {\n        name\n      }\n      ...ChatContact_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerCreatorSection_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerCreatorSection_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerEducationsSection_Profile on Profile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerEducationsSection_Profile on Profile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerEducationsSection_AnonymizedProfile on AnonymizedProfile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerEducationsSection_AnonymizedProfile on AnonymizedProfile {\n    educations {\n      id\n      degreeTitle\n      school {\n        id\n        name\n      }\n      startedAt\n      endedAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerExperiencesSection_Profile on Profile {\n    experiences {\n      ...ProfileExperiences_Experience\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerExperiencesSection_Profile on Profile {\n    experiences {\n      ...ProfileExperiences_Experience\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerExperiencesSection_AnonymizedProfile on AnonymizedProfile {\n    experiences {\n      ...ProfileExperiences_AnonymizedExperience\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerExperiencesSection_AnonymizedProfile on AnonymizedProfile {\n    experiences {\n      ...ProfileExperiences_AnonymizedExperience\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerInfoSection_Profile on Profile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerInfoSection_Profile on Profile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerInfoSection_AnonymizedProfile on AnonymizedProfile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerInfoSection_AnonymizedProfile on AnonymizedProfile {\n    salaryClaimAmount\n    salaryClaimCurrencyCode\n    approximateFirstJobStartDate\n    preferredRemoteWorkPolicy\n    urbanArea {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerSkillsSection_Profile on Profile {\n    validSkills {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerSkillsSection_Profile on Profile {\n    validSkills {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileDrawerSkillsSection_AnonymizedProfile on AnonymizedProfile {\n    validSkills {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileDrawerSkillsSection_AnonymizedProfile on AnonymizedProfile {\n    validSkills {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileHeader_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n  }\n'
): (typeof documents)['\n  fragment ProfileHeader_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    profilePictureUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileEducation_Education on Education {\n    id\n    degreeTitle\n    school {\n      id\n      name\n    }\n    startedAt\n    endedAt\n  }\n'
): (typeof documents)['\n  fragment ProfileEducation_Education on Education {\n    id\n    degreeTitle\n    school {\n      id\n      name\n    }\n    startedAt\n    endedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileEducationsForm_Profile on Profile {\n    id\n    educations {\n      ...ProfileEducation_Education\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileEducationsForm_Profile on Profile {\n    id\n    educations {\n      ...ProfileEducation_Education\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperience_Experience on Experience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileExperience_Experience on Experience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperience_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileExperience_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperiences_Experience on Experience {\n    ...ProfileExperience_Experience\n  }\n'
): (typeof documents)['\n  fragment ProfileExperiences_Experience on Experience {\n    ...ProfileExperience_Experience\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperiences_AnonymizedExperience on AnonymizedExperience {\n    ...ProfileExperience_AnonymizedExperience\n  }\n'
): (typeof documents)['\n  fragment ProfileExperiences_AnonymizedExperience on AnonymizedExperience {\n    ...ProfileExperience_AnonymizedExperience\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperiencesForm_Experience on Experience {\n    id\n    jobTitle\n    employer {\n      name\n    }\n    startedAt\n    endedAt\n  }\n'
): (typeof documents)['\n  fragment ProfileExperiencesForm_Experience on Experience {\n    id\n    jobTitle\n    employer {\n      name\n    }\n    startedAt\n    endedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileExperiencesForm_Profile on Profile {\n    id\n    experiences {\n      ...ProfileExperiencesForm_Experience\n    }\n    linkedInProfileUrl\n  }\n'
): (typeof documents)['\n  fragment ProfileExperiencesForm_Profile on Profile {\n    id\n    experiences {\n      ...ProfileExperiencesForm_Experience\n    }\n    linkedInProfileUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileInfo_Profile on Profile {\n    ...ProfileEducationsForm_Profile\n    ...ProfileExperiencesForm_Profile\n    ...ProfileHeader_Profile\n    ...ProfileRequirementsChecklist_Profile\n    ...UpdateProfileCareerAutoSubmitForm_Profile\n    ...UseProfileAcademicBackgroundsAutoSubmitForm_Profile\n    ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    ...UseProfileSkillsAutoSubmitForm_Profile\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    myNote {\n      id\n      createdAt\n      updatedAt\n      body\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileInfo_Profile on Profile {\n    ...ProfileEducationsForm_Profile\n    ...ProfileExperiencesForm_Profile\n    ...ProfileHeader_Profile\n    ...ProfileRequirementsChecklist_Profile\n    ...UpdateProfileCareerAutoSubmitForm_Profile\n    ...UseProfileAcademicBackgroundsAutoSubmitForm_Profile\n    ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    ...UseProfileSkillsAutoSubmitForm_Profile\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    myNote {\n      id\n      createdAt\n      updatedAt\n      body\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UpdateProfileCareerAutoSubmitForm_Profile on Profile {\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    # Below are the fields that are used in this component\n    lastName\n    firstName\n    curriculumVitaeUrl\n    openToWorkUntil\n  }\n'
): (typeof documents)['\n  fragment UpdateProfileCareerAutoSubmitForm_Profile on Profile {\n    ...UseUpdateProfileAutoSubmitForm_Profile\n    ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    # Below are the fields that are used in this component\n    lastName\n    firstName\n    curriculumVitaeUrl\n    openToWorkUntil\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment UseProfileAcademicBackgroundsAutoSubmitForm_Profile on Profile {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  '
): (typeof documents)['\n    fragment UseProfileAcademicBackgroundsAutoSubmitForm_Profile on Profile {\n      id\n      academicBackgrounds {\n        id\n        name\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseProfileIsOpenToWorkAutoSubmitForm_Profile on Profile {\n    id\n    openToWorkUntil\n  }\n'
): (typeof documents)['\n  fragment UseProfileIsOpenToWorkAutoSubmitForm_Profile on Profile {\n    id\n    openToWorkUntil\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation markProfileAsOpenToWork($input: MarkProfileAsOpenToWorkInput!) {\n    markProfileAsOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation markProfileAsOpenToWork($input: MarkProfileAsOpenToWorkInput!) {\n    markProfileAsOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation markProfileAsNotOpenToWork(\n    $input: MarkProfileAsNotOpenToWorkInput!\n  ) {\n    markProfileAsNotOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation markProfileAsNotOpenToWork(\n    $input: MarkProfileAsNotOpenToWorkInput!\n  ) {\n    markProfileAsNotOpenToWork(input: $input) {\n      ...UseProfileIsOpenToWorkAutoSubmitForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseProfileJobCategoriesAutoSubmitForm_Profile on Profile {\n    id\n    newJobCategories {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment UseProfileJobCategoriesAutoSubmitForm_Profile on Profile {\n    id\n    newJobCategories {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateProfileJobCategories(\n    $input: UpdateProfileJobCategoriesInput!\n  ) {\n    updateProfileJobCategories(input: $input) {\n      ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation updateProfileJobCategories(\n    $input: UpdateProfileJobCategoriesInput!\n  ) {\n    updateProfileJobCategories(input: $input) {\n      ...UseProfileJobCategoriesAutoSubmitForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseProfileSkillsAutoSubmitForm_Profile on Profile {\n    id\n    skills {\n      id\n      name\n      status\n    }\n  }\n'
): (typeof documents)['\n  fragment UseProfileSkillsAutoSubmitForm_Profile on Profile {\n    id\n    skills {\n      id\n      name\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useProfileSkillsAutoSubmitForm_addProfilePotentialSkillMutation(\n    $input: AddProfilePotentialSkillInput!\n  ) {\n    addProfilePotentialSkill(input: $input) {\n      ...UseProfileSkillsAutoSubmitForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation useProfileSkillsAutoSubmitForm_addProfilePotentialSkillMutation(\n    $input: AddProfilePotentialSkillInput!\n  ) {\n    addProfilePotentialSkill(input: $input) {\n      ...UseProfileSkillsAutoSubmitForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseUpdateProfileAutoSubmitForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment UseUpdateProfileAutoSubmitForm_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    linkedInProfileUrl\n    curriculumVitaeUrl\n    approximateFirstJobStartDate\n    salaryClaimAmount\n    preferredRemoteWorkPolicy\n    openToWorkUntil\n    urbanArea {\n      id\n      name\n    }\n    currentEmployer {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useUpdateProfileAutoSubmitForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseUpdateProfileAutoSubmitForm_Profile\n    }\n  }\n'
): (typeof documents)['\n  mutation useUpdateProfileAutoSubmitForm_updateProfileMutation(\n    $input: UpdateProfileInput!\n  ) {\n    updateProfile(input: $input) {\n      ...UseUpdateProfileAutoSubmitForm_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProfileRequirementsChecklist_Profile on Profile {\n    id\n    email\n    approximateFirstJobStartDate\n    newJobCategories {\n      id\n    }\n    academicBackgrounds {\n      id\n    }\n    skills {\n      id\n    }\n    urbanArea {\n      id\n    }\n    experiences {\n      id\n    }\n  }\n'
): (typeof documents)['\n  fragment ProfileRequirementsChecklist_Profile on Profile {\n    id\n    email\n    approximateFirstJobStartDate\n    newJobCategories {\n      id\n    }\n    academicBackgrounds {\n      id\n    }\n    skills {\n      id\n    }\n    urbanArea {\n      id\n    }\n    experiences {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useProfile_profileQuery($input: GetProfileInput!) {\n    profile(input: $input) {\n      ...ProfileInfo_Profile\n    }\n  }\n'
): (typeof documents)['\n  query useProfile_profileQuery($input: GetProfileInput!) {\n    profile(input: $input) {\n      ...ProfileInfo_Profile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query useRecruiterProfilesSearch_recruiterJobApplicationsQuery(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: JobApplicationsFilters\n    ) {\n      recruiterJobApplications(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        nodes {\n          profile {\n            id\n          }\n        }\n      }\n    }\n  '
): (typeof documents)['\n    query useRecruiterProfilesSearch_recruiterJobApplicationsQuery(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: JobApplicationsFilters\n    ) {\n      recruiterJobApplications(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        nodes {\n          profile {\n            id\n          }\n        }\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query recruiterProfiles($filters: RecruiterProfilesFilters) {\n    recruiterProfiles(filters: $filters) {\n      id\n      firstName\n      lastName\n      email\n      phoneNumber\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n      createdAt\n    }\n  }\n'
): (typeof documents)['\n  query recruiterProfiles($filters: RecruiterProfilesFilters) {\n    recruiterProfiles(filters: $filters) {\n      id\n      firstName\n      lastName\n      email\n      phoneNumber\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n      createdAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query recruiterLayout_recruiterRecruitmentTaskRecommendationsCount(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n    }\n  '
): (typeof documents)['\n    query recruiterLayout_recruiterRecruitmentTaskRecommendationsCount(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    roles {\n      name\n    }\n    searchFirm {\n      id\n      contracts {\n        id\n        statuses {\n          id\n          name\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterLayout_User on User {\n    id\n    isAuthorizedToUseMarketplace\n    roles {\n      name\n    }\n    searchFirm {\n      id\n      contracts {\n        id\n        statuses {\n          id\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterSettingsGeneralTab_User on User {\n    id\n    searchFirm {\n      id\n      name\n      description\n    }\n    firstName\n    lastName\n    phoneNumber\n    email\n    isAuthorizedToUseMarketplace\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterSettingsGeneralTab_User on User {\n    id\n    searchFirm {\n      id\n      name\n      description\n    }\n    firstName\n    lastName\n    phoneNumber\n    email\n    isAuthorizedToUseMarketplace\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterSettingsSpecializationsTab_User on User {\n    recruiterNewJobCategories {\n      id\n      name\n    }\n    recruiterActivitySectors {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterSettingsSpecializationsTab_User on User {\n    recruiterNewJobCategories {\n      id\n      name\n    }\n    recruiterActivitySectors {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query jobCategories_useRecruiterSettingsSpecializationsTab(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query jobCategories_useRecruiterSettingsSpecializationsTab(\n    $filters: JobCategoriesFilters\n  ) {\n    jobCategories(filters: $filters) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateRecruiterNewJobCategoryAssociations(\n    $recruiterJobCategoryAssociationInputs: [RecruiterJobCategoryAssociationInput!]!\n  ) {\n    updateRecruiterNewJobCategoryAssociations(\n      recruiterJobCategoryAssociationInputs: $recruiterJobCategoryAssociationInputs\n    )\n  }\n'
): (typeof documents)['\n  mutation updateRecruiterNewJobCategoryAssociations(\n    $recruiterJobCategoryAssociationInputs: [RecruiterJobCategoryAssociationInput!]!\n  ) {\n    updateRecruiterNewJobCategoryAssociations(\n      recruiterJobCategoryAssociationInputs: $recruiterJobCategoryAssociationInputs\n    )\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterSuperRecruiter_RecruiterActivityMetrics on RecruiterActivityMetrics {\n    jobApplicationMetrics(input: $jobApplicationMetricsInput) {\n      numberOfJobApplications\n      interviewConversionRateInBasisPoints\n      hireConversionRateInBasisPoints\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterSuperRecruiter_RecruiterActivityMetrics on RecruiterActivityMetrics {\n    jobApplicationMetrics(input: $jobApplicationMetricsInput) {\n      numberOfJobApplications\n      interviewConversionRateInBasisPoints\n      hireConversionRateInBasisPoints\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterSuperRecruiter_UserActivityMetric on UserActivityMetric {\n    recruiterActivityMetrics {\n      ...RecruiterSuperRecruiter_RecruiterActivityMetrics\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterSuperRecruiter_UserActivityMetric on UserActivityMetric {\n    recruiterActivityMetrics {\n      ...RecruiterSuperRecruiter_RecruiterActivityMetrics\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterSuperRecruiter_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n      ...RecruiterSuperRecruiter_UserActivityMetric\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterSuperRecruiter_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n      ...RecruiterSuperRecruiter_UserActivityMetric\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query recruiterSuperRecruiter_myUserQuery(\n    $jobApplicationMetricsInput: RecruiterJobApplicationMetricsInput!\n  ) {\n    myUser {\n      __typename\n      ...RecruiterSuperRecruiter_User\n    }\n  }\n'
): (typeof documents)['\n  query recruiterSuperRecruiter_myUserQuery(\n    $jobApplicationMetricsInput: RecruiterJobApplicationMetricsInput!\n  ) {\n    myUser {\n      __typename\n      ...RecruiterSuperRecruiter_User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskWithApplicationsCard_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask\n    jobApplications {\n      ...JobApplicationProfileDrawer_JobApplication\n      ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n      ...JobApplicationLastUpdateAlert_JobApplication\n      profile {\n        firstName\n        lastName\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskWithApplicationsCard_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask\n    jobApplications {\n      ...JobApplicationProfileDrawer_JobApplication\n      ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n      ...JobApplicationLastUpdateAlert_JobApplication\n      profile {\n        firstName\n        lastName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      jobOffer {\n        id\n        title\n      }\n    }\n  '
): (typeof documents)['\n    fragment RecruitmentTaskWithApplicationsCardHeader_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      jobOffer {\n        id\n        title\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskWithApplicationsCard_RecruitmentTask\n    }\n  '
): (typeof documents)['\n    fragment RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskWithApplicationsCard_RecruitmentTask\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query recruitmentTasksOfRecruiterForMyEmployer(\n    $input: RecruitmentTasksOfRecruiterForMyEmployerInput!\n  ) {\n    recruitmentTasksOfRecruiterForMyEmployer(input: $input) {\n      ...RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  query recruitmentTasksOfRecruiterForMyEmployer(\n    $input: RecruitmentTasksOfRecruiterForMyEmployerInput!\n  ) {\n    recruitmentTasksOfRecruiterForMyEmployer(input: $input) {\n      ...RecruitmentTasksOfRecruiterForMyEmployer_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query pendingRecruiterRecruitmentTaskRecommendationsCount(\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n  }\n'
): (typeof documents)['\n  query pendingRecruiterRecruitmentTaskRecommendationsCount(\n    $filters: RecruitmentTaskRecommendationsFilters!\n  ) {\n    recruiterRecruitmentTaskRecommendationsCount(filters: $filters)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation rejectRecruitmentTaskRecommendation(\n      $input: RejectRecruitementTaskRecommendationInput!\n    ) {\n      rejectRecruitmentTaskRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  '
): (typeof documents)['\n    mutation rejectRecruitmentTaskRecommendation(\n      $input: RejectRecruitementTaskRecommendationInput!\n    ) {\n      rejectRecruitmentTaskRecommendation(input: $input) {\n        id\n        status\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseSchoolsOptions_School on School {\n    id\n    name\n  }\n'
): (typeof documents)['\n  fragment UseSchoolsOptions_School on School {\n    id\n    name\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useSchoolsOptions_schools($input: GetSchoolsInput!) {\n    schools(input: $input) {\n      ...UseSchoolsOptions_School\n    }\n  }\n'
): (typeof documents)['\n  query useSchoolsOptions_schools($input: GetSchoolsInput!) {\n    schools(input: $input) {\n      ...UseSchoolsOptions_School\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n    agreeToTermsOfService(input: $input) {\n      id\n      termsOfServiceVersion\n    }\n  }\n'
): (typeof documents)['\n  mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n    agreeToTermsOfService(input: $input) {\n      id\n      termsOfServiceVersion\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ActivityMetric_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n'
): (typeof documents)['\n  fragment ActivityMetric_User on User {\n    id\n    activityMetric {\n      jobApplicationsResponseTime\n      jobApplicationsResponseRate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query profilePictureUploadUrlAndFileName(\n    $input: ProfilePictureUploadUrlAndFileNameInput!\n  ) {\n    profilePictureUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n'
): (typeof documents)['\n  query profilePictureUploadUrlAndFileName(\n    $input: ProfilePictureUploadUrlAndFileNameInput!\n  ) {\n    profilePictureUploadUrlAndFileName(input: $input) {\n      uploadUrl\n      awsS3Key\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateMyProfilePicture($input: UpdateMyProfilePictureInput!) {\n    updateMyProfilePicture(input: $input) {\n      id\n      profilePictureUrl\n    }\n  }\n'
): (typeof documents)['\n  mutation updateMyProfilePicture($input: UpdateMyProfilePictureInput!) {\n    updateMyProfilePicture(input: $input) {\n      id\n      profilePictureUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ChatContact_User on User {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n'
): (typeof documents)['\n  fragment ChatContact_User on User {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ChatContact_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n'
): (typeof documents)['\n  fragment ChatContact_InternalManager on InternalManager {\n    id\n    firstName\n    lastName\n    profilePictureUrl\n    myOneToOneChatChannelId\n    __typename\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createMyEmployer($input: CreateMyEmployerInput!) {\n    createMyEmployer(input: $input) {\n      id\n      employer {\n        id\n        name\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation createMyEmployer($input: CreateMyEmployerInput!) {\n    createMyEmployer(input: $input) {\n      id\n      employer {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseRecruiterProfilesTableColumns_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    curriculumVitaeUrl\n    linkedInProfileUrl\n    profilePictureUrl\n    createdAt\n    openToWorkUntil\n    lastJobApplicationRecommendationsComputation {\n      id\n      startedAt\n      status\n    }\n  }\n'
): (typeof documents)['\n  fragment UseRecruiterProfilesTableColumns_Profile on Profile {\n    id\n    firstName\n    lastName\n    email\n    phoneNumber\n    curriculumVitaeUrl\n    linkedInProfileUrl\n    profilePictureUrl\n    createdAt\n    openToWorkUntil\n    lastJobApplicationRecommendationsComputation {\n      id\n      startedAt\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useRecruiterProfilesPage_paginatedRecruiterProfilesQuery(\n    $filters: RecruiterProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedRecruiterProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseRecruiterProfilesTableColumns_Profile\n      }\n    }\n  }\n'
): (typeof documents)['\n  query useRecruiterProfilesPage_paginatedRecruiterProfilesQuery(\n    $filters: RecruiterProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedRecruiterProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      hasNextPage\n      totalCount\n      nodes {\n        ...UseRecruiterProfilesTableColumns_Profile\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query recruitmentTaskRecommendation($id: String!) {\n      recruitmentTaskRecommendation(id: $id) {\n        ...RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation\n      }\n    }\n  '
): (typeof documents)['\n    query recruitmentTaskRecommendation($id: String!) {\n      recruitmentTaskRecommendation(id: $id) {\n        ...RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      recruiterFlatFeeInBasisPoints\n      recruiterSuccessFeeInBasisPoints\n      jobOffer {\n        id\n        ...JobOfferHeader_JobOffer\n        ...JobOfferBody_JobOffer\n        ...JobOfferContacts_JobOffer\n        latestSuccessFee {\n          id\n          basisPoints\n        }\n        isTerminated\n      }\n      ...RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation\n    }\n  '
): (typeof documents)['\n    fragment RecruitmentTaskRecommendationDetails_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      recruiterFlatFeeInBasisPoints\n      recruiterSuccessFeeInBasisPoints\n      jobOffer {\n        id\n        ...JobOfferHeader_JobOffer\n        ...JobOfferBody_JobOffer\n        ...JobOfferContacts_JobOffer\n        latestSuccessFee {\n          id\n          basisPoints\n        }\n        isTerminated\n      }\n      ...RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      expirationDate\n      jobOffer {\n        isTerminated\n      }\n    }\n  '
): (typeof documents)['\n    fragment RecruitmentTaskRecommendationHeaderBanner_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      status\n      expirationDate\n      jobOffer {\n        isTerminated\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      expirationDate\n      recruiterSuccessFeeInBasisPoints\n      recruiterFlatFeeInBasisPoints\n      jobOffer {\n        title\n        priorityIndicator\n        employer {\n          name\n        }\n        location\n        latestSuccessFee {\n          basisPoints\n        }\n        ...JobOfferPackageTableCell_JobOffer\n      }\n    }\n  '
): (typeof documents)['\n    fragment RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation on RecruitmentTaskRecommendation {\n      id\n      expirationDate\n      recruiterSuccessFeeInBasisPoints\n      recruiterFlatFeeInBasisPoints\n      jobOffer {\n        title\n        priorityIndicator\n        employer {\n          name\n        }\n        location\n        latestSuccessFee {\n          basisPoints\n        }\n        ...JobOfferPackageTableCell_JobOffer\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendations(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendations(filters: $filters) {\n        ...RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n      }\n    }\n  '
): (typeof documents)['\n    query RecruiterRecruitmentTasksRecommendationsTable_RecruitmentTaskRecommendations(\n      $filters: RecruitmentTaskRecommendationsFilters!\n    ) {\n      recruiterRecruitmentTaskRecommendations(filters: $filters) {\n        ...RecruiterRecruitmentTaskRecommendationsTable_RecruitmentTaskRecommendation\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TermsOfServiceAgreementBox_termsOfService(\n    $input: TermsOfServiceInput!\n  ) {\n    termsOfService(input: $input) {\n      id\n      content\n    }\n  }\n'
): (typeof documents)['\n  query TermsOfServiceAgreementBox_termsOfService(\n    $input: TermsOfServiceInput!\n  ) {\n    termsOfService(input: $input) {\n      id\n      content\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n      agreeToTermsOfService(input: $input) {\n        id\n        termsOfServiceVersion\n      }\n    }\n  '
): (typeof documents)['\n    mutation agreeToTermsOfService($input: AgreeToTermsOfServiceInput!) {\n      agreeToTermsOfService(input: $input) {\n        id\n        termsOfServiceVersion\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n                query deprecatedJobCategories_RecruiterRecruitmentTasksFilterModal(\n                  $filters: DeprecatedJobCategoriesFilters\n                ) {\n                  deprecatedJobCategories(filters: $filters) {\n                    id\n                    name\n                  }\n                }\n              '
): (typeof documents)['\n                query deprecatedJobCategories_RecruiterRecruitmentTasksFilterModal(\n                  $filters: DeprecatedJobCategoriesFilters\n                ) {\n                  deprecatedJobCategories(filters: $filters) {\n                    id\n                    name\n                  }\n                }\n              '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query recruiterRecruitmentTasksTable_recruiterRecruitmentTasks(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: RecruiterRecruitmentTasksFilters\n  ) {\n    recruiterRecruitmentTasks(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...RecruiterRecruitmentTasksTable_RecruitmentTask\n      }\n    }\n  }\n'
): (typeof documents)['\n  query recruiterRecruitmentTasksTable_recruiterRecruitmentTasks(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: RecruiterRecruitmentTasksFilters\n  ) {\n    recruiterRecruitmentTasks(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...RecruiterRecruitmentTasksTable_RecruitmentTask\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOffer {\n      id\n      title\n      location\n      priorityIndicator\n      isTerminated\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      creator {\n        ...ChatContact_User\n      }\n      employer {\n        name\n      }\n      ...JobOfferPackageTableCell_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n    id\n    recruiterSuccessFeeInBasisPoints\n    recruiterFlatFeeInBasisPoints\n    jobOffer {\n      id\n      title\n      location\n      priorityIndicator\n      isTerminated\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      creator {\n        ...ChatContact_User\n      }\n      employer {\n        name\n      }\n      ...JobOfferPackageTableCell_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ActivateMyJobOfferPage_employerJobOfferLead($jobOfferLeadId: String!) {\n    employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n      ...ActivateMyJobOfferPage_JobOfferLead\n    }\n  }\n  fragment ActivateMyJobOfferPage_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n'
): (typeof documents)['\n  query ActivateMyJobOfferPage_employerJobOfferLead($jobOfferLeadId: String!) {\n    employerJobOfferLead(jobOfferLeadId: $jobOfferLeadId) {\n      ...ActivateMyJobOfferPage_JobOfferLead\n    }\n  }\n  fragment ActivateMyJobOfferPage_JobOfferLead on JobOfferLead {\n    id\n    title\n    description\n    location\n    jobBoard\n    jobBoardUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCollectPaymentDetailsCheckoutSession {\n    createCollectPaymentDetailsCheckoutSession {\n      url\n    }\n  }\n'
): (typeof documents)['\n  mutation createCollectPaymentDetailsCheckoutSession {\n    createCollectPaymentDetailsCheckoutSession {\n      url\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SelectedProfileDrawer_AnonymizedProfile on AnonymizedProfile {\n    ...SelectedProfileDrawerHeader_AnonymizedProfile\n    ...ProfileDrawerCreatorSection_AnonymizedProfile\n    ...ProfileDrawerInfoSection_AnonymizedProfile\n    ...ProfileDrawerSkillsSection_AnonymizedProfile\n    ...ProfileDrawerExperiencesSection_AnonymizedProfile\n    ...ProfileDrawerEducationsSection_AnonymizedProfile\n  }\n'
): (typeof documents)['\n  fragment SelectedProfileDrawer_AnonymizedProfile on AnonymizedProfile {\n    ...SelectedProfileDrawerHeader_AnonymizedProfile\n    ...ProfileDrawerCreatorSection_AnonymizedProfile\n    ...ProfileDrawerInfoSection_AnonymizedProfile\n    ...ProfileDrawerSkillsSection_AnonymizedProfile\n    ...ProfileDrawerExperiencesSection_AnonymizedProfile\n    ...ProfileDrawerEducationsSection_AnonymizedProfile\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SelectedProfileDrawerHeader_AnonymizedProfile on AnonymizedProfile {\n    id\n    profilePictureUrl\n  }\n'
): (typeof documents)['\n  fragment SelectedProfileDrawerHeader_AnonymizedProfile on AnonymizedProfile {\n    id\n    profilePictureUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferDashboard_JobOffer on JobOffer {\n    ...EmployerJobOfferHeader_JobOffer\n    ...EmployerJobOfferTimeline_JobOffer\n    ...EmployerJobOfferTabs_JobOffer\n    ...EmployerJobOfferPageProvider_JobOffer\n    status\n    internalManager {\n      ...JobOfferContact_InternalManager\n      phoneNumber\n      email\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferDashboard_JobOffer on JobOffer {\n    ...EmployerJobOfferHeader_JobOffer\n    ...EmployerJobOfferTimeline_JobOffer\n    ...EmployerJobOfferTabs_JobOffer\n    ...EmployerJobOfferPageProvider_JobOffer\n    status\n    internalManager {\n      ...JobOfferContact_InternalManager\n      phoneNumber\n      email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query employerJobOfferDashboard_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      ...EmployerJobOfferDashboard_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  query employerJobOfferDashboard_jobOfferQuery($jobOfferId: String!) {\n    jobOffer(id: $jobOfferId) {\n      ...EmployerJobOfferDashboard_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferHeader_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfOngoingRecruitmentTasks\n    briefingVideoUrl\n    flatFee\n    currencyCode\n    employer {\n      id\n      name\n      logoUrl\n    }\n    creator {\n      id\n      firstName\n      lastName\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    ...EmployerJobOfferManagementPopover_JobOffer\n    ...JobOfferStatusTag_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferHeader_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfOngoingRecruitmentTasks\n    briefingVideoUrl\n    flatFee\n    currencyCode\n    employer {\n      id\n      name\n      logoUrl\n    }\n    creator {\n      id\n      firstName\n      lastName\n    }\n    latestSuccessFee {\n      id\n      basisPoints\n    }\n    ...EmployerJobOfferManagementPopover_JobOffer\n    ...JobOfferStatusTag_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseBookVideoBriefMeeting_VideoBriefMeeting on VideoBriefMeeting {\n    id\n    startDate\n    endDate\n    updatedAt\n    jobOffer {\n      id\n      videoBriefMeeting {\n        id\n        startDate\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment UseBookVideoBriefMeeting_VideoBriefMeeting on VideoBriefMeeting {\n    id\n    startDate\n    endDate\n    updatedAt\n    jobOffer {\n      id\n      videoBriefMeeting {\n        id\n        startDate\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation useBookVideoBriefMeeting_bookVideoBriefMeetingMutation(\n    $input: BookVideoBriefMeetingInput!\n  ) {\n    bookVideoBriefMeeting(input: $input) {\n      ...UseBookVideoBriefMeeting_VideoBriefMeeting\n    }\n  }\n'
): (typeof documents)['\n  mutation useBookVideoBriefMeeting_bookVideoBriefMeetingMutation(\n    $input: BookVideoBriefMeetingInput!\n  ) {\n    bookVideoBriefMeeting(input: $input) {\n      ...UseBookVideoBriefMeeting_VideoBriefMeeting\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferManagementPopover_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    briefingVideoUrl\n    ...EmployerJobOfferTerminationModal_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferManagementPopover_JobOffer on JobOffer {\n    id\n    videoAskUrl\n    briefingVideoUrl\n    ...EmployerJobOfferTerminationModal_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n    numberOfOngoingRecruitmentTasks\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferTerminationModal_JobOffer on JobOffer {\n    id\n    title\n    numberOfOngoingRecruitmentTasks\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferTabs_JobOffer on JobOffer {\n    status\n    ...EmployerJobOfferJobApplicationsTabContent_JobOffer\n    ...EmployerJobOfferRecruitersTabContent_JobOffer\n    ...EmployerJobOfferPreviewTabContent_JobOffer\n    ...EmployerJobOfferSelectedProfilesTabContent_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferTabs_JobOffer on JobOffer {\n    status\n    ...EmployerJobOfferJobApplicationsTabContent_JobOffer\n    ...EmployerJobOfferRecruitersTabContent_JobOffer\n    ...EmployerJobOfferPreviewTabContent_JobOffer\n    ...EmployerJobOfferSelectedProfilesTabContent_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferJobApplicationsCards_JobApplication on JobApplication {\n    id\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      firstName\n      lastName\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferJobApplicationsCards_JobApplication on JobApplication {\n    id\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    profile {\n      id\n      firstName\n      lastName\n      linkedInProfileUrl\n      curriculumVitaeUrl\n      profilePictureUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferJobApplicationsList_JobApplication on JobApplication {\n    ...EmployerJobOfferJobApplicationsTable_JobApplication\n    ...EmployerJobOfferJobApplicationsCards_JobApplication\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferJobApplicationsList_JobApplication on JobApplication {\n    ...EmployerJobOfferJobApplicationsTable_JobApplication\n    ...EmployerJobOfferJobApplicationsCards_JobApplication\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferJobApplicationsTabContent_JobOffer on JobOffer {\n    id\n    status\n    numberOfJobApplications\n    isTerminated\n    ...CheckIfJobOfferBriefIsRecorded_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferJobApplicationsTabContent_JobOffer on JobOffer {\n    id\n    status\n    numberOfJobApplications\n    isTerminated\n    ...CheckIfJobOfferBriefIsRecorded_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query employerJobOfferJobApplications(\n    $jobOfferId: String!\n    $jobApplicationsFilters: JobApplicationsFilters\n  ) {\n    jobOfferApplications(\n      jobOfferId: $jobOfferId\n      filters: $jobApplicationsFilters\n    ) {\n      ...EmployerJobOfferJobApplicationsList_JobApplication\n    }\n  }\n'
): (typeof documents)['\n  query employerJobOfferJobApplications(\n    $jobOfferId: String!\n    $jobApplicationsFilters: JobApplicationsFilters\n  ) {\n    jobOfferApplications(\n      jobOfferId: $jobOfferId\n      filters: $jobApplicationsFilters\n    ) {\n      ...EmployerJobOfferJobApplicationsList_JobApplication\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationRecruiterDetailsModal_User on User {\n    id\n    createdAt\n    firstName\n    lastName\n    profilePictureUrl\n    searchFirm {\n      id\n      name\n      description\n    }\n    roles {\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationRecruiterDetailsModal_User on User {\n    id\n    createdAt\n    firstName\n    lastName\n    profilePictureUrl\n    searchFirm {\n      id\n      name\n      description\n    }\n    roles {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferJobApplicationsTable_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileNameCell_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    ...JobApplicationProfileCareerCell_JobApplication\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    profile {\n      id\n      creator {\n        id\n        ...JobApplicationRecruiterCell_User\n        ...JobApplicationRecruiterDetailsModal_User\n      }\n    }\n    statuses {\n      id\n    }\n    recruitmentTask {\n      id\n      ...JobApplicationRecruiterCell_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferJobApplicationsTable_JobApplication on JobApplication {\n    id\n    ...JobApplicationProfileNameCell_JobApplication\n    ...JobApplicationLastUpdateAlert_JobApplication\n    ...JobApplicationProfileCareerCell_JobApplication\n    ...EmployerJobOfferJobApplicationEditableStatusTag_JobApplication\n    profile {\n      id\n      creator {\n        id\n        ...JobApplicationRecruiterCell_User\n        ...JobApplicationRecruiterDetailsModal_User\n      }\n    }\n    statuses {\n      id\n    }\n    recruitmentTask {\n      id\n      ...JobApplicationRecruiterCell_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationProfileCareerCell_JobApplication on JobApplication {\n    profile {\n      curriculumVitaeUrl\n      linkedInProfileUrl\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationProfileCareerCell_JobApplication on JobApplication {\n    profile {\n      curriculumVitaeUrl\n      linkedInProfileUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationProfileNameCell_JobApplication on JobApplication {\n    id\n    profile {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n  }\n'
): (typeof documents)['\n  fragment JobApplicationProfileNameCell_JobApplication on JobApplication {\n    id\n    profile {\n      firstName\n      lastName\n      profilePictureUrl\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationRecruiterCell_User on User {\n    id\n    firstName\n    lastName\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n'
): (typeof documents)['\n  fragment JobApplicationRecruiterCell_User on User {\n    id\n    firstName\n    lastName\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment JobApplicationRecruiterCell_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'
): (typeof documents)['\n  fragment JobApplicationRecruiterCell_RecruitmentTask on RecruitmentTask {\n    id\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferPreview_JobOffer on JobOffer {\n    ...JobOfferBody_JobOffer\n    ...JobOfferContacts_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferPreview_JobOffer on JobOffer {\n    ...JobOfferBody_JobOffer\n    ...JobOfferContacts_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferPreviewTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferPreview_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferPreviewTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferPreview_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferRecruitersTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferRecruitmentTasksList_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferRecruitersTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferRecruitmentTasksList_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferRecruitmentTasksList_JobOffer on JobOffer {\n    id\n    status\n    numberOfOngoingRecruitmentTasks\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseEmployerJobOfferRecruitmentTasksTable_JobApplication on JobApplication {\n    id\n  }\n'
): (typeof documents)['\n  fragment UseEmployerJobOfferRecruitmentTasksTable_JobApplication on JobApplication {\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseEmployerJobOfferRecruitmentTasksTable_User on User {\n    id\n    createdAt\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n'
): (typeof documents)['\n  fragment UseEmployerJobOfferRecruitmentTasksTable_User on User {\n    id\n    createdAt\n    roles {\n      name\n    }\n    ...ChatContact_User\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      recruiter {\n        ...UseEmployerJobOfferRecruitmentTasksTable_User\n      }\n      jobApplications {\n        ...UseEmployerJobOfferRecruitmentTasksTable_JobApplication\n      }\n    }\n  '
): (typeof documents)['\n    fragment UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask on RecruitmentTask {\n      id\n      status\n      recruiter {\n        ...UseEmployerJobOfferRecruitmentTasksTable_User\n      }\n      jobApplications {\n        ...UseEmployerJobOfferRecruitmentTasksTable_JobApplication\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query useEmployerJobOfferRecruitmentTasksList($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask\n    }\n  }\n'
): (typeof documents)['\n  query useEmployerJobOfferRecruitmentTasksList($jobOfferId: String!) {\n    jobOfferRecruitmentTasks(jobOfferId: $jobOfferId) {\n      ...UseEmployerJobOfferRecruitmentTasksTable_RecruitmentTask\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferSelectedProfilesTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferSelectedProfilesTabContent_JobOffer on JobOffer {\n    id\n    ...EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer\n    ...EmployerJobOfferGoToProfileSearchButton_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferGoToProfileSearchButton_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferGoToProfileSearchButton_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      ...EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile\n      ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment\n      ...EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile\n    }\n  '
): (typeof documents)['\n    fragment EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      ...EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile\n      ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment\n      ...EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferLikedAnonymizedProfilesTable_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...EmployerJobOfferLikedAnonymizedProfilesTable_HiringManagerLikesAnonymizedProfile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query employerJobOfferLikedAnonymizedProfilesTable_anonymizedProfile(\n      $input: GetProfileInput!\n    ) {\n      anonymizedProfile(input: $input) {\n        ...SelectedProfileDrawer_AnonymizedProfile\n      }\n    }\n  '
): (typeof documents)['\n    query employerJobOfferLikedAnonymizedProfilesTable_anonymizedProfile(\n      $input: GetProfileInput!\n    ) {\n      anonymizedProfile(input: $input) {\n        ...SelectedProfileDrawer_AnonymizedProfile\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      createdAt\n    }\n  '
): (typeof documents)['\n    fragment EmployerJobOfferLikedAnonymizedProfilesCreatedAtCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      createdAt\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile on AnonymizedProfile {\n    id\n    firstName\n    profilePictureUrl\n    experiences {\n      id\n      jobTitle\n      employer {\n        name\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile on AnonymizedProfile {\n    id\n    firstName\n    profilePictureUrl\n    experiences {\n      id\n      jobTitle\n      employer {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        ...EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile\n      }\n    }\n  '
): (typeof documents)['\n    fragment EmployerJobOfferLikedAnonymizedProfilesNameCell_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        ...EmployerJobOfferLikedAnonymizedProfilesNameCell_AnonymizedProfile\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User on User {\n    id\n    email\n    firstName\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User on User {\n    id\n    email\n    firstName\n    profilePictureUrl\n    roles {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        creator {\n          id\n          ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User\n        }\n      }\n    }\n  '
): (typeof documents)['\n    fragment EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_HiringManagerLikesAnonymizedProfileFragment on HiringManagerLikesAnonymizedProfile {\n      id\n      anonymizedProfile {\n        id\n        creator {\n          id\n          ...EmployerJobOfferLikedAnonymizedProfilesRecruiterCell_User\n        }\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferTimeline_JobOffer on JobOffer {\n    status\n    numberOfOngoingRecruitmentTasks\n    numberOfJobApplications\n    totalNumberOfAcceptedForFirstInterviewJobApplications\n    numberOfHiredJobApplications\n    numberOfNewJobApplications\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferTimeline_JobOffer on JobOffer {\n    status\n    numberOfOngoingRecruitmentTasks\n    numberOfJobApplications\n    totalNumberOfAcceptedForFirstInterviewJobApplications\n    numberOfHiredJobApplications\n    numberOfNewJobApplications\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOfferPageProvider_JobOffer on JobOffer {\n    id\n    numberOfJobApplications\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOfferPageProvider_JobOffer on JobOffer {\n    id\n    numberOfJobApplications\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query employerJobOffersPage {\n    myUser {\n      __typename\n      ... on User {\n        ...ActivityMetric_User\n      }\n    }\n  }\n'
): (typeof documents)['\n  query employerJobOffersPage {\n    myUser {\n      __typename\n      ... on User {\n        ...ActivityMetric_User\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query employerJobOfferLeads_employerPaginatedJobOfferLeads(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: EmployerJobOfferLeadsFilters\n    ) {\n      employerPaginatedJobOfferLeads(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        totalCount\n        hasNextPage\n        nodes {\n          ...EmployerJobOfferLeads_JobOfferLead\n        }\n      }\n    }\n    fragment EmployerJobOfferLeads_JobOfferLead on JobOfferLead {\n      id\n      title\n      location\n      description\n      jobBoard\n      jobBoardUrl\n    }\n  '
): (typeof documents)['\n    query employerJobOfferLeads_employerPaginatedJobOfferLeads(\n      $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n      $filters: EmployerJobOfferLeadsFilters\n    ) {\n      employerPaginatedJobOfferLeads(\n        offsetBasedPaginationParams: $offsetBasedPaginationParams\n        filters: $filters\n      ) {\n        totalCount\n        hasNextPage\n        nodes {\n          ...EmployerJobOfferLeads_JobOfferLead\n        }\n      }\n    }\n    fragment EmployerJobOfferLeads_JobOfferLead on JobOfferLead {\n      id\n      title\n      location\n      description\n      jobBoard\n      jobBoardUrl\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query employerJobOffersTable_employerPaginatedJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query employerJobOffersTable_employerPaginatedJobOffers(\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n    $filters: EmployerJobOffersFilters\n  ) {\n    employerPaginatedJobOffers(\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n      filters: $filters\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerJobOffersTableColumns_JobOffer\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerJobOffersTableColumns_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfNewJobApplications\n    status\n    ...JobOfferStatusTag_JobOffer\n  }\n'
): (typeof documents)['\n  fragment EmployerJobOffersTableColumns_JobOffer on JobOffer {\n    id\n    title\n    createdAt\n    numberOfNewJobApplications\n    status\n    ...JobOfferStatusTag_JobOffer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Skill on Skill {\n    id\n    name\n  }\n'
): (typeof documents)['\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Skill on Skill {\n    id\n    name\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...EmployerProfileSearchPageAnonymizedProfileCard_Skill\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...EmployerProfileSearchPageAnonymizedProfileCard_Skill\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment EmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  '
): (typeof documents)['\n    fragment EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...EmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n'
): (typeof documents)['\n  fragment EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...EmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmployerProfileSearchPageUseAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EmployerProfileSearchPageUseAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...EmployerProfileSearchPageUseAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'
): (typeof documents)['\n  fragment EmployerProfileSearchPageAnonymizedProfileSelection_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferSelectionForm_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferSelectionForm_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      ...UseJobOfferSelectionForm_AnonymizedProfile\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      ...UseJobOfferSelectionForm_AnonymizedProfile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UseJobOfferSelectionForm_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    status\n    isTerminated\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n    likedAnonymizedProfiles {\n      ...UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile\n    }\n  }\n'
): (typeof documents)['\n  fragment UseJobOfferSelectionForm_JobOffer on JobOffer {\n    id\n    title\n    minNumberOfYearsOfExperience\n    status\n    isTerminated\n    academicBackgrounds {\n      id\n      name\n    }\n    newJobCategory {\n      id\n      name\n    }\n    skills {\n      id\n      name\n      status\n    }\n    likedAnonymizedProfiles {\n      ...UseJobOfferSelectionForm_HiringManagerLikesAnonymizedProfile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmployerProfileSearchPage_employerJobOffers {\n    employerJobOffers {\n      ...UseJobOfferSelectionForm_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  query EmployerProfileSearchPage_employerJobOffers {\n    employerJobOffers {\n      ...UseJobOfferSelectionForm_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment useProfileSearchSelectionSubmit_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'
): (typeof documents)['\n  fragment useProfileSearchSelectionSubmit_AnonymizedProfile on AnonymizedProfile {\n    id\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  '
): (typeof documents)['\n    fragment OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      profilePictureUrl\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_Education\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n'
): (typeof documents)['\n  fragment OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...OnboardingEmployerProfileSearchPageAnonymizedProfileCard_AnonymizedProfile\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EmployerProfileSearchPageAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EmployerProfileSearchPageAnonymizedProfileList_paginatedAnonymizedProfiles(\n    $filters: AnonymizedProfilesFilters\n    $offsetBasedPaginationParams: OffsetBasedPaginationParams!\n  ) {\n    paginatedAnonymizedProfiles(\n      filters: $filters\n      offsetBasedPaginationParams: $offsetBasedPaginationParams\n    ) {\n      totalCount\n      hasNextPage\n      nodes {\n        ...OnboardingEmployerProfileSearchPageAnonymizedProfileList_AnonymizedProfile\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createMySearchFirm($input: CreateSearchFirmInput!) {\n    createSearchFirm(input: $input) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  mutation createMySearchFirm($input: CreateSearchFirmInput!) {\n    createSearchFirm(input: $input) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    query recruiterRecruitmentTaskDetailsByJobOfferId($jobOfferId: String!) {\n      recruiterRecruitmentTaskByJobOfferId(jobOfferId: $jobOfferId) {\n        id\n        jobOffer {\n          ...JobOfferHeader_JobOffer\n        }\n        ...RecruiterRecruitmentTaskTabs_RecruitmentTask\n      }\n    }\n  '
): (typeof documents)['\n    query recruiterRecruitmentTaskDetailsByJobOfferId($jobOfferId: String!) {\n      recruiterRecruitmentTaskByJobOfferId(jobOfferId: $jobOfferId) {\n        id\n        jobOffer {\n          ...JobOfferHeader_JobOffer\n        }\n        ...RecruiterRecruitmentTaskTabs_RecruitmentTask\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskDetailsTabContent_RecruitmentTask on RecruitmentTask {\n    id\n    status\n    recruiterFlatFeeInBasisPoints\n    recruiterSuccessFeeInBasisPoints\n    jobOffer {\n      id\n      isTerminated\n      contractType\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      ...JobOfferBody_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskDetailsTabContent_RecruitmentTask on RecruitmentTask {\n    id\n    status\n    recruiterFlatFeeInBasisPoints\n    recruiterSuccessFeeInBasisPoints\n    jobOffer {\n      id\n      isTerminated\n      contractType\n      latestSuccessFee {\n        id\n        basisPoints\n      }\n      ...JobOfferBody_JobOffer\n      ...JobOfferContacts_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTaskTabs_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskPageProvider_RecruitmentTask\n    ...RecruitmentTaskDetailsTabContent_RecruitmentTask\n    ...RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTaskTabs_RecruitmentTask on RecruitmentTask {\n    id\n    ...RecruitmentTaskPageProvider_RecruitmentTask\n    ...RecruitmentTaskDetailsTabContent_RecruitmentTask\n    ...RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Skill on Skill {\n    id\n    name\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Skill on Skill {\n    id\n    name\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...RecruiterRecruitmentTaskRelevantProfileCard_Skill\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience on AnonymizedExperience {\n    id\n    jobTitle\n    startedAt\n    endedAt\n    employer {\n      name\n    }\n    skills {\n      ...RecruiterRecruitmentTaskRelevantProfileCard_Skill\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTaskRelevantProfileCard_Education on Education {\n    id\n    startedAt\n    endedAt\n    school {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      firstName\n      profilePictureUrl\n      creator {\n        id\n        firstName\n        lastName\n        email\n      }\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_Education\n      }\n    }\n  '
): (typeof documents)['\n    fragment RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile on AnonymizedProfile {\n      id\n      firstName\n      profilePictureUrl\n      creator {\n        id\n        firstName\n        lastName\n        email\n      }\n      urbanArea {\n        id\n        name\n      }\n      experiences {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedExperience\n      }\n      educations {\n        ...RecruiterRecruitmentTaskRelevantProfileCard_Education\n      }\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskRelevantProfileCardList_Employer on Employer {\n    id\n    name\n    ...RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskRelevantProfileCardList_Employer on Employer {\n    id\n    name\n    ...RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskRelevantProfileCardList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskRelevantProfileCardList_AnonymizedProfile on AnonymizedProfile {\n    id\n    ...RecruiterRecruitmentTaskRelevantProfileCard_AnonymizedProfile\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_AnonymizedProfile\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile on HiringManagerLikesAnonymizedProfile {\n    id\n    anonymizedProfile {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_AnonymizedProfile\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskRelevantProfileCardList_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile\n    }\n    employer {\n      ...RecruitmentTaskRelevantProfileCardList_Employer\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskRelevantProfileCardList_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      ...RecruitmentTaskRelevantProfileCardList_HiringManagerLikesAnonymizedProfile\n    }\n    employer {\n      ...RecruitmentTaskRelevantProfileCardList_Employer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskRelevantProfileCardList_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruitmentTaskRelevantProfileCardList_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskRelevantProfileCardList_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruitmentTaskRelevantProfileCardList_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer on Employer {\n      id\n      name\n    }\n  '
): (typeof documents)['\n    fragment RecruitmentTaskRelevantProfilesEmptyListDisplay_Employer on Employer {\n      id\n      name\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    fragment RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_RecruitmentTask\n    }\n  '
): (typeof documents)['\n    fragment RecruiterRecruitmentTaskRelevantProfilesTabContent_RecruitmentTask on RecruitmentTask {\n      id\n      ...RecruitmentTaskRelevantProfileCardList_RecruitmentTask\n    }\n  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruiterRecruitmentTaskPageProvider_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      id\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruiterRecruitmentTaskPageProvider_JobOffer on JobOffer {\n    id\n    likedAnonymizedProfiles {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RecruitmentTaskPageProvider_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruiterRecruitmentTaskPageProvider_JobOffer\n    }\n  }\n'
): (typeof documents)['\n  fragment RecruitmentTaskPageProvider_RecruitmentTask on RecruitmentTask {\n    id\n    jobOffer {\n      ...RecruiterRecruitmentTaskPageProvider_JobOffer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query recruiterSettingsUser {\n    myUser {\n      __typename\n      ...RecruiterSettingsGeneralTab_User\n      ...RecruiterSettingsSpecializationsTab_User\n    }\n  }\n'
): (typeof documents)['\n  query recruiterSettingsUser {\n    myUser {\n      __typename\n      ...RecruiterSettingsGeneralTab_User\n      ...RecruiterSettingsSpecializationsTab_User\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
