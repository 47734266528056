import { SpinnerIcon } from '@core/components/Icons/SpinnerIcon.component';
import { IconBaseProps } from '@core/components/Icons/types/IconBaseProps';
import { useStopClickEventsPropagation } from '@core/hooks/useStopDurationClickEventsPropagation.hook';
import { AtomicFunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { Size, SizeXsToLg } from '@core/types/size.types';
import classNames from 'classnames';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

// Link to Figma: https://www.figma.com/file/guEcqpJn8S3QdFpl34O0Jv/Design-system?type=design&node-id=787-3043&t=E8OH3QOFX8BtPv6q-0

export enum BasicButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Ghost = 'ghost',
  Danger = 'danger',
  DangerOutline = 'dangerOutline',
}

export interface BasicButtonProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: BasicButtonVariant;
  size?: SizeXsToLg;
  isDisabled?: boolean;
  OnlyIcon?: FunctionComponent<IconBaseProps>;
  LeftIcon?: FunctionComponent<IconBaseProps>;
  RightIcon?: FunctionComponent<IconBaseProps>;
  isLoading?: boolean;
  enableTextWrap?: boolean;
  isUppercase?: boolean;
  isFullWidth?: boolean;
  label?: ReactNode;
  enableClickEventsPropagation?: boolean;
  dataTestId?: string; // only for tests
  dataCustomId?: string; // only for analytics
}

export const BasicButton: AtomicFunctionComponentWithChildren<
  BasicButtonProps
> = ({
  id,
  type = 'button',
  children,
  onClick,
  isDisabled,
  isLoading,
  LeftIcon,
  RightIcon,
  OnlyIcon,
  label,
  variant = BasicButtonVariant.Primary,
  size = Size.Md,
  enableTextWrap = false,
  isUppercase = false,
  isFullWidth = false,
  enableClickEventsPropagation = false,
  dataTestId,
  dataCustomId,
}) => {
  const stopClickEventsPropagationProps = useStopClickEventsPropagation();

  const clickEventrPropagationProps = enableClickEventsPropagation
    ? {}
    : stopClickEventsPropagationProps;

  const isClickable = !(isLoading || isDisabled);

  // It the button content is only an icon, it renders a circular button
  const isCircular = !!OnlyIcon;

  // Force text-inherit so that icon get the color of the variant
  const iconSizes = classNames('text-inherit', {
    'h-3 w-3': size === Size.Xs,
    'h-4 w-4': size === Size.Sm,
    'h-5 w-5': size === Size.Md,
    'h-6 w-6': size === Size.Lg,
  });

  return (
    <div
      className={classNames({ 'w-full': isFullWidth })}
      {...clickEventrPropagationProps}
    >
      <button
        data-testid={dataTestId}
        data-custom-id={dataCustomId}
        id={id}
        type={type}
        onClick={isClickable ? onClick : undefined}
        disabled={!isClickable}
        className={classNames(
          'relative max-h-full cursor-pointer select-none rounded-full',
          {
            'w-full': !isCircular,
            uppercase: isUppercase,
            'whitespace-nowrap': !enableTextWrap,

            'transition-all duration-150 hover:-translate-y-[1px] active:translate-y-[3px] active:shadow-none':
              isClickable,
            'cursor-not-allowed opacity-50': !isClickable,

            'border-catalina-blue bg-persian-blue border font-bold text-white [box-shadow:0_2px_0_0_theme(colors.catalina-blue)]':
              variant === BasicButtonVariant.Primary,
            'hover:[box-shadow:0_3px_0_0_theme(colors.catalina-blue)]':
              variant === BasicButtonVariant.Primary && isClickable,
            'border-persian-blue text-persian-blue border bg-white font-semibold [box-shadow:0_2px_0_0_theme(colors.persian-blue)] ':
              variant === BasicButtonVariant.Secondary,
            'hover:[box-shadow:0_3px_0_0_theme(colors.persian-blue)]':
              variant === BasicButtonVariant.Secondary && isClickable,
            'border-dark-red bg-radical-red border font-bold text-white [box-shadow:0_2px_0_0_theme(colors.dark-red)] ':
              variant === BasicButtonVariant.Danger,
            'hover:[box-shadow:0_3px_0_0_theme(colors.dark-red)]':
              variant === BasicButtonVariant.Danger && isClickable,
            'border-radical-red text-radical-red border bg-white font-bold [box-shadow:0_2px_0_0_theme(colors.radical-red)]':
              variant === BasicButtonVariant.DangerOutline,
            'hover:[box-shadow:0_3px_0_0_theme(colors.radical-red)]':
              variant === BasicButtonVariant.DangerOutline && isClickable,
            'text-catalina-blue hover:bg-catalina-blue/10 bg-transparent font-bold':
              variant === BasicButtonVariant.Ghost,
            'hover:[box-shadow:0_3px_0_0_theme(colors.grey)]':
              variant === BasicButtonVariant.Ghost && isClickable,

            'text-xxs h-6 px-2': size === Size.Xs && !isCircular,
            'h-7 px-3 text-xs': size === Size.Sm && !isCircular,
            'h-8 px-4 text-sm': size === Size.Md && !isCircular,
            'h-9 px-5 text-base': size === Size.Lg && !isCircular,

            'h-6 w-6': size === Size.Xs && isCircular,
            'h-7 w-7': size === Size.Sm && isCircular,
            'h-8 w-8': size === Size.Md && isCircular,
            'h-9 w-9': size === Size.Lg && isCircular,
          }
        )}
      >
        <div
          className={classNames('flex items-center justify-center', {
            invisible: isLoading,
          })}
        >
          {OnlyIcon ? (
            <OnlyIcon className={iconSizes} />
          ) : (
            <>
              {LeftIcon && (
                <div className="mr-1.5">
                  <LeftIcon className={iconSizes} />
                </div>
              )}
              <div
                className={classNames({
                  'underline underline-offset-2':
                    variant === BasicButtonVariant.Ghost,
                })}
              >
                {label || children}
              </div>
              {RightIcon && (
                <div className="ml-1.5">
                  <RightIcon className={iconSizes} />
                </div>
              )}
            </>
          )}
        </div>

        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <SpinnerIcon className={iconSizes} />
          </div>
        )}
      </button>
    </div>
  );
};
