/**
 * DO NOT change those values, those events are sent by the backend.
 * see https://github.com/muzzo-tech/job-marketplace-api/blob/staging/src/shared-kernel/application/frontend-event-system/frontend-event.ts
 * If you really need to, make sure to correctly version your changes on the API side so that the contract is always fulfilled
 */
export enum FrontendEventName {
  unreadMessagesCountUpdated = 'unread-messages-count-updated',
  messagePosted = 'message-posted',
  videoAskAnswered = 'video-ask-answered',
  jobOfferClassified = 'job-offer-classified',
}

/**
 * DO NOT change those values, those events are sent by the backend.
 * see https://github.com/muzzo-tech/job-marketplace-api/blob/staging/src/shared-kernel/application/frontend-event-system/frontend-event.ts
 * If you really need to, make sure to correctly version your changes on the API side so that the contract is always fulfilled
 */
export interface FrontendEvent {
  [FrontendEventName.unreadMessagesCountUpdated]: {
    unreadMessagesCount: number;
  };
  [FrontendEventName.messagePosted]: {
    messageId: string;
    chatChannelId: string;
  };
  [FrontendEventName.videoAskAnswered]: {
    jobOfferId: string;
    shareUrl: string;
    status: string; // VideoAskAnswerStatus
  };
  [FrontendEventName.jobOfferClassified]: {
    jobId: string;
    jobOfferId: string;
  };
}
