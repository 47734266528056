import { FieldMergeFunction, FieldReadFunction } from '@apollo/client';
import { isReference } from '@apollo/client/cache';
import { mergeIncomingHasNextPageIntoCache } from '@core/clients/apollo/cache/mergeAndReadMethods/mergeIncomingHasNextPageIntoCache';
import {
  ExistingMyChatChannelsCache,
  IncomingForMyChatChannelsCache,
  PartialChatChannelOffsetBasedConnection,
} from '@core/clients/apollo/cache/mergeAndReadMethods/myChatChannels.cache.types';
import { ChatChannel, Message } from 'graphql/DEPRECATED/generated';
import { orderBy, uniqBy } from 'lodash';

export const mergeIncomingChatChannelsIntoCache: FieldMergeFunction<
  ExistingMyChatChannelsCache,
  IncomingForMyChatChannelsCache
> = (existing, incoming, options): PartialChatChannelOffsetBasedConnection => {
  const readField = options.readField;

  const existingNodes = existing?.nodes ?? [];
  const incomingNodes = incoming.nodes;

  const mergedNodes = orderBy(
    uniqBy(
      [...incomingNodes, ...existingNodes],
      (chatChannelRef) => chatChannelRef.__ref
    ),
    (chatChannelRef) => {
      const lastMessageFieldName: keyof ChatChannel = 'lastMessage'; // TODO: use typescript satisfies when typescript version is >= 4.9
      const createdAtFieldName: keyof Message = 'createdAt'; // TODO: use typescript satisfies when typescript version is >= 4.9

      const lastMessageRef = readField({
        fieldName: lastMessageFieldName,
        from: chatChannelRef,
      });
      if (!isReference(lastMessageRef)) {
        return undefined;
      }
      const createdAt = readField<string>({
        fieldName: createdAtFieldName,
        from: lastMessageRef,
      });

      if (!createdAt) {
        return undefined;
      }
      // createdAt is a date as a string value in ISO format
      return new Date(createdAt);
    },
    'desc'
  );

  return {
    ...existing,
    __typename: incoming.__typename,
    hasNextPage: mergeIncomingHasNextPageIntoCache({
      existingHasNextPage: existing && existing.hasNextPage,
      incomingHasNextPage: incoming.hasNextPage,
    }),
    nodes: mergedNodes,
  };
};

type MyChatChannelsCacheReadResult = PartialChatChannelOffsetBasedConnection;

export const readMyChatChannelsFromCache: FieldReadFunction<
  ExistingMyChatChannelsCache,
  MyChatChannelsCacheReadResult
> = (existing) => {
  return existing;
};
