import { useApolloQuery } from '@core/hooks/useApolloQuery.hook';
import { UnreadMessagesCountDocument } from 'graphql/DEPRECATED/generated';
import { useMyUserContext } from 'modules/MyUser/context/MyUserContext';
import { FrontendEventName } from 'modules/Pusher/frontendEvent';
import { usePusherUserEvent } from 'modules/Pusher/usePusherUserEvent';
import { useCallback, useState } from 'react';

export const useUnreadChatMessagesCount = () => {
  const { myMarketplaceUser } = useMyUserContext();
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] = useState<
    number | undefined
  >(undefined);

  useApolloQuery(UnreadMessagesCountDocument, {
    onCompleted: (data) => {
      setUnreadChatMessagesCount(data.unreadMessagesCount);
    },
    skip: !myMarketplaceUser,
  });

  const updateUnreadChatMessagesCount = useCallback(
    (data: { unreadMessagesCount: number }) => {
      setUnreadChatMessagesCount(data.unreadMessagesCount);
    },
    []
  );

  usePusherUserEvent(
    FrontendEventName.unreadMessagesCountUpdated,
    updateUnreadChatMessagesCount
  );

  return unreadChatMessagesCount;
};
