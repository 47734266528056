import { makeContext } from '@core/context/makeContext';
import { ResultOf } from '@graphql-typed-document-node/core';
import { PermissionName } from 'graphql/generated/graphql';
import { AuthRole } from 'modules/Authentication/hooks/useIsAuthorizedOrRedirectToHomePage.hook';
import { myUserQuery } from 'modules/MyUser/context/MyUserProvider';
import { Dispatch, SetStateAction } from 'react';

type MyUserQueryResult = Exclude<
  ResultOf<typeof myUserQuery>['myUser'],
  null | undefined
>;
export type MarketplaceUser = Extract<
  MyUserQueryResult,
  { __typename: 'User' }
>;
export type UserLead = Extract<MyUserQueryResult, { __typename: 'UserLead' }>;
export type MyUser = MarketplaceUser | UserLead;

interface MyUserContextValue {
  myUser: MyUser | null;
  myMarketplaceUser: MarketplaceUser | null;
  myUserLead: UserLead | null;
  isMyUserAdmin: boolean;
  hasAgreedToLastTermsOfServiceVersion: boolean;
  hasAgreedToMinimumRequiredTermsOfServiceVersion: boolean;
  hasPermission: (permission: PermissionName) => boolean;
  isLoadingMyUser: boolean;
  refetchMyUser: () => Promise<{
    myMarketplaceUser: MarketplaceUser | null;
    myUserLead: UserLead | null;
  }>;
  authRoles: { get(): AuthRole[]; set: Dispatch<SetStateAction<AuthRole[]>> };
}

export const { Provider: MyUserContextProvider, useContext: useMyUserContext } =
  makeContext<MyUserContextValue>('MyUserContext');
