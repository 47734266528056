import { ApolloLink } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { OperationTypeNode } from 'graphql';
import { DocumentType } from 'graphql/generated';

interface AnalyticsLinkOptions {
  readonly analytics: { track: (eventName: string, payload: any) => void };
  readonly operationTypeWhitelist: Array<OperationTypeNode>;
  readonly operationDocumentWhitelist: Array<DocumentType<TypedDocumentNode>>;
}
export const makeAnalyticsLink = (opts: AnalyticsLinkOptions) => {
  return new ApolloLink((operation, forward) => {
    // Note: to track event before the request is sent, we need to code before the return forward(data)
    // To track event after the request is successfully sent, we need to code in the forward(data).map(data => {... return data})
    // See https://www.apollographql.com/docs/react/api/link/introduction/#handling-a-response
    return forward(operation).map((data) => {
      const { operationName, query, variables } = operation;

      const operationNameWhitelist = opts.operationDocumentWhitelist.reduce(
        (accumulator: string[], operationDocument) => {
          const operationDefinition = operationDocument.definitions[0];
          if (
            operationDefinition &&
            'name' in operationDefinition &&
            operationDefinition.name?.value
          ) {
            return [...accumulator, operationDefinition.name?.value];
          }
          return accumulator;
        },
        []
      );

      if (query.definitions[0]?.kind === 'OperationDefinition') {
        const operationType = query.definitions[0].operation;
        if (
          opts.operationTypeWhitelist.includes(operationType) ||
          operationNameWhitelist.includes(operationName)
        ) {
          opts.analytics.track(operationName, {
            operationType,
            variables,
            responseData: data,
          });
        }
      }
      return data;
    });
  });
};
