import * as Sentry from '@sentry/nextjs';
import {
  AnalyticsEvent,
  AnalyticsService,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';

export class SentryAnalyticService implements AnalyticsService {
  init = () => {
    // Handled by:
    // sentry.server.config.ts
    // sentry.client.config.ts
  };

  // TODO: implement the trackGqlOperation logic if useful
  trackGqlOperation = (_operationName: string, _payload?: object) => {};

  // TODO: implement the track event logic if useful
  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    _eventName: TAnalyticsEventName,
    _payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {};

  // TODO: implement the track event logic if useful
  trackPageView = (_page: string) => {};

  identifyUser = (user: AnalyticsUser) => {
    this.setSentryUser(user);
  };

  forgetUser = () => {
    this.setSentryUser(null);
  };

  setSentryUser = (user: AnalyticsUser | null) => {
    Sentry.setUser(user);
    Sentry.withScope((scope) => {
      scope.setTag('user_email', user?.email ?? null);
    });
  };
}
