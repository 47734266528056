import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/nextjs';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';
import { AuthService } from 'modules/Authentication/authentication.interface';
import * as uuid from 'uuid';

export const getContextLink = ({
  authenticationService,
}: {
  authenticationService: AuthService;
}) => {
  return setContext(async () => {
    try {
      const transactionId = uuid.v4();
      // generate unique transactionId and set as Sentry tag as explained
      // here https://blog.sentry.io/2019/04/04/trace-errors-through-stack-using-unique-identifiers-in-sentry
      Sentry.withScope((scope) => {
        scope.setTag('transaction_id', transactionId);
      });

      const authorizationHeaders =
        await authenticationService.getAuthorizationHeaders();

      return {
        headers: {
          ...authorizationHeaders,
          'X-Transaction-ID': transactionId,
        },
      };
    } catch (error) {
      reportErrorToSentry(error);
    }
  });
};
