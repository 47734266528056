export enum Size {
  Xxs = 'extra extra small',
  Xs = 'extra small',
  Sm = 'small',
  Md = 'medium',
  Lg = 'large',
  Xl = 'extra large',
  Xxl = 'extra extra large',
}

export type SizeXsToSm = Size.Xs | Size.Sm;
export type SizeSmToLg = Size.Sm | Size.Md | Size.Lg;
export type SizeSmToXl = SizeSmToLg | Size.Xl;
export type SizeXsToLg = Size.Xs | SizeSmToLg;
export type SizeXxsToXxl = Size.Xxs | Size.Xs | SizeSmToLg | Size.Xl | Size.Xxl;
