import { Banner } from '@core/components/Banners/Banner.component';
import { AtomicFunctionComponentWithChildren } from '@core/types/functionComponent.types';

interface Props {
  className?: string;
}

export const UrgentBanner: AtomicFunctionComponentWithChildren<Props> = ({
  children,
}) => {
  return (
    <Banner isFullWidth noRoundedCorners>
      <div className="bold md:text-md text-xs sm:text-sm">{children}</div>
    </Banner>
  );
};
