import { SpinnerIcon } from '@core/components/Icons/SpinnerIcon.component';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface Props {
  centering?: 'full-screen' | 'block' | 'none';
}

export const SpinnerLoader: FunctionComponent<Props> = ({
  centering = 'none',
}) => {
  return (
    <div
      className={classNames({
        'flex items-center justify-center h-full w-full': centering === 'block',
        'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2':
          centering === 'full-screen',
      })}
    >
      <SpinnerIcon className="text-klein-blue h-6 w-6" />
    </div>
  );
};
