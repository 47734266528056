import {
  AuthenticationPageConfig,
  AuthService,
} from 'modules/Authentication/authentication.interface';

export class AuthenticationOfflineService implements AuthService {
  getUser = () => {
    return Promise.resolve({
      email: process.env.USER_EMAIL,
      email_verified: true,
      authUserId: `offline-auth0-user-id+${process.env.USER_EMAIL}`,
    });
  };

  getTokenSilently = (_config?: { ignoreCache: boolean } | undefined) => {
    return Promise.resolve('cheatcode');
  };

  goToLoginPage = (_config?: AuthenticationPageConfig) => {
    return Promise.resolve();
  };

  goToSignupPage = (_config?: AuthenticationPageConfig) => {
    return Promise.resolve();
  };

  isAuthenticated = () => {
    return Promise.resolve(true);
  };

  getAuthorizationHeaders = () => {
    return Promise.resolve({
      Authorization: 'cheatcode',
      auth0userid: 'auth0UserId1',
    });
  };

  logout = (_config: { returnTo: string }) => {
    return Promise.resolve();
  };

  handleRedirectCallback = () => {
    return Promise.resolve({
      appState: { redirectUrl: undefined },
    });
  };
}
