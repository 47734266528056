import { UnspecifiedError } from '@core/errors';
import React, { createContext, createElement, useContext } from 'react';

interface ProviderProps<Context> {
  value: Context;
  children: React.ReactNode;
}

export const makeContext = <Context,>(contextName: string) => {
  const context = createContext<Context | null>(null);
  context.displayName = contextName;
  const Provider = ({ children, value }: ProviderProps<Context>) =>
    createElement(context.Provider, { value }, children);

  const useContextValue = () => {
    const contextValue = useContext(context);
    if (!contextValue) {
      throw new UnspecifiedError(
        `Context not injected, you probably forgot to use the ${contextName} Provider node`
      );
    }

    return contextValue;
  };

  return { Provider, useContext: useContextValue, Consumer: context.Consumer };
};
