import { DOMAttributes } from 'react';

/** WARNING:
 * These props should be used in a parent element of the element that triggers the event.
 * Example with 'onClick'
 *
 * const stopClickEventsPropagationProps = useStopClickEventsPropagation();
 *
 * <div onClick={doSomething} {...stopClickEventsPropagationProps} />
 * Here "doSomething" gets overriden
 *
 * <div {...stopClickEventsPropagationProps} onClick={doSomething} />
 * Here "stopPropagation" gets overriden
 *
 * RIGHT WAY:
 * <div {...stopClickEventsPropagationProps}>
 * .
 * .
 *  <div onClick={doSomething} />
 * .
 * .
 * </div>
 *
 * It' not necessary the direct parent, so that it can work for several kids events, but just don't forget it!
 *  */

export const useStopClickEventsPropagation = (): Pick<
  DOMAttributes<Element>,
  | 'onClick'
  | 'onMouseDown'
  | 'onMouseUp'
  | 'onMouseLeave'
  | 'onTouchStart'
  | 'onTouchEnd'
> => {
  return {
    onClick: (e) => {
      e.stopPropagation();
    },
    onMouseDown: (e) => {
      e.stopPropagation();
    },
    onMouseUp: (e) => {
      e.stopPropagation();
    },
    onTouchStart: (e) => {
      e.stopPropagation();
    },
    onMouseLeave: (e) => {
      e.stopPropagation();
    },
    onTouchEnd: (e) => {
      e.stopPropagation();
    },
  };
};
