import { MissingEnvironmentVariableError } from '@core/errors';

export const getAppBaseUrl = (): string => {
  if (process.env.NEXT_PUBLIC_ENV === 'preview') {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  const nextPublicAppBaseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL;
  if (!nextPublicAppBaseUrl) {
    throw new MissingEnvironmentVariableError({
      envVarName: 'NEXT_PUBLIC_APP_BASE_URL',
    });
  }
  return nextPublicAppBaseUrl;
};
