import {
  MissingEnvironmentVariableError,
  UnspecifiedError,
} from '@core/errors';
import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import axios from 'axios';
import { backOff } from 'exponential-backoff';
import { logBreadcrumbs } from 'modules/Analytics/utils/reportError.utils';
import { useAuthenticationServiceContext } from 'modules/Authentication/context/AuthenticationServiceContext';
import { useMyUserContext } from 'modules/MyUser/context/MyUserContext';
import { PusherContextProvider } from 'modules/Pusher/PusherContext';
import pusherJs from 'pusher-js';
import { useCallback, useEffect, useMemo, useState } from 'react';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(function (config) {
  logBreadcrumbs({
    message: 'PusherProvider axios request interceptor',
    data: {
      url: config.url,
      method: config.method,
      headers: config.headers,
    },
  });
  return config;
});

/*
 * Check the "How to use Pusher?" standard for more details
 * see https://www.notion.so/muzzo/How-to-use-Pusher-4acdbe70feb84c2297b09738e4551fd5
 */
export const PusherProvider: FunctionComponentWithChildren = ({ children }) => {
  const [client, setClient] = useState<pusherJs | undefined>();
  const { authenticationService } = useAuthenticationServiceContext();
  const { myMarketplaceUser } = useMyUserContext();

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER) {
      throw new MissingEnvironmentVariableError({
        envVarName: 'NEXT_PUBLIC_PUSHER_APP_CLUSTER',
      });
    }

    setClient(
      new pusherJs(process.env.NEXT_PUBLIC_PUSHER_APP_KEY ?? '', {
        cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
        userAuthentication: {
          endpoint: '', // Typescript forces us to set this prop but this props is not used because customHandler is set
          transport: 'ajax', // Typescript forces us to set this prop but this props is not used because customHandler is set
          /**
           * The customHandler function is called when pusherClient.signin method is called
           * see https://pusher.com/docs/channels/using_channels/connection/#userauthcustomhandler-1319001424
           */
          customHandler: async (params, callback) => {
            const pusherAuthentProcess = async () => {
              const { socketId } = params;

              const authorizationHeaders =
                await authenticationService.getAuthorizationHeaders();
              const auth0User = await authenticationService.getUser();

              logBreadcrumbs({
                message: 'Authenticating Pusher user',
                data: {
                  socket_id: socketId,
                  auth0UserId: auth0User?.authUserId,
                  auth0UserEmail: auth0User?.email,
                  authorizationHeaders,
                },
              });

              const response = await axiosInstance.post<{
                auth: string;
                user_data: string;
              }>(
                `${process.env.NEXT_PUBLIC_API_URL}/pusher/user-auth`,
                { socket_id: socketId },
                {
                  headers: {
                    ...authorizationHeaders,
                  },
                  timeout: 3000,
                }
              );

              logBreadcrumbs({
                message: '/pusher/user-auth response',
                data: {
                  'response.data': response.data,
                },
              });

              callback(null, {
                auth: response.data.auth,
                user_data: response.data.user_data,
              });
            };

            try {
              await backOff(pusherAuthentProcess, {
                numOfAttempts: 3,
                retry(error, attemptNumber) {
                  logBreadcrumbs({
                    message: `Retry attempt #${attemptNumber}`,
                    data: { error },
                  });
                  // We retry in every cases, we just use this function to log the error and the retry
                  return true;
                },
              });
            } catch (error) {
              logBreadcrumbs({
                message:
                  'An unexpected error has occured while authenticating pusher user',
                data: {
                  error,
                },
              });
              // We rethrow here as we just want to add intermediary logs before throwing error
              throw new UnspecifiedError('Cannot authenticate pusher user');
            }
          },
        },
      })
    );
    // We only want to setup Pusher once after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticatePusherUser = useCallback(async () => {
    if (!client) {
      return;
    }

    client.signin();
  }, [client]);

  const value = useMemo(() => {
    return {
      client,
      authenticatePusherUser,
    };
  }, [authenticatePusherUser, client]);

  useEffect(() => {
    if (myMarketplaceUser) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      authenticatePusherUser();
    }
  }, [authenticatePusherUser, myMarketplaceUser]);

  /**
   * Uncomment if you want to log all pusher events for debugging purposes
   */
  // useEffect(() => {
  //   if (client) {
  //     client.bind_global((eventName: any, data: any) => {
  //       console.log(
  //         `Pusher bind_global: The event ${eventName} was triggered with data ${JSON.stringify(
  //           data
  //         )}`
  //       );
  //     });
  //   }
  //   return () => {
  //     if (client) {
  //       client.unbind_global();
  //     }
  //   };
  // }, [client]);

  return (
    <PusherContextProvider value={value}>{children}</PusherContextProvider>
  );
};
