import { useApolloLazyQuery } from '@core/hooks/useApolloQuery.hook';
import { GetSupportChatUserIdentifierDocument } from 'graphql/DEPRECATED/generated';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';
import { MyUser } from 'modules/MyUser/context/MyUserContext';
import { isMarketplaceUser } from 'modules/MyUser/context/MyUserProvider';

/**
 * TODO: Move this logic in HubspotAnalyticService.
 * It is not simple as this hook needs apolloClient and apolloClient needs HubspotAnalyticService.
 *
 * This method follows recommendation from hubspot
 * to identify a visitor: https://developers.hubspot.com/docs/api/conversation/visitor-identification
 */
export const useIdentifyMyUserInHubspotChat = () => {
  const [queryGetSupportChatUserIdentifier] = useApolloLazyQuery(
    GetSupportChatUserIdentifierDocument
  );

  const identifyMyUserInHubspotChat = async ({
    myUser,
  }: {
    myUser: MyUser;
  }) => {
    if (!process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID) return;

    if (!isMarketplaceUser(myUser)) {
      // TODO: Update the API supportChatUserIdentifier query so that it identifies MyUser if myUser is of UserLead type
      return;
    }

    try {
      const { data } = await queryGetSupportChatUserIdentifier();
      const identificationToken = data?.supportChatUserIdentifier;

      window.hsConversationsSettings = {
        identificationEmail: myUser.email,
        identificationToken,
      };

      // We need to reset then load to be sure
      window.HubSpotConversations?.resetAndReloadWidget();
      window.HubSpotConversations?.widget.load();
    } catch (error) {
      reportErrorToSentry(error);
    }
  };

  return { identifyMyUserInHubspotChat };
};
