export const mergeIncomingHasNextPageIntoCache = ({
  existingHasNextPage,
  incomingHasNextPage,
}: {
  existingHasNextPage: boolean | undefined;
  incomingHasNextPage: boolean;
}) => {
  if (existingHasNextPage === false) {
    return false;
  }

  return incomingHasNextPage;
};
