import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';

export const HubspotAnalytics: FunctionComponentWithChildren = ({
  children,
}) => {
  return (
    <>
      {/**
       * We want to ignore all app forms from being collected by Hubspot
       * Otherwise, wrong properties could be associated to the Hubspot contact
       * - see https://www.notion.so/muzzo/Pb-profils-Muzzo-qui-remontent-dans-HubSpot-1bd27afaacc74ebd837c61fb31d302e7
       * We can't deactivate the Hubspot external form setting as Growth team needs it: https://app-eu1.hubspot.com/settings/25172544/marketing/form/non-hubspot
       *
       * => Solution is described here: https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/367909/highlight/true#M4606
       * We add a HTML element with a specific ID so that Hubspot script won't install the collectedforms.js script
       * The hubspot script (that is loaded by gtm) can be found here: https://js-eu1.hs-scripts.com/25172544.js
       */}
      {process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID ? (
        <script
          id={`CollectedForms-${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID}`}
        ></script>
      ) : null}
      {children}
    </>
  );
};
