import { createContext } from 'react';

export enum ScreenSize {
  Mobile = 'Mobile',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}
export interface ScreenSizeContextValue {
  isMobileScreen: boolean;
  currentScreenSize?: ScreenSize;
  checkIfScreenSizeIsGreaterOrEqualTo: (limitScreenSize: ScreenSize) => boolean;
  checkIfScreenSizeIsLowerOrEqualTo: (limitScreenSize: ScreenSize) => boolean;
}

export const ScreenSizeContext = createContext<ScreenSizeContextValue>({
  isMobileScreen: false,
  currentScreenSize: undefined,
  checkIfScreenSizeIsGreaterOrEqualTo: () => false,
  checkIfScreenSizeIsLowerOrEqualTo: () => false,
});
