import { AuthenticatedUser } from 'modules/Authentication/authentication.interface';
import { useAuthenticationServiceContext } from 'modules/Authentication/context/AuthenticationServiceContext';
import { useState } from 'react';

export const useCheckIfUserIsAuthenticated = () => {
  const { authenticationService } = useAuthenticationServiceContext();
  const [isLoading, setIsLoading] = useState(true);

  const checkIfUserIsAuthenticated = async ({
    onUserAuthenticated,
    onUserNotAuthenticated,
  }: {
    onUserAuthenticated: (
      authenticatedUser: AuthenticatedUser
    ) => Promise<void> | void;
    onUserNotAuthenticated?: () => Promise<void> | void;
  }) => {
    const authenticatedUser = await authenticationService.getUser();
    const isUserAuthenticated = !!authenticatedUser;

    if (isUserAuthenticated) {
      onUserAuthenticated && (await onUserAuthenticated(authenticatedUser));
    } else {
      onUserNotAuthenticated && (await onUserNotAuthenticated());
    }
    setIsLoading(false);
  };

  return { checkIfUserIsAuthenticated, isLoading };
};
