import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { AuthService } from 'modules/Authentication/authentication.interface';
import { AuthenticationServiceContextProvider } from 'modules/Authentication/context/AuthenticationServiceContext';
import { useMemo } from 'react';

interface Props {
  authenticationService: AuthService;
}

export const AuthenticationServiceProvider: FunctionComponentWithChildren<
  Props
> = ({ children, authenticationService }) => {
  const value = useMemo(() => {
    return {
      authenticationService,
    };
  }, [authenticationService]);

  return (
    <AuthenticationServiceContextProvider value={value}>
      {children}
    </AuthenticationServiceContextProvider>
  );
};
