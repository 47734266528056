import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';

/**  https://posthog.com/docs/libraries/next-js */
if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_POSTHOG_KEY &&
  process.env.NEXT_PUBLIC_POSTHOG_HOST
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    // We need a reverse proxy to avoid requests being blocked by blockers https://posthog.com/docs/advanced/proxy/nextjs
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    loaded: (posthog) => {
      /**
       * Enable debug mode if the environment variable is set to true
       * We always set the debug value so that the local storage "debug" value is invalidated
       */
      posthog.debug(process.env.NEXT_PUBLIC_POSTHOG_DEBUG_MODE === 'true');
    },
  });
}

export const PostHogProvider: FunctionComponentWithChildren = ({
  children,
}) => {
  return <PHProvider client={posthog}>{children}</PHProvider>;
};
