export enum Routes {
  /** 404 */
  NOT_FOUND = '/404',
  /** ADMIN */
  ADMIN_STAFFING = '/muzzichien/staffing',

  /** CHEATCODE */
  CHEATCODE = '/cheatcode',
  CHEATCODE_AUTH0_LOGIN_TEMPLATE = '/cheatcode/auth0-universal-login-template',
  CHEATCODE_AUTH0_LOGIN_STAGING_TEMPLATE_HIRING_MANAGER_SIGNUP = '/cheatcode/auth0-universal-login-template?variant=hiring-manager-signup&environment=staging',
  CHEATCODE_AUTH0_LOGIN_PRODUCTION_TEMPLATE_HIRING_MANAGER_SIGNUP = '/cheatcode/auth0-universal-login-template?variant=hiring-manager-signup&environment=production',

  /** HOME */
  HOME = '/',

  /** LOGOUT */
  LOGOUT = '/logout',

  /** ACCOUNT_CREATION */
  ACCOUNT_CREATION = '/account-creation',

  /** EMAIL_VERIFICATION */
  EMAIL_VERIFICATION = '/email-verification',

  /** EMPLOYER */
  EMPLOYER = '/employer',
  EMPLOYER_JOB_OFFER = '/employer/job-offer',
  EMPLOYER_JOB_OFFERS = '/employer/job-offers',
  EMPLOYER_JOB_OFFER_CLASSIFICATION = '/employer/job-offer-classification',

  EMPLOYER_CREATE_JOB_OFFER = '/employer/create-job-offer',
  EMPLOYER_UPDATE_JOB_OFFER = '/employer/update-job-offer',

  EMPLOYER_MESSAGING_CHANNEL = '/employer/messaging/channel',
  EMPLOYER_SETTINGS = '/employer/settings',

  EMPLOYER_PROFILE_SEARCH = '/employer/profile-search',
  EMPLOYER_GUARANTEE = '/employer/guarantee',
  EMPLOYER_PRICING = '/employer/pricing',

  /** MATCHING */
  MATCHING = '/matching',
  MATCHING_PROFILES_AND_JOB_OFFERS = '/matching/profiles-and-job-offers',
  MATCHING_PROFILES_AND_JOB_OFFERS_PROFILE = '/matching/profiles-and-job-offers/profile',

  /** COMMON ONBOARDING */
  ONBOARDING_SIGNUP = '/onboarding/signup',

  /** EMPLOYER ONBOARDING */
  ONBOARDING_EMPLOYER_PROFILE_SEARCH = '/onboarding/employer/profile-search',
  ONBOARDING_HIRING_MANAGER_REDIRECT_TO_SIGNUP = '/onboarding/hiring-manager/redirect-to-signup',
  ONBOARDING_EMPLOYER_ACCOUNT_CREATION = '/onboarding/employer/account-creation',
  ONBOARDING_EMPLOYER_EMPLOYER_CREATION = '/onboarding/employer/employer-creation',

  ONBOARDING_EMPLOYER_CREATE_JOB_OFFER = '/onboarding/employer/create-job-offer',

  ONBOARDING_EMPLOYER_JOB_OFFER_CLASSIFICATION = '/onboarding/employer/job-offer-classification',

  /** RECRUITER ONBOARDING */
  ONBOARDING_RECRUITER_ACCOUNT_CREATION = '/onboarding/recruiter/account-creation',
  ONBOARDING_RECRUITER_SEARCH_FIRM_CREATION = '/onboarding/recruiter/search-firm-creation',
  ONBOARDING_RECRUITER_EXPERTISES = '/onboarding/recruiter/expertises',
  ONBOARDING_RECRUITER_FAQ = '/onboarding/recruiter/faq',
  DEPRECATED_RECRUITER_SEARCH_FIRM_AFFILIATION = '/recruiter/search-firm-affiliation',
  DEPRECATED_RECRUITER_SEARCH_FIRM_CREATION = '/recruiter/search-firm-creation',
  DEPRECATED_RECRUITER_SEARCH_FIRM_CREATION_SUCCESS = '/recruiter/search-firm-creation-success',

  /** RECRUITER */
  RECRUITER = '/recruiter',
  RECRUITER_JOB_APPLICATION_RECOMMENDATION = '/recruiter/job-application-recommendation',
  RECRUITER_JOB_APPLICATIONS = '/recruiter/job-applications',
  RECRUITER_JOB_OFFER = '/recruiter/job-offer',
  RECRUITER_MESSAGING_CHANNEL = '/recruiter/messaging/channel',
  RECRUITER_PROFILE = '/recruiter/profile',
  RECRUITER_PROFILES = '/recruiter/profiles',
  RECRUITER_RECOMMENDATION = '/recruiter/recommendation',
  RECRUITER_RECOMMENDATIONS = '/recruiter/recommendations',
  RECRUITER_RECRUITMENT_TASKS = '/recruiter/recruitment-tasks',
  RECRUITER_SETTINGS = '/recruiter/settings',
  RECRUITER_FAQ = '/recruiter/faq',

  /** REDIRECTION_AFTER_LOGIN */
  REDIRECTION_AFTER_LOGIN = '/redirection-after-login',
  REDIRECTION_TO_SIGNUP = '/redirect-to-signup',

  /** EXTENSION_LOGIN */
  RECRUITER_EXTENSION_LOGIN = '/recruiter/extension-login',

  // Documents

  /** TERMS_AND_CONDITIONS */
  TERMS_AND_CONDITIONS = '/documents/terms-and-conditions.pdf',

  /** TERMS_OF_SRVICE */
  TERMS_OF_SERVICE = '/terms-of-service',
}

// TODO: in this ticket, https://www.notion.so/muzzo/Migrer-Recruiter-profile-22a83a5896514d3ab9c77c0bed494e8e?pvs=4
// This will be moved to the page component
// It can't be done right now as jest can't import the page component as it contains '[]' in the filename
export enum RECRUITER_PROFILE_TABS {
  INFO = 'info',
  MATCHING = 'matching',
  CV_BANK = 'cvBank',
}
