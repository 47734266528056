import { useMyUserContext } from 'modules/MyUser/context/MyUserContext';
import { FrontendEvent } from 'modules/Pusher/frontendEvent';
import { usePusherContext } from 'modules/Pusher/PusherContext';
import { useEffect } from 'react';

/*
 * Check the "How to use Pusher?" standard for more details
 * see https://www.notion.so/muzzo/How-to-use-Pusher-4acdbe70feb84c2297b09738e4551fd5
 */
export function usePusherUserEvent<K extends keyof FrontendEvent>(
  eventName: K,
  callback: (data: FrontendEvent[K]) => void
) {
  const { client } = usePusherContext();
  const { myMarketplaceUser } = useMyUserContext();

  useEffect(() => {
    if (!eventName || !client || !myMarketplaceUser) {
      return;
    }
    client.user.bind(eventName, callback);

    return () => {
      client.user.unbind(eventName, callback);
    };
  }, [callback, client, eventName, myMarketplaceUser]);
}
