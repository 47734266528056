import { InMemoryCache } from '@apollo/client';
import {
  mergeIncomingPaginatedAnonymizedProfiles,
  readPaginatedAnonymizedProfilesFromCache,
} from '@core/clients/apollo/cache/mergeAndReadMethods/mergeIncomingPaginatedAnonymizedProfiles.cache';
import {
  mergeIncomingMessagesIntoCache,
  readMessagesFromCache,
} from '@core/clients/apollo/cache/mergeAndReadMethods/messages.cache';
import {
  mergeIncomingChatChannelsIntoCache,
  readMyChatChannelsFromCache,
} from '@core/clients/apollo/cache/mergeAndReadMethods/myChatChannels.cache';
import { mergeIncomingProfilePictureUrlIntoCache } from '@core/clients/apollo/cache/mergeAndReadMethods/profilePictureUrl.cache';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messages: {
          keyArgs: false,
          merge: mergeIncomingMessagesIntoCache,
          read: readMessagesFromCache,
        },
        myChatChannels: {
          keyArgs: false,
          merge: mergeIncomingChatChannelsIntoCache,
          read: readMyChatChannelsFromCache,
        },
        paginatedAnonymizedProfiles: {
          keyArgs: false,
          merge: mergeIncomingPaginatedAnonymizedProfiles,
          read: readPaginatedAnonymizedProfilesFromCache,
        },
      },
    },
    User: {
      fields: {
        profilePictureUrl: {
          merge: mergeIncomingProfilePictureUrlIntoCache,
        },
      },
    },
    /**
    We can use the apollo cache to mock some fields when the API is not ready yet.
    The gql schema can be override via the ./graphql/local-schema.graphql file
    See the example below:
    */
    // JobOffer: {
    //   fields: {
    //     status: {
    //       read: () => {
    //         return JobOfferStatus.StaffingNotStarted;
    //       },
    //     },
    //   },
    // },
    /**
    Those fields should then be queried using the @client gql directive
    fragment JobOffer on JobOffer {
      id
      status @client
    }
    */
  },
});
