import { makeContext } from '@core/context/makeContext';
import pusherJs from 'pusher-js';

interface PusherContextValue {
  client?: pusherJs;
  authenticatePusherUser: () => void;
}

export const { Provider: PusherContextProvider, useContext: usePusherContext } =
  makeContext<PusherContextValue>('PusherContext');
