// Standard about error handling: https://www.notion.so/muzzo/Tout-savoir-sur-la-gestion-des-erreurs-6244616bd833444caf9de0ee6ee4f4c0?pvs=4

export class ManualOperationNeededError extends Error {
  howToFixLink: string;
  constructor(props: { message: string; howToFixLink: string }) {
    super(`${props.message}. How to fix: ${props.howToFixLink}`);
    this.name = 'NeedingManualOperation';
    this.howToFixLink = props.howToFixLink;
  }
}

/**
 * Note: to completely remove this kind of error, we should use a method to check all environment variables at the beginning of the app
 */
export class MissingEnvironmentVariableError extends Error {
  constructor(props: { envVarName: string }) {
    super(`Environment variable "${props.envVarName}" is missing`);
    this.name = 'EnvironmentVariableMissing';
  }
}

export class UndefinedGraphQLFieldError extends Error {
  constructor(props: { fieldName: string; operationName: string }) {
    super(
      `GraphQL field "${props.fieldName}" is undefined in operation "${props.operationName}"`
    );
    this.name = 'UndefinedGraphQLField';
  }
}

export class MissingGraphQLVariableError extends Error {
  constructor(props: { variableName: string; operationName: string }) {
    super(
      `GraphQL variable "${props.variableName}" is missing to perform operation "${props.operationName}"`
    );
    this.name = 'MissingGraphQLVariable';
  }
}

/**
 * Use of this class is considered as technical debt.
 * It should not be used anymore for reporting new errors, but should be progressively replaced by more specific errors.
 *
 * @deprecated see description
 */
export class UnspecifiedError extends Error {
  constructor(message: string) {
    super(
      `${message} - Please categorize this error with a more specific error. Create a new category if needed.`
    );
    this.name = 'UnspecifiedError';
  }
}
