import { FieldMergeFunction, FieldReadFunction } from '@apollo/client';
import { mergeIncomingHasNextPageIntoCache } from '@core/clients/apollo/cache/mergeAndReadMethods/mergeIncomingHasNextPageIntoCache';
import {
  ExistingMessageCache,
  IncomingForMessageCache,
  PartialMessageOffsetBasedConnection,
  PartialMessageOffsetBasedConnectionByChannelId,
} from '@core/clients/apollo/cache/mergeAndReadMethods/messages.cache.types';
import { UnspecifiedError } from '@core/errors';
import {
  ChatMessagesQueryVariables,
  Message,
} from 'graphql/DEPRECATED/generated';
import { sortBy, uniqBy } from 'lodash';

export const mergeIncomingMessagesIntoCache: FieldMergeFunction<
  ExistingMessageCache,
  IncomingForMessageCache
> = (
  existing,
  incoming,
  options
): PartialMessageOffsetBasedConnectionByChannelId => {
  const args = options.args as ChatMessagesQueryVariables | null; // Hack because typing of FieldFunctionOptions is tough
  const readField = options.readField;

  const chatChannelId = args?.input.chatChannelId;
  if (!chatChannelId) {
    throw new UnspecifiedError(
      'Cannot merge messages into Apollo cache: chatChannelId is undefined'
    );
  }
  const existingNodes = existing?.[chatChannelId]?.nodes ?? [];

  const incomingNodes = incoming.nodes;

  const mergedNodes = sortBy(
    uniqBy(
      [...existingNodes, ...incomingNodes],
      (messageRef) => messageRef.__ref
    ),
    (messageRef) => {
      const createdAtFieldName: keyof Message = 'createdAt'; // TODO: use typescript satisfies when typescript version is >= 4.9
      const createdAt = readField<string>(createdAtFieldName, messageRef);

      if (!createdAt) {
        return undefined;
      }
      // createdAt is a date as a string value in ISO format
      return new Date(createdAt);
    }
  );

  return {
    ...existing,
    [chatChannelId]: {
      __typename: incoming.__typename,
      hasNextPage: mergeIncomingHasNextPageIntoCache({
        existingHasNextPage:
          existing &&
          existing[chatChannelId] &&
          existing[chatChannelId]?.hasNextPage,
        incomingHasNextPage: incoming.hasNextPage,
      }),
      totalCount: incoming.totalCount,
      nodes: mergedNodes,
    },
  };
};

type MessageCacheReadResult = PartialMessageOffsetBasedConnection;

export const readMessagesFromCache: FieldReadFunction<
  ExistingMessageCache,
  MessageCacheReadResult
> = (existing, options) => {
  const args = options.args as ChatMessagesQueryVariables | null; // Hack because typing of FieldFunctionOptions is tough

  const chatChannelId = args?.input.chatChannelId;

  if (!chatChannelId) {
    throw new UnspecifiedError(
      'Cannot read messages from Apollo cache: chatChannelId is undefined'
    );
  }
  // A read function should always return undefined if existing is
  // undefined. Returning undefined signals that the field is
  // missing from the cache, which instructs Apollo Client to
  // fetch its value from your GraphQL server.
  if (!existing || !existing[chatChannelId]) {
    return undefined;
  }
  return existing[chatChannelId];
};
