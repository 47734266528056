import defaultTheme from 'tailwindcss/defaultTheme';
import plugin from 'tailwindcss/plugin';

const heightAndWidthSizeOptions = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '44px',
  12: '48px',
  20: '80px',
  24: '96px',
  26: '104px',
  28: '112px',
  32: '128px',
  40: '160px',
  44: '176px',
  48: '192px',
  52: '208px',
  54: '216px',
  56: '224px',
  60: '240px',
  68: '272px',
  72: '288px',
  79: '316px',
  80: '320px',
  86: '344px',
  96: '384px',
  100: '400px',
  112: '448px',
  120: '480px',
  142: '568px',
  160: '640px',
  212: '848px',
  '1/4': '25%',
  '1/3': '33.333333%',
  '1/2': '50%',
  '5/8': '62.5%',
  '3/4': '75%',
  '7/8': '87.5%',
  full: '100%',
  'calculated-screen': 'calc(var(--vh) * 100)', // https://www.markusantonwolf.com/blog/solution-to-the-mobile-viewport-height-issue-with-tailwind-css
};
const config = {
  content: [
    './src/pages/**/*.{js,jsx,ts,tsx}',
    './modules/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    screens: {
      // although these are tailwind default breakpoints,
      // they are redefined here in order to be used outside of tailwind context
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1800px',
    },
    extend: {
      fontFamily: {
        sans: ['Montserrat', ...defaultTheme.fontFamily.sans],
      },
      fontWeight: {
        medium: '500',
      },
      fontSize: {
        xxs: ['10px', '16px'],
        xss: ['11px', '16px'],
        xs: ['12px', '16px'],
        s: ['13px', '18px'],
        sm: ['14px', '20px'],
        base: ['16px', '24px'],
        lg: ['18px', '28px'],
        xl: ['20px', '28px'],
      },
      colors: {
        current: 'currentColor',
        magenta: '#FF00FF', // ADMIN ONLY COLOR
        'medium-orchid': '#BA55D3',
        'catalina-blue': '#213159',
        'focus-blue': '#2563EB',
        'klein-blue': '#0724A1',
        'persian-blue': '#1134C9',
        'periwinkle-blue': '#eef2fa',
        'radical-red': '#F04B4C',
        'dark-red': '#8B0000',
        'wild-watermelon': '#F66263',
        'beauty-bush': '#F9B7B7',
        wewak: '#F58C8D',
        emerald: '#61D177',
        'dark-pastel-green': '#08B62B',
        'white-smoke': '#F7F7F7',
        silver: '#C3C2C2',
        gainsboro: '#E0E0E0',
        grey: '#7B7B7B',
        white: '#FFFFFF',
        yellow: '#FFF500',
        orange: '#FFA000',
      },
      zIndex: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        100: '100',
        1000: '1000',
        max: '2000000000', // 32 bits max
      },
      opacity: {
        15: '0.15',
        40: '0.40',
        60: '0.60',
      },
      borderWidth: {
        9: '9px',
      },
      spacing: {
        '1/2': '50%',
        '1/3': '33.333333%',
        '2/3': '66.666667%',
        '1/4': '25%',
        '2/4': '50%',
        '3/4': '75%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.666667%',
        '2/6': '33.333333%',
        '3/6': '50%',
        '4/6': '66.666667%',
        '5/6': '83.333333%',
        '1/12': '8.333333%',
        '2/12': '16.666667%',
        '3/12': '25%',
        '4/12': '33.333333%',
        '5/12': '41.666667%',
        '6/12': '50%',
        '7/12': '58.333333%',
        '8/12': '66.666667%',
        '9/12': '75%',
        '10/12': '83.333333%',
        '11/12': '91.666667%',
        '1/100': '1%',
      },
      inset: {
        '2%': '2%',
      },
      boxShadow: {
        'md-blue': '0px 4px 15px rgba(7, 36, 161, 0.5)',
        'md-red': '0px 4px 15px rgba(240, 75, 76, 0.5);',
        'md-inside': 'inset 2px 4px 7px rgba(0, 0, 0, 0.25)',
        dropdown: '5px 5px 10px rgba(0, 0, 0, 0.15)',
        focus: '0px 0px 0px 1px rgb(37, 99, 235)',
        card: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)',
      },
      width: heightAndWidthSizeOptions,
      minWidth: heightAndWidthSizeOptions,
      maxWidth: heightAndWidthSizeOptions,
      height: heightAndWidthSizeOptions,
      minHeight: heightAndWidthSizeOptions,
      maxHeight: heightAndWidthSizeOptions,

      // animations
      transitionProperty: {
        height: 'height',
      },
      keyframes: {
        'auto-save-check-fade-out-up': {
          from: {
            opacity: '1',
            transform: 'translateY(0px)',
          },
          to: {
            opacity: '0',
            transform: 'translateY(-20px)',
          },
        },
        'admin-staffing-loader': {
          '0%': {
            transform: 'scale(0.1)',
          },
          '50%': {
            transform: 'scale(1) rotateY(720deg) ',
          },
          '100%': {
            transform: 'scale(0.1) rotate(1440deg)',
          },
        },
      },
      animation: {
        'auto-save-check-persistent-fade-out-up':
          'auto-save-check-fade-out-up 2s ease-out forwards', // forwards to stay in final state
        'admin-staffing-loader': 'admin-staffing-loader 2s ease-out infinite',
      },
      gridTemplateColumns: {
        'auto-1fr': 'auto 1fr',
      },
      gridTemplateRows: {
        8: 'repeat(8, minmax(0, 1fr))',
        12: 'repeat(12, minmax(0, 1fr))',
        60: 'repeat(60, minmax(0, 1fr))',
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        '.toggle-dot': {
          transition: 'all 0.3s ease-in-out',
        },
        'input:checked ~ .toggle-dot': {
          transform: 'translateX(80%)',
          'background-color': '#0724A1',
        },
        '.row-with-border:first-child, .row-with-border.is-focus, .row-with-border.is-focus + .row-with-border, .row-with-border:hover + .row-with-border':
          {
            '.option-border': {
              'background-color': 'transparent',
            },
          },
      };

      addUtilities(newUtilities);
    }),
  ],
};

export default config;
