import { ApolloClient, ApolloLink } from '@apollo/client';
import { apolloCache } from '@core/clients/apollo/cache/cache';
import { makeAnalyticsLink } from '@core/clients/apollo/links/analytics';
import { getContextLink } from '@core/clients/apollo/links/context';
import { errorLink } from '@core/clients/apollo/links/error';
import { httpLink } from '@core/clients/apollo/links/http';
import { OperationTypeNode } from 'graphql';
import { AnalyticsService } from 'modules/Analytics/analytics.interface';
import { AuthService } from 'modules/Authentication/authentication.interface';

// Currently the apollo client is used only on the client side (no SSR / SSG) thanks to the "ssrMode" and "ssr: false" options
// If you need to implement server side apollo fetching, please see https://github.com/vercel/next.js/tree/canary/examples/with-apollo
export const getApolloClient = ({
  authenticationService,
  analyticService,
}: {
  authenticationService: AuthService;
  analyticService: AnalyticsService;
}) => {
  const contextLink = getContextLink({ authenticationService });
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      makeAnalyticsLink({
        analytics: {
          track: analyticService.trackGqlOperation,
        },
        operationTypeWhitelist: [OperationTypeNode.MUTATION],
        operationDocumentWhitelist: [],
      }),
      errorLink,
      contextLink,
      httpLink,
    ]),
    cache: apolloCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
