import {
  BasicButton,
  BasicButtonProps,
} from '@core/components/Buttons/BasicButton/BasicButton.component';
import { AtomicFunctionComponentWithChildren } from '@core/types/functionComponent.types';
import classNames from 'classnames';
import Link from 'next/link';
import { MouseEventHandler } from 'react';

export interface BasicButtonAsLinkProps extends BasicButtonProps {
  href: string;
  rel?: string;
  openInNewTab?: boolean;
  /**
   * Reminder that this component is meant for navigation, not for actions.
   * Typical use case is to trigger analytics events.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * This component enables to trigger a navigation from a button click.
 * A navigation should rather be triggerred by a LINK,
 **/

export const BasicButtonAsLink: AtomicFunctionComponentWithChildren<
  BasicButtonAsLinkProps
> = ({
  href,
  rel,
  openInNewTab = false,
  children,
  isFullWidth = false,
  label,
  onClick,
  ...props
}) => {
  return (
    <Link
      href={href}
      rel={rel}
      target={openInNewTab ? '_blank' : '_self'}
      className={classNames({ 'w-full': isFullWidth })}
    >
      <BasicButton {...props} isFullWidth={isFullWidth} onClick={onClick}>
        <div className="font-medium hover:underline">{label || children}</div>
      </BasicButton>
    </Link>
  );
};
