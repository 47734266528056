import { screenSizeBreakpoints } from '@core/components/ScreenSizeContext/screenSizeBreakpoints';
import {
  ScreenSize,
  ScreenSizeContext,
} from '@core/components/ScreenSizeContext/ScreenSizeContext';
import { useDebounce } from '@core/hooks/useDebounce.hook';
import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';

import { useWindowWidth } from '@react-hook/window-size';
import { useCallback, useEffect, useState } from 'react';

const screenSizeOrder: ScreenSize[] = [
  ScreenSize.Mobile,
  ScreenSize.Small,
  ScreenSize.Medium,
  ScreenSize.Large,
  ScreenSize.XLarge,
];

const getScreenSize = (windowWidth?: number): ScreenSize | undefined => {
  if (!windowWidth) {
    return;
  }

  if (windowWidth < screenSizeBreakpoints.sm) {
    return ScreenSize.Mobile;
  }
  if (windowWidth < screenSizeBreakpoints.md) {
    return ScreenSize.Small;
  }
  if (windowWidth < screenSizeBreakpoints.lg) {
    return ScreenSize.Medium;
  }
  if (windowWidth < screenSizeBreakpoints.xl) {
    return ScreenSize.Large;
  }

  return ScreenSize.XLarge;
};

export const ScreenSizeContextProvider: FunctionComponentWithChildren = ({
  children,
}) => {
  const windowWidth = useWindowWidth();
  const [currentScreenSize, setCurrentScreenSize] = useState<
    ScreenSize | undefined
  >(undefined);

  const setScreenVh = () => {
    /** We added this chunk of code to always get the right screen size, even on Mobile
     * We use then the calculated vh for "screen" property to be exactly "100vh" in Tailwind (see tailwind.config.ts)
     * https://www.markusantonwolf.com/blog/solution-to-the-mobile-viewport-height-issue-with-tailwind-css */
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const debouncedSetScreenVh = useDebounce(setScreenVh, 500);

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', debouncedSetScreenVh);

    // Call handler right away so state gets updated with initial window size
    setScreenVh();

    // Remove event listener on cleanup
    return () => {
      debouncedSetScreenVh.cancel();
      window.removeEventListener('resize', debouncedSetScreenVh);
    };
  }, []);

  useEffect(() => {
    setCurrentScreenSize(getScreenSize(windowWidth));
  }, [windowWidth]);

  const checkIfScreenSizeIsGreaterOrEqualTo = useCallback(
    (limitScreenSize: ScreenSize): boolean => {
      return (
        !!currentScreenSize &&
        screenSizeOrder.indexOf(currentScreenSize) >=
          screenSizeOrder.indexOf(limitScreenSize)
      );
    },
    [currentScreenSize]
  );

  const checkIfScreenSizeIsLowerOrEqualTo = useCallback(
    (limitScreenSize: ScreenSize): boolean => {
      return (
        !!currentScreenSize &&
        screenSizeOrder.indexOf(currentScreenSize) <=
          screenSizeOrder.indexOf(limitScreenSize)
      );
    },
    [currentScreenSize]
  );

  return (
    <ScreenSizeContext.Provider
      value={{
        currentScreenSize,
        isMobileScreen: currentScreenSize === ScreenSize.Mobile,
        checkIfScreenSizeIsGreaterOrEqualTo,
        checkIfScreenSizeIsLowerOrEqualTo,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};
