import tailwindConfig from 'tailwind.config';

const extractBreakpointFromTailwindScreenConfig = (
  tailwindScreenConfig: string
): number => {
  const configPx = tailwindScreenConfig.split('px')[0];
  if (!configPx) return 0;
  return parseInt(configPx, 10);
};

export const screenSizeBreakpoints = {
  sm: extractBreakpointFromTailwindScreenConfig(
    tailwindConfig.theme.screens.sm
  ),
  md: extractBreakpointFromTailwindScreenConfig(
    tailwindConfig.theme.screens.md
  ),
  lg: extractBreakpointFromTailwindScreenConfig(
    tailwindConfig.theme.screens.lg
  ),
  xl: extractBreakpointFromTailwindScreenConfig(
    tailwindConfig.theme.screens.xl
  ),
};
